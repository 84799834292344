import { UserButton, useAuth } from '@clerk/nextjs';
import { AppShell, Group, Skeleton, Stack, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useClerk } from '~/ui/hooks/clerk.hook';
import { AppLogo } from '../../Logo/AppLogo';
import { ClearCacheButton } from './ClearCache';
import { MenuItem } from './MenuItem';
import { SecondaryMenuBar } from './SecondaryMenuBar';
import { ToggleButton } from './ToggleButton';
import { MAIN_MAIN_WIDTH, MENU_MARGIN_TOP, useMenuStore } from './menu.store';

export const Sidebar: React.FC = () => {
  const { orgId } = useAuth();
  const { menu, settingsMenu, selectedMenu } = useMenuStore((state) => ({
    menu: state.menu,
    settingsMenu: state.settingsMenu,
    selectedMenu: state.selectedMenu,
  }));
  const { appearance } = useClerk();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  return (
    <Group
      h={'100%'}
      gap={0}
      pos="relative"
      style={{
        backgroundColor: theme.variantColorResolver({
          color: theme.primaryColor,
          theme,
          variant: 'light',
        }).background,
        borderLeft: `solid 1px ${
          colorScheme === 'light' ? theme.colors.violet[2] : theme.colors.violet[6]
        }`,
      }}
    >
      <Stack
        h={'100%'}
        p={0}
        w={MAIN_MAIN_WIDTH}
        style={{
          backgroundColor: colorScheme === 'light' ? theme.white : theme.colors.dark[7],
        }}
      >
        <AppShell.Section mt={MENU_MARGIN_TOP}>
          <Stack align="center" justify="center" gap="sm">
            <AppLogo />
          </Stack>
        </AppShell.Section>

        <AppShell.Section grow>
          {orgId && selectedMenu?.children && <ToggleButton />}
          <Stack align="center" justify="center" gap="sm">
            {selectedMenu
              ? menu
                  .filter(({ hide }) => !hide)
                  .map((menuItem) => (
                    <MenuItem
                      key={menuItem.id}
                      {...menuItem}
                      selected={selectedMenu.id === menuItem.id}
                    />
                  ))
              : new Array(4).fill(0).map((_, i) => <Skeleton key={i} width={35} height={35} circle />)}
          </Stack>
        </AppShell.Section>

        <AppShell.Section>
          <Stack align="center" justify="center" gap="sm" mb={20}>
            <ClearCacheButton />
            {orgId && selectedMenu
              ? settingsMenu
                  .filter(({ hide }) => !hide)
                  .map((menuItem) => (
                    <MenuItem
                      key={menuItem.id}
                      {...menuItem}
                      selected={selectedMenu.id === menuItem.id}
                    />
                  ))
              : null}
            <UserButton appearance={appearance} />
          </Stack>
        </AppShell.Section>
      </Stack>
      {orgId && selectedMenu?.children && <SecondaryMenuBar />}
    </Group>
  );
};

import { Box, type BoxProps } from '@mantine/core';
import { type PropsWithChildren } from 'react';

// https://github.com/orgs/mantinedev/discussions/1141
export const ModalFooter: React.FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box
      pos="sticky"
      bottom={0}
      style={{ backgroundColor: 'var(--mantine-color-body)' }}
      p={'xs'}
      {...props}
    >
      {children}
    </Box>
  );
};

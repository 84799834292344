import { type TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc';
import { type ClientErrorCodes } from '~/server/services/errors/error-codes';

export const errorCodes: Record<ClientErrorCodes | TRPC_ERROR_CODE_KEY, string> = {
  MISSING_ROLE: `You don't have role defined`,
  NO_PERMISSION: 'You are not authorized to perform this action',
  MISSING_PERMISSION_DEFINITION: 'You do not have permission definition for this action',
  CONTACT_ADMIN: 'Please contact your administrator',
  GENERAL_ERROR: 'An error has occurred',
  CONTACT_SUPPORT: 'Please contact support',
  CARD_TYPE_DELETE_HAS_CARDS: 'Cannot delete card type with cards',
  UNAUTHORIZED_NO_ORG: 'Unauthorized - no organization',
  FINANCE_SETTINGS_NOT_FOUND: 'Finance settings were not found - go to finance settings and create them',
  SMS_DISABLED: 'SMS is disabled for this organization plan - please contact support',
  SMS_LIMIT_REACHED: 'You have reached your SMS limit',

  // TRPC errors
  BAD_REQUEST: 'Bad request - invalid data',
  CLIENT_CLOSED_REQUEST: 'Client closed request',
  CONFLICT: 'Conflict with existing data',
  FORBIDDEN: 'Forbidden',
  INTERNAL_SERVER_ERROR: 'Internal server error',
  METHOD_NOT_SUPPORTED: 'Method not supported',
  NOT_FOUND: 'Not found',
  NOT_IMPLEMENTED: 'Not implemented',
  PARSE_ERROR: 'Parse error',
  PAYLOAD_TOO_LARGE: 'Payload too large',
  PRECONDITION_FAILED: 'Precondition failed',
  TIMEOUT: 'Request Timeout',
  TOO_MANY_REQUESTS: 'Too many requests - try again later',
  UNAUTHORIZED: 'Unauthorized - try to login again',
  UNPROCESSABLE_CONTENT: 'Unprocessable content',
  UNSUPPORTED_MEDIA_TYPE: 'Unsupported media type',
  BAD_GATEWAY: 'Bad gateway',
  GATEWAY_TIMEOUT: 'Gateway timeout',
  SERVICE_UNAVAILABLE: 'Service unavailable',
};

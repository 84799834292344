export const meetingReminderEmail = {
  'Meeting scheduled - {{title}}': 'הוזמנת לפגישה - {{title}}',
  'Meeting changed - {{title}}': 'פגישה שונתה - {{title}}',
  'Meeting canceled - {{title}}': 'פגישה בוטלה - {{title}}',
  'Meeting reminder - {{title}}': 'תזכורת לפגישה - {{title}}',
  Hi: 'שלום',
  'You have a been invited to a meeting. Here are the details:': 'הוזמנת לפגישה. הנה הפרטים:',
  Title: 'כותרת',
  Description: 'תיאור',
  Location: 'מיקום',
  When: 'מתי',
  Who: 'מי',
};

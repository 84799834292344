export const automationsPage = {
  'When this happens': 'Amikor ez történik',
  'Do this': 'Tedd ezt',
  'Automation description': 'Automatizálás leírása',
  Automation: 'Automatizálás',
  Description: 'Leírás',
  Field: 'Mező',
  'Create automation': 'Automatizálás létrehozása',
  'Card type': 'Kártyatípus',
  Save: 'Mentés',
  'Please select a trigger': 'Kérlek válassz egy kiváltót',
  'When field': 'Amikor a mező',
  changes: 'megváltozik',
  'changes to': 'megváltozik erre',
  'Change field': 'Mező módosítása',
  to: 'erre:',
  on: 'ekkor:',
  'on the': 'a(z)',
  and: 'és',
  'Send email to': 'Küldj e-mailt',
  'Field: {{fieldName}}': 'Mező: {{fieldName}}',
  'When a {{cardType}} is created': 'Amikor létrejön egy {{cardType}}',
  'When a {{cardType}} is deleted': 'Amikor törölnek egy {{cardType}}-t',
  'with subject:': 'tárggyal:',
  Subject: 'Tárgy',
  'and body': 'és tartalommal:',
  Email: 'E-mail',
  'Email Body': 'E-mail tartalma',
  Participants: 'Résztvevők',
  Creator: 'Létrehozó',

  // Label
  'When user assigned': 'Amikor felhasználó hozzárendelve',
  'When button clicked': 'Amikor a gombra kattintanak',
  'When email received': 'Amikor e-mail érkezik',
  'When card created': 'Amikor létrejön egy kártya',
  'When card deleted': 'Amikor törölnek egy kártyát',
  'changes from': 'megváltozik erről:',
  Every: 'Minden',
  at: 'ekkor:',
  'On due date': 'A határidő napján',
  'send email to': 'küldj e-mailt',
  create: 'létrehozás',
  with: 'ezzel:',
  'send SMS to': 'küldj SMS-t',
  // Task
  Title: 'Cím',
  after: 'után',
  Value: 'Érték',
  Unit: 'Egység',
  before: 'előtt',
  'with description': 'leírással',
  'assigned to': 'hozzárendelve ehhez:',
  'with reminder': 'emlékeztetővel',
  // SMS
  'send sms': 'küldj SMS-t',
  Message: 'Üzenet',
  'Total characters: {{count}}': 'Összes karakter: {{count}}',

  // Webhook
  Remove: 'Törlés',

  // Actions
  'Attach files from archive...': 'Fájlok csatolása az archívumból...',

  // units
  Minutes: 'Percek',
  Hours: 'Órák',
  Days: 'Napok',
  Weeks: 'Hetek',
  Months: 'Hónapok',
  Years: 'Évek',
  day: 'nap',
  week: 'hét',
  month: 'hónap',
  days: 'napok',
  weeks: 'hetek',
  months: 'hónapok',
  // delete
  'Delete automation': 'Automatizálás törlése',
  'Are you sure you want to delete automation?': 'Biztosan törölni szeretnéd az automatizálást?',
  'Automation deleted': 'Automatizálás törölve',
  'Automation was successfully deleted': 'Az automatizálás sikeresen törölve',

  // triggers
  'status change': 'állapotváltozás',
  'user assigned': 'felhasználó hozzárendelve',
  'button clicked': 'gombra kattintás',
  'email received': 'e-mail érkezett',
  'card created': 'kártya létrejött',
  'card deleted': 'kártya törölve',
  'every time period': 'minden időszakban',
  'date arrives': 'dátum érkezik',
  // actions
  notify: 'értesítés küldése',
  'change field': 'mező módosítása',
  'send email': 'e-mail küldése',
  'create card': 'kártya létrehozása',
  'create meeting': 'találkozó létrehozása',
  'create task': 'feladat létrehozása',
  'send SMS': 'SMS küldése',
  'call webhook': 'webhook hívása',

  //days
  Sunday: 'Vasárnap',
  Monday: 'Hétfő',
  Tuesday: 'Kedd',
  Wednesday: 'Szerda',
  Thursday: 'Csütörtök',
  Friday: 'Péntek',
  Saturday: 'Szombat',
};

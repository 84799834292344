import { useUser } from '@clerk/nextjs';
import { Button, Text } from '@mantine/core';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { useMantineColor } from '~/ui/hooks/mantine-color.hook';
import { useAppRouter } from '../../../ui/hooks/app.router.hook';

export const BackToAppButton = () => {
  const { isMarketingPage, isAuthPage } = useAppRouter();
  const { isSignedIn, isLoaded } = useUser();
  const { t } = useTranslation('headerComponent');
  const { color } = useMantineColor('gray', 9);
  if (!isMarketingPage) return null;
  if (isAuthPage) return null;
  const href = isSignedIn ? '/cards' : '/login';
  const text = isSignedIn ? t('Back to App') : t('Sign In');

  return (
    <Link href={href}>
      <Button color={color} variant={'outline'} loading={!isLoaded}>
        <Text>{text}</Text>
      </Button>
    </Link>
  );
};

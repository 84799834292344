'use client';
import { ClerkProvider } from '@clerk/nextjs';
import { useMemo } from 'react';
import { clerkClientUtils } from '~/core/auth/clerk.utils';
import { useLocale } from '~/ui/hooks/locale.hook';

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { locale } = useLocale();

  const localization = useMemo(() => {
    return clerkClientUtils.getLocale(locale);
  }, [locale]);

  return (
    <ClerkProvider localization={localization} afterSignOutUrl={'/'}>
      {children}
    </ClerkProvider>
  );
};

export const financePage = {
  // Document Types
  TAX_INVOICE: 'Tax Invoice',
  RECEIPT: 'Receipt',
  INVOICE_RECEIPT: 'Invoice Receipt',
  CREDIT_INVOICE: 'Credit Invoice',
  PROFORMA_INVOICE: 'Proforma Invoice',
  ORDER_CONFIRMATION: 'Order Confirmation',
  PRICE_OFFER: 'Price Offer',
  SHIPPING_CERTIFICATE: 'Shipping Certificate',
  DEPOSIT: 'Deposit',
  RETAINER: 'Retainer',
  INVOICE_FOR_RECEIPT: 'Invoice For Receipt',
  DRAFTS: 'Drafts',
  DONATION: 'Donation',

  // Document Status
  DRAFT: 'Draft',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CANCELED: 'Canceled',

  // Type of related card
  EXISTING: 'Exist Card',
  CASUAL: 'Casual',
  NEW: 'New Card',

  // Product vat
  TAXABLE: 'Taxable',
  EXEMPTION: 'Exemption',

  // Payment types
  CASH: 'Cash',
  CHECK: 'Check',
  CREDIT_CARD: 'Credit Card',
  BANK_TRANSFER: 'Bank Transfer',
  OTHER: 'Other',
  WITHHOLDING_TAX: 'Withholding Tax',

  // Credit Card
  UNKNOWN: 'Unknown',
  MASTER_CARD: 'Master Card',
  DINERS: 'Diners',
  ISRACARD: 'Isracard',
  AMERICAN_EXPRESS: 'American Express',
  VISA: 'Visa',

  // Document Display type
  ORIGINAL: 'Original',
  COPY: 'Certified copy',
  PREVIEW: 'Preview',

  // Business types
  SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
  AUTHORIZED_DEALER: 'Authorized Dealer',
  LIMITED_COMPANY: 'Limited Company',
  NON_PROFIT_ORGANIZATION: 'Non Profit Organization',
};

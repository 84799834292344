export const navbarComponent = {
  Dashboard: 'דשבורד',
  Automations: 'אוטומציות',
  Cards: 'כרטיסים',
  'All Cards': 'כל סוגי הכרטיסים',
  Tasks: 'משימות',
  Agenda: 'לוח זמנים',
  Favorites: 'מועדפים',
  Recent: 'דפים אחרונים שביקרת בהם',
  Settings: 'הגדרות',
  General: 'כללי',
  'User Settings': 'הגדרות משתמש',
  Members: 'משתמשים',
  'Files Archive': 'ארכיון קבצים',
  'API Keys': 'מפתחות API',
  Organization: 'ארגון',
  'Roles and Permissions': 'תפקידים והרשאות',
  'Plan and Billing': 'תוכנית וחיוב',
  'Card Types': 'סוגי כרטיסים',
  Audit: 'הסטוריית שינויים',
  'Admin Console': 'ניהול מערכת',
  Organizations: 'ארגונים',
  crmContacts: 'אנשי קשר',
  crmCompanies: 'חברות',
  crmProjects: 'פרוייקטים',
  'Open Navigation': 'פתיחת תפריט ניווט',
  'Close Navigation': 'סגירת תפריט ניווט',
  Finances: 'הנהלת חשבונות',
  'Documents & Reports': 'מסמכים ודוחות',
  Conversations: 'שיחות',
};

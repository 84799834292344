import { ActionIcon, Group, useMantineColorScheme } from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import { setCookie } from 'cookies-next';
import { useCallback } from 'react';
import { styleConfigs } from '~/Components/Providers/Styles/style.config';

export const ThemeToggle: React.FC = () => {
  const theme = useMantineColorScheme();
  const handleSetColorTheme = useCallback(() => {
    const colorSchemeValue = theme.colorScheme === 'dark' ? 'light' : 'dark';
    theme.setColorScheme(colorSchemeValue);
    setCookie(styleConfigs.cookieKey, colorSchemeValue, { maxAge: 60 * 60 * 24 * 30 });
  }, [theme.colorScheme]);

  return (
    <Group justify="center">
      <ActionIcon onClick={handleSetColorTheme} size="lg" radius="xl" variant="light">
        {theme.colorScheme === 'dark' ? <IconSun size="1.2rem" /> : <IconMoonStars size="1.2rem" />}
      </ActionIcon>
    </Group>
  );
};

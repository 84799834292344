export const cardsMainPage = {
  contacts: 'Contacts',
  contact: 'Contact',
  record: 'Record',
  records: 'Records',
  actions: 'Actions',
  import: 'Import',
  create: 'Create',
  add: 'Add',
  save: 'Save',
  automation: 'Automation',
  integrations: 'Integrations',
  editProperties: 'Edit Card Properties',
  manageDuplicates: 'Manage Duplicates',
  restoreRecords: 'Restore Records',
  of: 'of',
};

import { ActionIcon, Indicator, Popover, Stack, Text } from '@mantine/core';
import { IconBell } from '@tabler/icons-react';
import { realtimeState } from '~/Components/Integrations/Realtime/Realtime';
import { useAppRouter } from '~/ui/hooks/app.router.hook';
import { useFeatureFlag } from '~/ui/hooks/feature-flag.hook';
import { ActiveUsers } from './ActiveUsers';
import { AuditList } from './AuditList';

export const Notifications: React.FC = () => {
  const { isMarketingPage } = useAppRouter();
  const activeUsers = useFeatureFlag('active-users');
  const { presence } = realtimeState(({ presence }) => ({ presence }));

  if (isMarketingPage) return null;

  const haveUsers = Object.keys(presence).length > 1;
  return (
    <Popover position="bottom" withArrow shadow="md" keepMounted={false}>
      <Popover.Target>
        <Indicator
          size={16}
          disabled={!haveUsers}
          label={<Text size={'0.8rem'}>{Object.keys(presence).length}</Text>}
          processing={haveUsers}
        >
          <ActionIcon size="lg" radius="xl" variant="light">
            <IconBell />
          </ActionIcon>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown miw={300}>
        <Stack>
          {activeUsers ? <ActiveUsers /> : null}
          <AuditList />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

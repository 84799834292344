export const zodHebrewLocale = {
  errors: {
    invalid_type: 'צפוי {{expected}}, התקבל {{received}}',
    invalid_type_received_undefined: 'נדרש',
    invalid_type_received_null: 'נדרש',
    invalid_literal: 'ערך לא תקין, צפוי {{expected}}',
    unrecognized_keys: 'מפתחות לא מזוהים באובייקט: {{- keys}}',
    invalid_union: 'קלט לא תקין',
    invalid_union_discriminator: 'ערך מזהה לא תקין. צפוי {{- options}}',
    invalid_enum_value: "ערך לא תקין לסוג enum. ציפיתי ל-{{- options}}, קיבלתי '{{received}}'",
    invalid_arguments: 'פרמטרים לפונקציה לא תקינים',
    invalid_return_type: 'סוג ההחזרה של הפונקציה לא תקין',
    invalid_date: 'תאריך לא תקין',
    custom: 'קלט לא תקין',
    invalid_intersection_types: 'לא ניתן למזג את הסוגים',
    not_multiple_of: 'המספר חייב להיות מכפלה של {{multipleOf}}',
    not_finite: 'המספר חייב להיות סופי',
    invalid_string: {
      email: 'כתובת דוא"ל לא תקינה',
      url: 'כתובת אינטרנט לא תקינה',
      uuid: 'מזהה UUID לא תקין',
      cuid: 'מזהה CUID לא תקין',
      regex: 'קלט לא תקין',
      datetime: 'תאריך ושעה לא תקינים',
      startsWith: 'קלט לא תקין: חייב להתחיל ב-"{{startsWith}}"',
      endsWith: 'קלט לא תקין: חייב להסתיים ב-"{{endsWith}}"',
    },
    too_small: {
      array: {
        exact: 'המערך חייב להכיל בדיוק {{minimum}} איברים',
        inclusive: 'המערך חייב להכיל לפחות {{minimum}} איברים',
        not_inclusive: 'המערך חייב להכיל יותר מ-{{minimum}} איברים',
      },
      string: {
        exact: 'הטקסט חייב להכיל בדיוק {{minimum}} תווים',
        inclusive: 'הטקסט חייב להכיל לפחות {{minimum}} תווים',
        not_inclusive: 'הטקסט חייב להכיל מעל {{minimum}} תווים',
      },
      number: {
        exact: 'המספר חייב להיות בדיוק {{minimum}}',
        inclusive: 'המספר חייב להיות גדול או שווה ל-{{minimum}}',
        not_inclusive: 'המספר חייב להיות גדול מ-{{minimum}}',
      },
      set: {
        exact: 'קלט לא תקין',
        inclusive: 'קלט לא תקין',
        not_inclusive: 'קלט לא תקין',
      },
      date: {
        exact: 'התאריך חייב להיות בדיוק {{- minimum, datetime}}',
        inclusive: 'התאריך חייב להיות גדול או שווה ל-{{- minimum, datetime}}',
        not_inclusive: 'התאריך חייב להיות גדול מ-{{- minimum, datetime}}',
      },
    },
    too_big: {
      array: {
        exact: 'המערך חייב להכיל בדיוק {{maximum}} איברים',
        inclusive: 'המערך חייב להכיל לכל היותר {{maximum}} איברים',
        not_inclusive: 'המערך חייב להכיל פחות מ-{{maximum}} איברים',
      },
      string: {
        exact: 'הטקסט חייב להכיל בדיוק {{maximum}} תווים',
        inclusive: 'הטקסט חייב להכיל לכל היותר {{maximum}} תווים',
        not_inclusive: 'הטקסט חייב להכיל פחות מ-{{maximum}} תווים',
      },
      number: {
        exact: 'המספר חייב להיות בדיוק {{maximum}}',
        inclusive: 'המספר חייב להיות קטן או שווה ל-{{maximum}}',
        not_inclusive: 'המספר חייב להיות קטן מ-{{maximum}}',
      },
      set: {
        exact: 'קלט לא תקין',
        inclusive: 'קלט לא תקין',
        not_inclusive: 'קלט לא תקין',
      },
      date: {
        exact: 'התאריך חייב להיות בדיוק {{- maximum, datetime}}',
        inclusive: 'התאריך חייב להיות קטן או שווה ל-{{- maximum, datetime}}',
        not_inclusive: 'התאריך חייב להיות קטן מ-{{- maximum, datetime}}',
      },
    },
  },
  validations: {
    email: 'כתובת דוא"ל',
    url: 'כתובת אינטרנט',
    uuid: 'מזהה UUID',
    cuid: 'מזהה CUID',
    regex: 'קלט רגולרי',
    datetime: 'תאריך ושעה',
  },
  types: {
    function: 'פונקציה',
    number: 'מספר',
    string: 'טקסט',
    nan: 'NaN',
    integer: 'מספר שלם',
    float: 'מספר עשרוני',
    boolean: 'בוליאני',
    date: 'תאריך',
    bigint: 'מספר גדול',
    undefined: 'לא מוגדר',
    symbol: 'סמל',
    null: 'null',
    array: 'מערך',
    object: 'אובייקט',
    unknown: 'לא ידוע',
    promise: 'Promise',
    void: 'void',
    never: 'לעולם לא',
    map: 'מפה',
    set: 'סט',
  },
};

export const cardPage = {
  'Delete card': 'מחיקת כרטיס',
  'Change card type': 'שינוי סוג כרטיס',
  'Card deleted': 'הכרטיס נמחק',
  "{{cardTypeName}} '{{cardName}}' deleted successfully": "{{cardTypeName}} '{{cardName}}' נמחק בהצלחה",
  'Edit Card Type - {{cardTypeName}}': 'עריכת סוג כרטיס - {{cardTypeName}}',
  'Are you sure you want to delete {{cardTypeName}} "{{cardName}}"?':
    'האם אתה בטוח שברצונך למחוק {{cardTypeName}} "{{cardName}}"?',
  Previous: 'הקודם',
  Next: 'הבא',
  'Options & Actions': 'אפשרויות ופעולות',
  'Status & Progress': 'סטטוסים ותהליכים',
  Storage: 'אחסון',
  Comments: 'הערות',
  Reminders: 'תזכורות',
  // Relations
  Relations: 'קישורים',
  'Add card relation': 'הוספת קישור לכרטיס',
  'Remove relation': 'הסר קישור',
  'Delete relation': 'מחיקת קישור',
  'Edit relation values': 'עריכת ערכי קישור',
  'Add field': 'הוספת שדה',
  History: 'היסטוריה',

  Activities: 'פעילויות',
  Audit: 'היסטוריית שינויים',
  'No Results Found': 'לא נמצאו תוצאות',
  'No activities for this record': 'אין פעילויות עבור רשומה זו',
  'Relation removed successfully': 'הקישור הוסר בהצלחה',
  'Relation added successfully': 'הקישור הוסף בהצלחה',
  from: 'מאת',
  to: 'אל',
  Reminder: 'תזכורת',
  Task: 'משימה',
  LOW: 'נמוכה',
  MEDIUM: 'בינונית',
  HIGH: 'גבוהה',
  Status: 'סטטוס',
  PENDING: 'לא בוצע',
  DONE: 'בוצע',
  'Meeting Details': 'פרטי פגישה',
  'Task Details': 'פרטי משימה',
  Tasks: 'משימות',
  Meetings: 'פגישה',
  Events: 'אירועים',
  'Meeting & Tasks': 'פגישות ומשימות',
  Upcoming: 'בקרוב',
  Completed: 'הושלמו',
  'Edit event': 'עריכת אירוע',
  Add: 'הוספה',
  All: 'הכל',
  Cancel: 'ביטול',
  Delete: 'מחיקה',
  Name: 'שם',
  Info: 'מידע',
  'Main View': 'תצוגה ראשית',
  'Relation delete': 'מחיקת קישור',
  'Are you sure you want to delete this relation? the original card will not be deleted, but the relation and its values will be removed.':
    'האם אתה בטוח שברצונך למחוק קישור זה? הכרטיס המקורי לא יימחק, אך הקישור וערכיו יימחקו.',
  'Duplicate card': 'שכפל כרטיס',
  'Adjust {{cardTypeName}} layout': 'התאמת תצוגת {{cardTypeName}}',
  'Edit {{cardTypeName}} fields': 'עריכת שדות {{cardTypeName}}',
  'No Activities Yet': 'עדיין לא נוצרו פעילויות',
  "It looks like you haven't created any activities yet. Start by adding your first activity to track progress and stay organized!":
    'אין לך עדיין פעילויות. הוסף פעילות ראשונה כדי להתחיל לעקוב אחר ההתקדמות שלך בצורה מסודרת ונוחה.',
  'No Audit Records Found': 'לא נמצאו רשומות שינויים',
  'There are no audit records to display': 'אין רשומות שינויים להצגה',
};

export const multiFiltersComponent = {
  EQUAL: 'Equal',
  CONTAINS: 'Contains',
  EXISTS: 'Exists',
  NOT_EXISTS: 'Not exists',
  BETWEEN: 'Between',
  NOT_BETWEEN: 'Not between',
  GREATER_THAN: 'Greater and equal than',
  LESS_THAN: 'Less and equal than',
  IN: 'One of',
  NOT_IN: 'Not one of',
  TRUE: 'True',
  FALSE: 'False',
};

export const fieldsComponent = {
  leadStatus: {
    NEW: 'New',
    OPEN: 'Open',
    IN_PROGRESS: 'In Progress',
    OPEN_DEAL: 'Open Deal',
    UNQUALIFIED: 'Unqualified',
    ATTEMPTED_TO_CONTACT: 'Attempted to Contact',
    CONNECTED: 'Connected',
    BAD_TIMING: 'Bad Timing',
    PILOT: 'Pilot',
    PAYING_CUSTOMER: 'Paying Customer',
  },
  lifecycleStage: {
    SUBSCRIBER: 'Subscriber',
    LEAD: 'Lead',
    MARKETING_QUALIFIED_LEAD: 'Marketing Qualified Lead',
    SALES_QUALIFIED_LEAD: 'Sales Qualified Lead',
    OPPORTUNITY: 'Opportunity',
    CUSTOMER: 'Customer',
    EVANGELIST: 'Evangelist',
    OTHER: 'Other',
  },
  priority: {
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
    URGENT: 'Urgent',
  },
};

export const dateComponent = {
  Absolute: 'מוחלט',
  Relative: 'יחסי',
  Apply: 'החל',
  'Choose dates': 'בחר תאריכים',
  Today: 'היום',
  Yesterday: 'אתמול',
  'Last 7 days': '7 הימים האחרונים',
  'Last 30 days': '30 הימים האחרונים',
  'Last week': 'השבוע האחרון',
  'This week': 'השבוע',
  'This month': 'החודש',
  'Last month': 'החודש הקודם',
  'This year': 'השנה',
  'Last year': 'השנה הקודמת',
  'Until now': 'עד עכשיו',
  'From today': 'מהיום',
  From: 'מתאריך',
  To: 'עד תאריך',

  // Schedule
  'repeat every': 'חזור כל',
  repeat: 'חזור',
  day: 'יום',
  week: 'שבוע',
  month: 'חודש',
  year: 'שנה',
  days: 'ימים',
  weeks: 'שבועות',
  months: 'חודשים',
  years: 'שנים',
  'days of week': 'ימי השבוע',
  'day of month': 'יום בחודש',
  at: 'בשעה',
  sunday: 'ראשון',
  monday: 'שני',
  tuesday: 'שלישי',
  wednesday: 'רביעי',
  thursday: 'חמישי',
  friday: 'שישי',
  saturday: 'שבת',
};

import { z } from 'zod';
import { type ICreateCard, createCardSchema } from '~/server/api/schemas';
import { type Field } from '~db/types';

const getDefaultValues = (fields: Field[] = []): Partial<ICreateCard> => {
  const defaultValues = fields?.reduce<Record<string, any>>(
    (acc, field) => {
      if (!field.defaultValues && !field.defaultValue) return acc;
      if (field.preDefinedColumn) {
        acc[field.system] = field.defaultValue ?? field.defaultValues;
      } else {
        acc.customFields = {
          ...acc.customFields,
          [field.system]: field.defaultValue ?? field.defaultValues,
        };
      }
      return acc;
    },
    { customFields: {} },
  );
  const parsedValues = createCardSchema
    .extend({ name: z.string().optional() })
    .omit({ cardTypeId: true })
    .parse(defaultValues);

  return parsedValues;
};

export const cardUtils = {
  getDefaultValues,
};

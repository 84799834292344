import { Group, Skeleton, Text } from '@mantine/core';
import { useParams } from 'next/navigation';
import { useAppMediaQuery } from '~/ui/hooks/media-query.hook';
import { useMenuStore } from '../../Sidebar/menu.store';
import { SubItemBreadcrumb } from './SubItemBreadcrumb';
import { SubMenuSelector } from './SubMenuSelector';

export const AppBreadcrumbs: React.FC = () => {
  const { selectedMenu, selectedSubMenu } = useMenuStore((state) => ({
    selectedMenu: state.selectedMenu,
    selectedSubMenu: state.selectedSubMenu,
  }));
  const params = useParams<{ id: string; slug: string }>();
  const isMediumScreen = useAppMediaQuery('lg');
  const isSmallScreen = useAppMediaQuery('sm');
  const shouldShowCardBreadcrumb =
    selectedMenu?.showItemBreadcrumb?.(params) ?? selectedSubMenu?.showItemBreadcrumb?.(params);
  return (
    <Group gap="xs">
      {selectedMenu?.title ? (
        !isSmallScreen && (
          <Text fz={isMediumScreen ? 14 : 20} fw="600">
            {selectedMenu.title}
          </Text>
        )
      ) : selectedMenu?.title === null ? null : (
        <Skeleton width={100} height={20} />
      )}
      {shouldShowCardBreadcrumb && <SubItemBreadcrumb />}
      {selectedSubMenu && !shouldShowCardBreadcrumb && <SubMenuSelector />}
    </Group>
  );
};

import { useAuth } from '@clerk/nextjs';
import { useMantineColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { styleConfigs } from '~/Components/Providers/Styles/style.config';
import { useLanguageInit } from './locale.hook';
import { useFetchOrgList } from './organization-memberships.hook';
import { useSettings } from './settings.hook';

export const useAppInit = () => {
  const { isSignedIn, orgId, userId } = useAuth();
  const router = useRouter();
  const theme = useMantineColorScheme();
  const { settings } = useSettings();
  const isSystemDark = useMediaQuery('(prefers-color-scheme: dark)');
  useLanguageInit(settings);
  useFetchOrgList();
  useEffect(() => {
    const themeCookieValue = getCookie(styleConfigs.cookieKey);
    if (!themeCookieValue && isSystemDark === undefined) return;
    if (!themeCookieValue && typeof isSystemDark === 'boolean') {
      setCookie(styleConfigs.cookieKey, isSystemDark, { maxAge: 60 * 60 * 24 * 30 });
      theme.setColorScheme(isSystemDark ? 'dark' : 'light');
      return;
    }
    // Sync color scheme cookie to persist if the cookie is not set
    if (theme.colorScheme !== themeCookieValue) {
      setCookie(styleConfigs.cookieKey, theme.colorScheme);
    }
  }, [theme, isSystemDark]);

  useEffect(() => {
    if (isSignedIn && userId && !orgId) {
      router.push('/welcome');
    }
  }, [isSignedIn]);
};

export const settingsPage = {
  'Missing Google account': 'חסר חשבון Google',
  'You need to connect your Google account first': 'עליך לחבר את חשבון ה-Google שלך תחילה',
  "Gmail's connection is active": 'החיבור ל Gmail פעיל',
  'Connect Gmail to system': 'חיבור GMAIL למערכת',
  'Account info': 'פרטי חשבון',
  'API Tokens': 'מפתחות API',
  'API Explorer': 'שימוש ב-API',
  'User Profile': 'פרופיל משתמש',
  Organization: 'ארגון',
  'Token copied': 'המפתח הועתק',
  'Token copied to clipboard': 'המפתח הועתק ללוח',
  Token: 'מפתח',
  'Card Identifier': 'מזהה כרטיס',
  Name: 'שם',
  'Try it out': 'נסה את זה',
  'Get Record': 'קבלת רשומה',
  'Create Record': 'יצירת רשומה',
  'Update Record': 'עדכון רשומה',
  'Delete Record': 'מחיקת רשומה',
  Search: 'חיפוש',
  Action: 'פעולה',
  'Card Type': 'סוג כרטיס',
  'Request URL': 'כתובת URL לבקשה',
  'Request example': 'דוגמא לבקשה',
  'Test Request': 'בדוק בקשה',
  Result: 'תוצאה',
  Copied: 'הועתק',
  Copy: 'העתק',
  Fields: 'שדות',
  Field: 'שדה',
  'Add Field': 'הוסף שדה',
  'Required fields are automatically added': 'שדות חובה נוספים באופן אוטומטי',
  'Add more fields to the request by selecting them from the dropdown':
    'הוסף שדות נוספים לבקשה על ידי בחירתם מהרשימה הנפתחת',
  'You can also add default values for the fields': 'ניתן גם להוסיף ערכים ברירת מחדל לשדות',
  'Default value': 'ערך ברירת מחדל',
  // Org
  'Basic member': 'חבר בסיסי',
  Admin: 'מנהל',
  Members: 'משתמשים',
  Invitations: 'הזמנות',
  Email: 'דוא"ל',
  Joined: 'הצטרף',
  Actions: 'פעולות',
  'Invited at': 'הוזמן בתאריך',
  Revoke: 'בטל',
  Remove: 'הסר',
  'Invitation sent': 'הזמנה נשלחה',
  'Invitation was sent successfully to {{email}}': 'הזמנה נשלחה בהצלחה ל{{email}}',
  'System Role': 'תפקיד במערכת',
  'Organization Role': 'תפקיד בארגון',
  Invite: 'הזמן',
  'Remove member': 'הסר משתמש',
  'Are you sure that you want to remove {{member}} from the organization?':
    'האם אתה בטוח שברצונך להסיר את {{member}} מהארגון?',
  'Revoke invitation': 'בטל הזמנה',
  'Are you sure that you want to revoke invitation to {{member}}?':
    'האם אתה בטוח שברצונך לבטל את ההזמנה ל{{member}}?',
  'No pending invitations': 'אין הזמנות ממתינות',
  'Invite member': 'הזמן משתמש',
  'Member removed': 'המשתמש הוסר',
  '{{member}} was successfully removed from the organization': '{{member}} הוסר בהצלחה מהארגון',
  'Organizations relations': 'קשרי ארגונים',
  organizationsRelationsDescription: `כאן ניתן להגדיר אילו ארגונים יהיו זמינים לכל תפקיד.
  לדוגמא, אם ברצונך לתת גישה לארגון "א" לתפקיד "ב", יש לבחור את הארגון "א" בעמודת "גישה" וללחוץ "שמור".
  כדי לבצע זאת, הארגון שלך צריך להיות הורה של הארגונים הקשורים. יש ליצור קשר עם המנהל אם ברצונך לשנות את הארגון ההורה.`,
  organizationsRelationsDescriptionNoOrgs: `אין לך ארגונים משניים או שהארגון שלך אינו ארגון הורה.`,
  'No child organizations': 'אין ארגונים משניים',

  // User
  'User Profile and Security': 'פרופיל משתמש ואבטחה',
  'User Settings': 'הגדרות משתמש',
  'General Settings': 'הגדרות כלליות',
  Language: 'שפה',
  Phone: 'טלפון',
  Notifications: 'התראות',
  'Emails will be sent to the following address: {{email}}':
    'הודעות דוא"ל ישלחו לכתובת הדוא"ל הבאה: {{email}}',
  'Email notifications': 'התראות דוא"ל',
  'Receive notifications for meetings, tasks, reminders, and mentions':
    'קבל התראות לפגישות, משימות, תזכורות והזכרות',
  'Receive notifications for meetings': 'קבל התראות לפגישות',
  'Receive notifications for tasks': 'קבל התראות למשימות',
  'Receive notifications for mentions': 'קבל התראות להזכרות',
  'Enable or disable web notifications': 'הפעל או השבת התראות אינטרנט',
  'System notifications': 'התראות מערכת',
  Mentions: 'הזכרות',
  Meetings: 'פגישות',
  Tasks: 'משימות',
  'User settings updated': 'ההגדרות משתמש עודכנו',
  'Your user settings have been updated': 'ההגדרות המשתמש שלך עודכנו',
  // Plan
  'Current plan': 'תוכנית נוכחית',
  Billing: 'חיוב',
  '{{usersLimit}} users': '{{usersLimit}} משתמשים',
  '{{cardsLimit}} cards': '{{cardsLimit}} כרטיסים',
  '{{cardTypesLimit}} card types': '{{cardTypesLimit}} סוגי כרטיסים',
  '{{activitiesLimit}} activities': '{{activitiesLimit}} פעילויות',
  '{{storageLimit}} storage': '{{storageLimit}} אחסון',
  Capabilities: 'יכולות',
  'Your trial plan will expire {{expiredIn}}': 'תוקפה של תוכנית הניסיון שלך תפוג {{expiredIn}}',
  'Please upgrade your plan to continue using the app - contact support for more information':
    'יש לשדרג את התוכנית שלך כדי להמשיך להשתמש באפליקציה - יש ליצור קשר עם התמיכה לקבלת מידע נוסף',
  'Content is not a part of the plan': 'התוכן אינו חלק מהתוכנית',
  'Please contact support to upgrade your plan': 'אנא צרו קשר עם התמיכה לשדרוג התוכנית',
  'You do not have permission to access this page': 'אין לך הרשאה לגשת לדף זה',
  'Please contact your organization administrator': 'יש ליצור קשר עם מנהל הארגון שלך',
  "Next payment's date": 'תאריך התשלום הבא',
  Usage: 'שימוש',
  'out of': 'מתוך',
  '{{limit}} left': `נותרו {{limit}}`,
  'No balance left': 'אין יתרה',
  'Limit exceeded by {{diff}}': `חריגה של {{diff}}`,
  Amount: 'סכום',
  planType: {
    FREE: 'חינמית',
    TRIAL: 'ניסיון',
    STARTER: 'מתחילים',
    PRO: 'מקצועית',
    ENTERPRISE: 'ארגונית',
  },
  'Plan and Billing': 'תוכנית וחיוב',
  'Plan updated': 'תוכנית עודכנה',
  'Plan updated successfully': 'התוכנית עודכנה בהצלחה',
  Plan: 'תוכנית',
  'Plan end date': 'תאריך סיום התוכנית',
  'SMS Balance': 'יתרת SMS',
  Save: 'שמור',
  reports: 'דוחות',
  automation: 'אוטומציה',
  mailing: 'דואר אלקטרוני',
  sms: 'הודעות SMS',
  finances: 'פיננסים',
  billing: 'חיוב',
  telephony: 'טלפוניה',
  Automations: 'אוטומציות',
  'Your trial plan has expired': 'תקופת הניסיון שלך פגה',
  'Please contact support to continue using the app':
    'יש ליצור קשר עם התמיכה כדי להמשיך להשתמש באפליקציה',

  // SMS
  Success: 'הצלחה',
  'Sender id has been verified successfully': 'מזהה השולח אומת בהצלחה',
  'Sender id already allowed': 'מזהה השולח כבר מאושר',
  'Sender id has already been verified, settings updated with the new sender id':
    'מזהה השולח כבר אומת, הגדרות עודכנו עם מזהה השולח החדש',
  'Sender id verification failed': 'אימות מזהה השולח נכשל',
  'SMS - sender name': 'SMS - שם השולח',
  'Sender name that will appear on the SMS - up to 11 characters - can be a phone number or a name':
    'שם השולח שיפורסם בהודעת ה-SMS - עד 11 תווים - יכול להיות מספר טלפון או שם',
  'Change sender id': 'שנה מזהה שולח',
  'Enter the OTP code that you received': 'הזן את קוד ה-OTP שקיבלת',
  'Add sender id': 'הוסף מזהה שולח',
  'OTP code sent to': 'קוד OTP נשלח אל',
  'OTP code': 'קוד OTP',
  'Verify OTP': 'אמת OTP',
  'Enter sender id to verify': 'הזן מזהה שולח לאימות',
  'Send OTP': 'שלח OTP',

  // Api Explorer
  api: 'API',
  'Took {{duration}}ms': `התבצע ב-{{duration}}ms`,
  Error: 'שגיאה',
  Get: 'קריאה',
  Create: 'יצירה',
  'Sort Field': 'שדה למיון',
  'Sort Order': 'סדר מיון',
  Ascending: 'סדר עולה',
  Descending: 'סדר יורד',
  Limit: 'כמות',
  'Cursor is a number of records to skip. It is used for pagination.':
    'הסמן הוא מספר הרשומות שיש לדלג עליהן. הוא משמש לעימוד הנתונים.',
  Cursor: 'סמן',

  // Usage
  Users: 'משתמשים',
  Cards: 'כרטיסים',
  'Card types': 'סוגי כרטיסים',
  SMS: 'הודעות SMS',
  Activities: 'פעילויות',
  Storage: 'אחסון',
  'Failed to load usage data': 'נכשל בטעינת נתוני שימוש',
  'Failed to load plan data': 'נכשל בטעינת נתוני תוכנית',

  // Roles & Permissions
  'User Roles': 'תפקידי משתמש',
  Organizations: 'ארגונים',
  'Delete role': 'מחק תפקיד',
  'Are you sure you want to delete role {{role}}?': 'האם אתה בטוח שברצונך למחוק את התפקיד {{role}}?',
  'Cannot delete role - in use': 'לא ניתן למחוק תפקיד - בשימוש',
  'Permission denied': 'הרשאה נדחתה',
  'You are not authorized to perform {{action}} on {{type}}': 'אין לך הרשאה לבצע {{action}} על {{type}}',
  'Update permissions': 'עדכן הרשאות',
  'Please contact your organization owner': 'יש ליצור קשר עם בעל הארגון שלך',
  'Create role': 'צור תפקיד',
  'New Role': 'תפקיד חדש',
  Cancel: 'ביטול',
  Owner: 'בעלים',
  User: 'משתמש',
  Permission: 'הרשאה',
  Read: 'צפיה',
  Update: 'עריכה',
  Delete: 'מחיקה',
  Collaboration: 'שיתוף',
  'Created at': 'נוצר בתאריך',
  'Updated at': 'עודכן בתאריך',
  Role: 'תפקיד',
  Roles: 'תפקידים',
  Permissions: 'הרשאות',
  Access: 'גישה',
  collaboration: {
    ORGANIZATION: 'ברמת הארגון',
    ROLE_GROUP: 'ברמת התפקיד',
    USER: 'ברמת המשתמש',
  },
  permissionAction: {
    read: 'צפיה',
    update: 'עריכה',
    delete: 'מחיקה',
    create: 'יצירה',
  },
  permissionType: {
    CARDS: 'כרטיסים',
    AUTOMATION: 'אוטומציה',
    COMMENTS: 'הערות',
    TASKS: 'משימות',
    STORAGE: 'אחסון',
    AUDIT: 'היסטוריה',
    MEETINGS: 'פגישות',
    CARD_TYPES: 'סוגי כרטיסים',
    CARD_FIELDS: 'שדות כרטיסים',
    CARD_LAYOUTS: 'פריסות כרטיסים',
    CARDS_TABLE_VIEWS: 'תצוגות טבלה',
    ROLES: 'תפקידים',
    ADMIN_CONSOLE: 'ניהול מערכת',
    ORGANIZATION: 'ארגון',
    FINANCE_DOCUMENTS: 'מסמכים פיננסים',
    SMS: 'הודעות SMS',
  },
};

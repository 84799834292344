import { IconTrashX } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { AppActionIcon } from '~/Components/Shared/Icon/AppActionIcon';
import { env } from '~/env.mjs';

export const ClearCacheButton: React.FC = () => {
  const isDev = env.NEXT_PUBLIC_APP_ENV === 'development';
  const queryClient = useQueryClient();
  if (!isDev) return null;
  return (
    <AppActionIcon
      variant={'subtle'}
      color={'gray'}
      tooltip="Clear query cache"
      onClick={() => queryClient.clear()}
      Icon={IconTrashX}
    />
  );
};

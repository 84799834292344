export const seedCustomLabels = {
  // Card types
  Projects: 'Projektek',
  Project: 'Projekt',
  'Projects related to a company': 'Vállalathoz kapcsolódó projektek',
  'Main projects view': 'Fő projektek nézet',
  Rooms: 'Szobák',
  Room: 'Szoba',
  'Rooms and suites': 'Szobák és lakosztályok',
  'Room size': 'Szoba mérete',
  'Room size in square meters': 'Szoba mérete négyzetméterben',
  'Bed size': 'Ágy mérete',
  Single: 'Egyszemélyes',
  Double: 'Kétszemélyes',
  King: 'Királyi',
  Queen: 'Királynői',
  'Room type': 'Szobatípus',
  Regular: 'Normál',
  Suite: 'Lakosztály',
  Deluxe: 'Deluxe',
  Penthouse: 'Tetőtéri lakosztály',
  'Last cleaning': 'Utolsó takarítás',
  'Last cleaning date': 'Utolsó takarítás dátuma',
  'Main rooms view': 'Fő szobák nézet',
  Main: 'Fő',

  // custom fields
  'Eye color': 'Szemszín',
  Blue: 'Kék',
  Brown: 'Barna',
  Green: 'Zöld',
  Height: 'Magasság',
  'Height in cm': 'Magasság cm-ben',
  Anniversary: 'Évforduló',
  'Anniversary date': 'Évforduló dátuma',
  Married: 'Házas',
  'Favorite color': 'Kedvenc szín',
  Red: 'Piros',
  Yellow: 'Sárga',
  Purple: 'Lila',
  Pink: 'Rózsaszín',
  Orange: 'Narancssárga',
  Black: 'Fekete',
  White: 'Fehér',
};

import { create } from 'zustand';
import { type IMenuItem } from './types';

type MenuStore = {
  menu: IMenuItem[];
  setMenu: (menu: IMenuItem[]) => void;
  settingsMenu: IMenuItem[];
  selectedMenu?: IMenuItem;
  selectedSubMenu?: IMenuItem;
  isSubMenuOpen: boolean;
  setSettingsMenu: (settingsMenu: IMenuItem[]) => void;
  setSelectedMenu: (selectedMenu: IMenuItem) => void;
  setSelectedSubMenu: (selectedSubMenu?: IMenuItem) => void;
  setIsSubMenuOpen: (open: boolean) => void;
  sideBarWidth: number;
};

export const MAIN_MAIN_WIDTH = 59;
export const MENU_MARGIN_TOP = 20;
export const TOGGLE_MARGIN_TOP = 60;
export const SECONDARY_MENU_WIDTH = 150;

const calculateWidth = ({ selectedMenu, isSubMenuOpen }: MenuStore) => {
  if (selectedMenu?.children && isSubMenuOpen) {
    return MAIN_MAIN_WIDTH + SECONDARY_MENU_WIDTH;
  }
  return MAIN_MAIN_WIDTH;
};

export const useMenuStore = create<MenuStore>((set, get) => ({
  menu: [],
  setMenu: (menu) => set({ menu }),
  settingsMenu: [],
  setSettingsMenu: (settingsMenu) => set({ settingsMenu }),
  selectedMenu: undefined,
  selectedSubMenu: undefined,
  isSubMenuOpen: false,
  setIsSubMenuOpen: (isSubMenuOpen) =>
    set({ isSubMenuOpen, sideBarWidth: calculateWidth({ ...get(), isSubMenuOpen }) }),
  setSelectedMenu: (selectedMenu) =>
    set({
      selectedMenu,
      sideBarWidth: calculateWidth({ ...get(), selectedMenu }),
    }),
  setSelectedSubMenu: (selectedSubMenu) => set({ selectedSubMenu }),
  sideBarWidth: MAIN_MAIN_WIDTH,
}));

export const storageComponent = {
  Storage: 'Tárhely',
  'Drag files here or click to select': 'Húzd ide a fájlokat, vagy kattints a kiválasztáshoz',
  'File deleted': 'A fájl törölve',
  'File was successfully deleted': 'A fájl sikeresen törölve lett',
  Name: 'Név',
  'Last modified': 'Utoljára módosítva',
  Size: 'Méret',
  'Upload files': 'Fájlok feltöltése',
  'Click here, to upload those files': 'Kattints ide a fájlok feltöltéséhez',
  'Attach as many files as you like, each file should not exceed 5mb':
    'Csatolj annyi fájlt, amennyit szeretnél, de egy fájl mérete nem haladhatja meg az 5 MB-ot',
};

export const fieldsComponent = {
  // pre defined fields name
  'Email address': 'E-mail cím',
  Description: 'Leírás',
  Email: 'E-mail',
  'Avatar image link': 'Avatar kép linkje',
  Avatar: 'Avatar',
  'Lead status': 'Lead státusz',
  Name: 'Teljes név',
  'Phone number': 'Telefonszám',
  'Select phone numbers...': 'Válassz telefonszámokat...',
  'Add a phone number': 'Adj hozzá egy telefonszámot',
  'Social security number': 'Társadalombiztosítási szám',
  SSN: 'TB szám',
  Address: 'Cím',
  City: 'Város',
  Age: 'Életkor',
  State: 'Megye',
  'Zip code': 'Irányítószám',
  'Postal code': 'Postai kód',
  Country: 'Ország',
  'Date of birth': 'Születési dátum',
  'Job title': 'Beosztás',
  Website: 'Weboldal',
  Industry: 'Iparág',
  'Company ID': 'Cégazonosító',
  Note: 'Jegyzet',
  Fax: 'Fax',
  'Company name': 'Cég neve',
  'Number of employees': 'Dolgozók száma',
  Priority: 'Prioritás',
  Language: 'Nyelv',
  Phone: 'Telefon',
  'Lifecycle stage': 'Életciklus szakasz',
  'Form validation error': 'Űrlap érvényesítési hiba',
  'Please fill all required fields and correct validation errors':
    'Kérjük, töltsd ki az összes kötelező mezőt és javítsd az érvényesítési hibákat',
  Owner: 'Felelős',
  'Updated at': 'Frissítve ekkor',
  'Created at': 'Létrehozva ekkor',
  'Updated by': 'Frissítette',
  'Created by': 'Létrehozta',
  Source: 'Forrás',
  Medium: 'Médium',
  Campaign: 'Kampány',
  'Year founded': 'Alapítás éve',
  'Total revenue': 'Teljes bevétel',
  'Annual revenue': 'Éves bevétel',
  Comment: 'Megjegyzés',
  Price: 'Ár',
  SKU: 'Cikkszám',
  Stock: 'Készlet',
  'Start Speech Recognition': 'Beszédfelismerés indítása',
  'Stop Speech Recognition': 'Beszédfelismerés leállítása',

  'Max number of characters': 'Maximális karakterek száma',

  // field types
  Checkbox: 'Jelölőnégyzet',
  Date: 'Dátum',
  Color: 'Szín',
  Text: 'Szöveg',
  Currency: 'Pénznem',
  Accounting: 'Könyvelés',
  File: 'Fájl',
  Image: 'Kép',
  Password: 'Jelszó',
  Radio: 'Rádiógomb',
  Time: 'Idő',
  'Date time': 'Dátum és idő',
  Number: 'Szám',
  URL: 'Webcím',
  Textarea: 'Szövegmező',
  Range: 'Tartomány',
  Link: 'Hivatkozás',
  'Single select': 'Egyszeres választás',
  'Multi select': 'Többszörös választás',
  'Add field': 'Mező hozzáadása',
  'Select {{cardType}}...': 'Válassz {{cardType}}...',
  User: 'Felhasználó',
  Basic: 'Alap',
  Advanced: 'Haladó',
  'Pre Defined': 'Előre definiált',
  Back: 'Vissza',
  'Create field - {{name}}': 'Hozz létre mezőt - {{name}}',
  'Custom Field': 'Egyedi mező',
  'Save field {{label}}': `Mező mentése {{label}}`,
  'Create field': 'Mező létrehozása',
  'Select card...': 'Válassz kártyát...',
  Create: 'Létrehozás',
  'Select user...': 'Válassz felhasználót...',
  'Select users...': 'Válassz felhasználókat...',
  'Add an email': 'Adj hozzá egy e-mail címet',
  'Select emails...': 'Válassz e-mail címeket...',
  'Search...': 'Keresés...',
  'Nothing here': 'Nincs itt semmi',
  'Select cards...': 'Válassz kártyákat...',
  Geneal: 'Általános',
  leadStatus: {
    NEW: 'Új',
    OPEN: 'Nyitott',
    IN_PROGRESS: 'Folyamatban',
    OPEN_DEAL: 'Nyitott ügylet',
    UNQUALIFIED: 'Nem minősített',
    ATTEMPTED_TO_CONTACT: 'Kapcsolatfelvételi kísérlet',
    CONNECTED: 'Kapcsolatban',
    BAD_TIMING: 'Rossz időzítés',
    PILOT: 'Tesztelés',
    PAYING_CUSTOMER: 'Fizető ügyfél',
  },
  priority: {
    LOW: 'Alacsony',
    MEDIUM: 'Közepes',
    HIGH: 'Magas',
    URGENT: 'Sürgős',
  },
  lifecycleStage: {
    SUBSCRIBER: 'Előfizető',
    LEAD: 'Lead',
    MARKETING_QUALIFIED_LEAD: 'Marketing minősített lead',
    SALES_QUALIFIED_LEAD: 'Értékesítési minősített lead',
    OPPORTUNITY: 'Lehetőség',
    CUSTOMER: 'Ügyfél',
    EVANGELIST: 'Nagykövet',
    OTHER: 'Egyéb',
  },
};

export const financePage = {
  'Create At': 'תאריך יצירה',
  Client: 'לקוח',
  'Serial Number': 'מספר סידורי',
  'Total Before Vat': 'סה"כ לפני מע"מ',
  Vat: 'מע"מ',
  'Total Including Vat': 'סה"כ כולל מע"מ',
  'Created By': 'נוצר על ידי',
  Balance: 'יתרה',
  'Document Type': 'סוג מסמך',
  'New Document': 'מסמך חדש',
  '{{type}} created': '{{type}} נוצרה',
  '{{type}} updated': '{{type}} עודכנה',
  '{{type}} created successfully': '{{type}} נוצרה בהצלחה',
  '{{type}} updated successfully': '{{type}} עודכנה בהצלחה',
  'Create {{type}}': 'יצירת {{type}} חדשה',
  Create: 'יצירה',
  'Edit {{type}}': 'עריכת {{type}}',
  'Edit draft': 'עריכת טיוטה',
  Error: 'שגיאה',
  Actions: 'פעולות',
  'Create related document': 'יצירת מסמך קשור',
  'General Settings': 'הגדרות כלליות',
  'Scroll to actions': 'גלול למטה לפעולות או לחץ כאן',
  'Casual customer': 'לקוח מזדמן',
  Status: 'סטטוס',
  'Invoices / receipts': 'חשבוניות / קבלות',
  'Open tax invoices': 'חשבוניות מס פתוחות',
  'Casual customers': 'לקוחות מזדמנים',
  'Clone document': 'שכפול מסמך',

  // Document Types
  TAX_INVOICE: 'חשבונית מס',
  RECEIPT: 'קבלה',
  INVOICE_RECEIPT: 'חשבונית מס קבלה',
  CREDIT_INVOICE: 'חשבונית מס זיכוי',
  PROFORMA_INVOICE: 'חשבון עסקה',
  ORDER_CONFIRMATION: 'הזמנת עבודה',
  PRICE_OFFER: 'הצעת מחיר',
  SHIPPING_CERTIFICATE: 'תעודת משלוח',
  DEPOSIT: 'הפקדה',
  RETAINER: 'ריטיינר',
  INVOICE_FOR_RECEIPT: 'חשבונית עבור קבלה',
  DRAFTS: 'טיוטות',
  DONATION: 'תרומה',

  // Document Status
  DRAFT: 'טיוטה',
  PENDING: 'ממתין',
  APPROVED: 'אושר',
  REJECTED: 'נדחה',
  CANCELED: 'בוטל',

  // Type of related card
  EXISTING: 'לקוח קיים',
  CASUAL: 'לקוח מזדמן',
  NEW: 'לקוח חדש',

  // Form
  'For {{type}} number {{serialNumber}}': 'עבור {{type}} מספר {{serialNumber}}',
  'Clone of {{type}} number {{serialNumber}}': 'שכפול של {{type}} מספר {{serialNumber}}',
  'Select a card': 'בחר כרטיס',
  'Select the card to which you want to associate this {{type}}':
    'בחר את הכרטיס לו תרצה לקשר את ה{{type}} הזאת',
  'Create document': 'יצירת מסמך',
  'Edit document': 'עריכת מסמך',
  Preview: 'תצוגה מקדימה',
  'Save as draft': 'שמירה כטיוטה',
  'Update draft': 'עדכון טיוטה',
  Draft: 'טיוטה',
  Cancel: 'ביטול',
  "Your customer's name": 'שם הלקוח שלך',
  Customer: 'לקוח',
  'Customer name': 'שם הלקוח',
  'Customer ID / Business ID': 'מספר זהות / ח.פ',
  'Main email': 'אימייל ראשי',
  'Email addresses': 'כתובות מייל',
  'You can add multiple email addresses': 'ניתן להוסיף מספר כתובות מייל',
  Date: 'תאריך',
  'The date can be selected from the day the last document of this type was produced until today':
    'בחירת התאריך מתאפשרת מהיום בו הופק המסמך האחרון מסוג זה ועד היום',
  Currency: 'מטבע',
  'Document language': 'שפת המסמך',
  'The type of currency in which the document will be issued': 'סוג המטבע בו יופק המסמך',
  'The language in which the document will be produced': 'השפה בה יופק המסמך',
  'Document title': 'כותרת המסמך',
  Notes: 'הערות',
  'Related documents': 'מסמכים קשורים',

  //Form Title
  'Documents creation': 'הפקת מסמכים',
  'Number {{serialNumber}}': 'מספר {{serialNumber}}',
  'Document status: {{status}}': 'סטטוס המסמך: {{status}}',
  View: 'צפייה',

  // Products
  VAT: 'מע"מ',
  SKU: 'מק"ט',
  Description: 'תיאור',
  Quantity: 'כמות',
  Price: 'מחיר ללא מע"מ',
  'Price incl. VAT': 'מחיר כולל מע"מ',
  'Total price without VAT': 'סה"כ ללא מע"מ',
  TAXABLE: 'חייב',
  EXEMPTION: 'פטור',
  'Add product': 'הוספת מוצר',
  Discount: 'הנחה',
  'Round total': 'עיגול סכום',
  'Total VAT liable': 'סה"כ חייב במע"מ',
  'Rounded amount': 'סכום לעיגול',
  'Total VAT': 'סה"כ מע"מ',
  'Before VAT': 'לפני מע"מ',
  'Total VAT liable after discount': 'סה"כ חייב במע"מ לאחר הנחה',
  'Total incl. VAT': 'סה"כ כולל מע"מ',
  'Total Due': 'סה"כ לתשלום',
  'SKU is required': 'מק"ט הינו שדה חובה',
  'Description is required': 'תיאור הינו שדה חובה',
  'Quantity is required': 'כמות הינה שדה חובה',
  'Create products catalog': 'יצירת קטלוג מוצרים',
  'By creating a product catalog, you can store your products and easily include them in your financial documents':
    'על ידי יצירת קטלוג מוצרים, אתה יכול לאחסן את המוצרים שלך ולכלול אותם בקלות במסמכים הפיננסיים שלך',

  // Preview
  from: 'מאת',
  id: 'מספר זיהוי',
  Address: 'כתובת',
  Phone: 'טלפון',
  Email: 'דוא"ל',
  'Email Address': 'כתובת דוא"ל',
  For: 'לכבוד',
  'Id / Business Number': 'מספר עוסק/ח.פ/ת.ז',
  notes: 'הערות',
  Signature: 'חתימה',
  'View document': 'צפייה במסמך',
  Download: 'הורדה',
  'Download document': 'הורדת מסמך',
  'Download copy of document': 'הורד עותק של המסמך',
  'Back to documents': 'חזרה למסמכים',
  ORIGINAL: 'מקור',
  COPY: 'העתק נאמן למקור',
  PREVIEW: 'תצוגה מקדימה',
  // Signature
  'This document is digitally signed': 'מסמך זה חתום דיגיטלית',
  'This document has been generated by icom software inc.': 'מסמך זה נוצר על ידי אייקום תוכנה בע׳׳מ',
  // Email
  'Finance document - {{type}} - {{serialNumber}}': 'מסמך פיננסי - {{type}} - {{serialNumber}}',
  'Hi {{customerName}}': 'שלום {{customerName}}',
  'You have received a {{type}} - {{serialNumber}}': 'קיבלת {{type}} - {{serialNumber}}',
  'You can view the document by clicking the button below':
    'ניתן לצפות במסמך על ידי לחיצה על הכפתור למטה',
  'Document - {{type}} {{serialNumber}} for {{customerName}} from {{businessName}}':
    'מסמך - {{type}} {{serialNumber}} עבור {{customerName}} מאת {{businessName}}',

  Name: 'שם',
  Type: 'סוג',
  Amount: 'סכום',
  Products: 'מוצרים',
  Payments: 'תשלומים',

  CASH: 'מזומן',
  CHECK: "צ'ק",
  CREDIT_CARD: 'כרטיס אשראי',
  BANK_TRANSFER: 'העברה בנקאית',
  WITHHOLDING_TAX: 'ניכוי מס במקור',
  OTHER: 'אחר',

  // Payments
  'Payment Method': 'אמצעי תשלום',
  Bank: 'בנק',
  Branch: 'סניף',
  'Account Number': 'מספר חשבון',
  'Check Id': "מספר צ'ק",
  'Transaction Id': 'מספר העברה',
  Sum: 'סכום',
  Total: 'סה"כ',
  'Payment Type': 'סוג תשלום',
  'Credit Card Id': 'מספר כרטיס',
  'Credit Card Type': 'סוג כרטיס',
  'Add payment': 'הוספת תשלום',
  'Payment Details': 'פרטי תשלום',

  'Total cash': 'סה"כ מזומן',
  'Total checks': 'סה"כ צ\'קים',
  'Total credit card': 'סה"כ כרטיס אשראי',
  'Total bank transfer': 'סה"כ העברה בנקאית',
  'Total other': 'סה"כ אחר',
  'Total with holding tax': 'סה"כ ניכוי מס במקור',
  'Total paid': 'סה"כ שולם',

  // Credit Card
  UNKNOWN: 'לא ידוע',
  MASTER_CARD: 'מאסטרקארד',
  DINERS: 'דיינרס',
  ISRACARD: 'ישראכרט',
  AMERICAN_EXPRESS: 'אמריקן אקספרס',
  VISA: 'ויזה',

  // settings
  Organization: 'ארגון',
  'Organization Name': 'שם הארגון',
  'Business Number': 'מספר עסק',
  'Business Type': 'סוג עסק',
  Language: 'שפה',
  'Default VAT': 'מע"מ ברירת מחדל',
  'Default Currency': 'מטבע ברירת מחדל',
  'VAT Percentage': 'אחוז מע"מ',
  'Theme Color': 'צבע עיצוב',
  'Last doc: {{max}}': 'מסמך אחרון: {{max}}',
  Save: 'שמירה',
  'Serial Numbers': 'מספור מסמכים',
  Design: 'עיצוב',
  'Failed to save settings': 'שמירת הגדרות נכשלה',
  Settings: 'הגדרות',
  'Missing settings': 'הגדרות חסרות',
  'You need to set up your finance settings before you can use this feature':
    'עליך להגדיר את הגדרות הפיננסים שלך לפני שתוכל להשתמש בתכונה זו',
  'Create & Update settings': 'יצירה ועדכון הגדרות',

  'Settings updated': 'הגדרות עודכנו',
  'Finance settings updated successfully': 'הגדרות פיננסיות עודכנו בהצלחה',
  'Once a document is created, you cannot change the starting serial number':
    'לאחר יצירת מסמך, לא ניתן לשנות את מספר הסידור התחלתי',

  // Business types
  SOLE_PROPRIETORSHIP: 'עוסק פטור',
  AUTHORIZED_DEALER: 'עוסק מורשה',
  LIMITED_COMPANY: 'חברה בע"מ',
  NON_PROFIT_ORGANIZATION: 'עמותה',

  // Dashboard
  'Main overview': 'סקירה כללית',
  'This month': 'החודש',
  'This year': 'השנה',
  'Cash Balance': 'מזומן שנותר לקבל',
  'Pending Payments': 'תשלומים ממתינים',
  'Documents Count': 'מספר מסמכים',
  'Total Revenue': 'הכנסה כוללת',
  'Total Before VAT': 'הכנסה לפני מע"מ',
  'Total Paid': 'תשלום שהתקבל',
  'Documents types': 'סוגי מסמכים',
  'Money flow': 'תזרים כספי',
  'vs Last month': 'לעומת החודש שעבר',
  'vs Last year': 'לעומת השנה שעברה',
  'No data available': 'אין נתונים זמינים',
};

export const zodHungarianLocale = {
  errors: {
    invalid_type: 'Elvárt típus: {{expected}}, kapott: {{received}}',
    invalid_type_received_undefined: 'Kötelező mező',
    invalid_type_received_null: 'Kötelező mező',
    invalid_literal: 'Érvénytelen érték, elvárt: {{expected}}',
    unrecognized_keys: 'Ismeretlen kulcsok az objektumban: {{- keys}}',
    invalid_union: 'Érvénytelen adatbevitel',
    invalid_union_discriminator: 'Érvénytelen azonosító érték. Elvárt: {{- options}}',
    invalid_enum_value: "Érvénytelen enum érték. Elvárt: {{- options}}, kapott: '{{received}}'",
    invalid_arguments: 'Érvénytelen függvényparaméterek',
    invalid_return_type: 'Érvénytelen függvény visszatérési típus',
    invalid_date: 'Érvénytelen dátum',
    custom: 'Érvénytelen adatbevitel',
    invalid_intersection_types: 'A típusok nem kombinálhatóak',
    not_multiple_of: 'A számnak a következő többszörösének kell lennie: {{multipleOf}}',
    not_finite: 'A számnak végesnek kell lennie',
    invalid_string: {
      email: 'Érvénytelen e-mail cím',
      url: 'Érvénytelen URL',
      uuid: 'Érvénytelen UUID',
      cuid: 'Érvénytelen CUID',
      regex: 'Érvénytelen adatbevitel',
      datetime: 'Érvénytelen dátum és idő',
      startsWith: 'Érvénytelen adatbevitel: "{{startsWith}}" kezdetűnek kell lennie',
      endsWith: 'Érvénytelen adatbevitel: "{{endsWith}}" végződésűnek kell lennie',
    },
    too_small: {
      array: {
        exact: 'A tömbnek pontosan {{minimum}} elemet kell tartalmaznia',
        inclusive: 'A tömbnek legalább {{minimum}} elemet kell tartalmaznia',
        not_inclusive: 'A tömbnek több mint {{minimum}} elemet kell tartalmaznia',
      },
      string: {
        exact: 'A szövegnek pontosan {{minimum}} karaktert kell tartalmaznia',
        inclusive: 'A szövegnek legalább {{minimum}} karaktert kell tartalmaznia',
        not_inclusive: 'A szövegnek több mint {{minimum}} karaktert kell tartalmaznia',
      },
      number: {
        exact: 'A számnak pontosan {{minimum}}-nak kell lennie',
        inclusive: 'A számnak legalább {{minimum}}-nak kell lennie',
        not_inclusive: 'A számnak nagyobbnak kell lennie, mint {{minimum}}',
      },
      set: {
        exact: 'Érvénytelen adatbevitel',
        inclusive: 'Érvénytelen adatbevitel',
        not_inclusive: 'Érvénytelen adatbevitel',
      },
      date: {
        exact: 'A dátumnak pontosan {{- minimum, datetime}}-nek kell lennie',
        inclusive: 'A dátumnak legalább {{- minimum, datetime}}-nek kell lennie',
        not_inclusive: 'A dátumnak nagyobbnak kell lennie, mint {{- minimum, datetime}}',
      },
    },
    too_big: {
      array: {
        exact: 'A tömbnek pontosan {{maximum}} elemet kell tartalmaznia',
        inclusive: 'A tömbnek legfeljebb {{maximum}} elemet kell tartalmaznia',
        not_inclusive: 'A tömbnek kevesebb mint {{maximum}} elemet kell tartalmaznia',
      },
      string: {
        exact: 'A szövegnek pontosan {{maximum}} karaktert kell tartalmaznia',
        inclusive: 'A szövegnek legfeljebb {{maximum}} karaktert kell tartalmaznia',
        not_inclusive: 'A szövegnek kevesebb mint {{maximum}} karaktert kell tartalmaznia',
      },
      number: {
        exact: 'A számnak pontosan {{maximum}}-nak kell lennie',
        inclusive: 'A számnak legfeljebb {{maximum}}-nak kell lennie',
        not_inclusive: 'A számnak kisebbnek kell lennie, mint {{maximum}}',
      },
      set: {
        exact: 'Érvénytelen adatbevitel',
        inclusive: 'Érvénytelen adatbevitel',
        not_inclusive: 'Érvénytelen adatbevitel',
      },
      date: {
        exact: 'A dátumnak pontosan {{- maximum, datetime}}-nek kell lennie',
        inclusive: 'A dátumnak legfeljebb {{- maximum, datetime}}-nek kell lennie',
        not_inclusive: 'A dátumnak kisebbnek kell lennie, mint {{- maximum, datetime}}',
      },
    },
  },
  validations: {
    email: 'E-mail cím',
    url: 'URL cím',
    uuid: 'UUID azonosító',
    cuid: 'CUID azonosító',
    regex: 'Szabályos kifejezés',
    datetime: 'Dátum és idő',
  },
  types: {
    function: 'Függvény',
    number: 'Szám',
    string: 'Szöveg',
    nan: 'NaN',
    integer: 'Egész szám',
    float: 'Tizedes szám',
    boolean: 'Logikai érték',
    date: 'Dátum',
    bigint: 'Nagy szám',
    undefined: 'Nem meghatározott',
    symbol: 'Szimbólum',
    null: 'Null',
    array: 'Tömb',
    object: 'Objektum',
    unknown: 'Ismeretlen',
    promise: 'Promise',
    void: 'Void',
    never: 'Soha',
    map: 'Térkép',
    set: 'Halmaz',
  },
};

export const adminPage = {
  Organizations: 'ארגונים',
  Organization: 'ארגון',
  'Organization name': 'שם הארגון',
  Plan: 'תוכנית',
  'Registered users': 'משתמשים רשומים',
  'More Clerk details': 'פרטים נוספים ב-Clerk',
  'View in Clerk': 'צפה ב-Clerk',
  'Created at': 'נוצר בתאריך',
  Actions: 'פעולות',
  'Organization ID': 'מזהה ארגון',
  'Max Allowed users': 'מספר מקסימלי של משתמשים',
  Users: 'משתמשים',
  'Members count': 'מספר משתמשים',
  'Max allowed memberships': 'מספר מקסימלי של משתמשים',
  Name: 'שם',
  Email: 'אימייל',
  Id: 'מזהה',
  'Clerk Role': 'תפקיד ב-Clerk',
  Impersonate: 'אימפרסונציה',
  'Danger Zone': 'אזור מסוכן',
  'Delete Organization': 'מחק ארגון',
  Unlimited: 'ללא הגבלה',
  'Are you sure you want to delete this organization?': 'האם אתה בטוח שאתה רוצה למחוק את הארגון הזה?',
  'You can only filter by one field at a time': 'ניתן לסנן רק לפי שדה אחד בכל פעם',
  'Organization info': 'פרטי ארגון',
};

export const taskReminderEmail = {
  'Reminder: task due soon - {{title}}': 'תזכורת: משימה עתידית - {{title}}',
  'Task Reminder': 'תזכורת משימה',
  'Hi {{username}}': 'שלום {{username}}',
  'You have a task due soon. Here are the details:': 'יש לך משימה עתידית. הנה הפרטים:',
  Title: 'כותרת',
  Description: 'תיאור',
  Location: 'מיקום',
  'Due Date': 'תאריך יעד',
  'View Task': 'צפה במשימה',
};

import { Stack, Table, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { User } from '~/Components/Form/Lookup/UserSelect';
import { realtimeState } from '~/Components/Integrations/Realtime/Realtime';
import { AppLink } from '~/Components/Shared/AppLink';
import { dateTimeService } from '~/utils/date-time.service';
import { useActiveMenuItem } from '../../Sidebar/menu.hook';

export const ActiveUsers: React.FC = () => {
  const { t } = useTranslation('headerComponent');
  const { presence } = realtimeState(({ presence }) => ({ presence }));
  const { getItemTitle } = useActiveMenuItem();
  return (
    <Stack>
      <Title order={1} size="sm">
        {t('Active users')}
      </Title>
      <Table>
        <Table.Tbody>
          {Object.entries(presence).map(([userId, state]) => {
            return (
              <Table.Tr key={userId}>
                <Table.Td>
                  <User userId={userId} />
                </Table.Td>
                <Table.Td>
                  <AppLink href={state.urlState.pathname}>
                    {getItemTitle(state.urlState.pathname)}
                  </AppLink>
                </Table.Td>
                <Table.Td>{dateTimeService.relativeTime(state.date)}</Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </Stack>
  );
};

export const meetingReminderSms = {
  'Hi {{name}}, This is a reminder from {{from}} for the {{title}} at {{time}} at {{location}}.':
    'שלום {{name}}, זוהי תזכורת מאת {{from}} לפגישה {{title}} בשעה {{time}} במיקום {{location}}.',
  'Hi {{name}}, a meeting {{title}} with {{from}} scheduled for {{time}} at {{location}}.':
    'שלום {{name}}, פגישה {{title}} עם {{from}} שנקבעה לשעה {{time}} במיקום {{location}}.',
  'Hi {{name}}, Your {{title}} with {{from}} scheduled for {{time}} at {{location}} has been canceled.':
    'שלום {{name}}, הפגישה {{title}} עם {{from}} שנקבעה לשעה {{time}} במיקום {{location}} בוטלה.',
  'Hi {{name}}, Your {{title}} with {{from}} has been rescheduled to {{time}} at {{location}}.':
    'שלום {{name}}, הפגישה {{title}} עם {{from}} הועברה לשעה {{time}} במיקום {{location}}.',
};

export const taskReminderSms = {
  'Hi {{name}}, This is a reminder from {{from}} for the {{title}} at {{time}} at {{location}}.':
    'שלום {{name}}, זוהי תזכורת מאת {{from}} למשימה {{title}} בשעה {{time}} במיקום {{location}}.',
  'Hi {{name}}, a task {{title}} with {{from}} scheduled for {{time}} at {{location}}.':
    'שלום {{name}}, משימה {{title}} עם {{from}} שנקבעה לשעה {{time}} במיקום {{location}}.',
  'Hi {{name}}, Your {{title}} with {{from}} scheduled for {{time}} at {{location}} has been canceled.':
    'שלום {{name}}, המשימה {{title}} עם {{from}} שנקבעה לשעה {{time}} במיקום {{location}} בוטלה.',
  'Hi {{name}}, Your {{title}} with {{from}} has been rescheduled to {{time}} at {{location}}.':
    'שלום {{name}}, המשימה {{title}} עם {{from}} הועברה לשעה {{time}} במיקום {{location}}.',
};

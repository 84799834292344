export const taskReminderEmail = {
  'Reminder: task due soon - {{title}}': 'Emlékeztető: hamarosan esedékes feladat - {{title}}',
  'Task Reminder': 'Feladat emlékeztető',
  'Hi {{username}}': 'Szia {{username}}',
  'You have a task due soon. Here are the details:':
    'Hamarosan esedékes feladatod van. Íme a részletek:',
  Title: 'Cím',
  Description: 'Leírás',
  Location: 'Helyszín',
  'Due Date': 'Határidő',
  'View Task': 'Feladat megtekintése',
};

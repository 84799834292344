export const multiFiltersComponent = {
  EQUAL: 'Egyenlő',
  CONTAINS: 'Tartalmaz',
  EXISTS: 'Érték létezik',
  NOT_EXISTS: 'Nincs érték',
  BETWEEN: 'Között',
  NOT_BETWEEN: 'Nem között',
  GREATER_THAN: 'Nagyobb vagy egyenlő',
  LESS_THAN: 'Kisebb vagy egyenlő',
  IN: 'Egy a következők közül',
  NOT_IN: 'Nem egy a következők közül',
  'Apply filters': 'Szűrők alkalmazása',
  Cancel: 'Mégse',
  'Add filter': 'Szűrő hozzáadása',
  'Search for field': 'Mező keresése',
  '{{count}} more...': `Még {{count}}...`,
  'not {{field}}': 'Nem {{field}}',
  Yes: 'Igen',
  No: 'Nem',
  'Without value': 'Nincs érték',
  'Greater than or equal': 'Nagyobb vagy egyenlő',
  'Less than or equal': 'Kisebb vagy egyenlő',
  TRUE: 'Igen',
  FALSE: 'Nem',
  Start: 'Kezdődik',
  End: 'Végződik',
  'Clear filters': 'Szűrők törlése',
  'Clear all filters': 'Összes szűrő törlése',
  'Are you sure you want to clear all filters?': 'Biztosan törölni szeretnéd az összes szűrőt?',
};

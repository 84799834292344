import { useAuth } from '@clerk/nextjs';
import { Avatar, Button, Group, Skeleton, Stack, Text } from '@mantine/core';
import { IconChevronDown, IconChevronRight, IconChevronUp } from '@tabler/icons-react';
import NextLink from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { useCard } from '~/Components/Card/card.hook';
import { useCardsTableNavigation } from '~/Components/Cards/CardsTable/cards-table-navigation.hook';
import { useCardTypes } from '~/Components/Cards/card-types.hook';
import { AppActionIcon } from '~/Components/Shared/Icon/AppActionIcon';
import { api } from '~/trpc';
import { useLocale } from '~/ui/hooks/locale.hook';
import { useAppMediaQuery } from '~/ui/hooks/media-query.hook';
import { testIdUtils } from '~/utils/test-id.service';
import { AppIcon } from '../../../Shared/Icon/AppIcon';
import { useMenuStore } from '../../Sidebar/menu.store';

export const SubItemBreadcrumb: React.FC = () => {
  const params = useParams();
  const { selectedSubMenu, selectedMenu } = useMenuStore((state) => ({
    selectedSubMenu: state.selectedSubMenu,
    selectedMenu: state.selectedMenu,
  }));
  const isSmallScreen = useAppMediaQuery('lg');
  if (!selectedSubMenu) return null;
  const backToMenuUrl = selectedMenu?.getBreadcrumbBackUrl?.(params) ?? selectedSubMenu.path;
  const Breadcrumb = selectedMenu?.Breadcrumb ?? selectedSubMenu?.Breadcrumb;
  return (
    <Group gap={0} wrap={isSmallScreen ? 'nowrap' : 'wrap'}>
      <NextLink href={backToMenuUrl}>
        <Button
          fz={isSmallScreen ? 14 : 20}
          px={'xs'}
          variant="subtle"
          leftSection={<IconChevronRight size={isSmallScreen ? '1rem' : '1.4rem'} />}
        >
          {selectedSubMenu.title}
        </Button>
      </NextLink>
      {Breadcrumb && <Breadcrumb />}
    </Group>
  );
};

export const CardBreadcrumb: React.FC = () => {
  const pathname = usePathname();
  const { id } = useParams<{ id: string }>();
  const { isSignedIn, isLoaded, orgId } = useAuth();
  const { card } = useCard(
    id,
    pathname.startsWith('/cards') && Boolean(isSignedIn) && Boolean(isLoaded) && Boolean(orgId),
  );
  const isSmallScreen = useAppMediaQuery('lg');
  const { t } = useTranslation('cardComponent');
  const isMobile = useAppMediaQuery('xs');
  const { nextCard, previousCard } = useCardsTableNavigation(id);
  const { align } = useLocale();
  const hasNavigation = Boolean(nextCard) || Boolean(previousCard);
  return (
    <Group gap={isSmallScreen ? 4 : 'xs'}>
      <AppIcon Icon={IconChevronRight} size={isSmallScreen ? '1rem' : '1.4rem'} color="gray" />
      {!isSmallScreen && card?.avatar && <Avatar src={card?.avatar} size={'sm'} radius={26} />}
      {card?.name ? (
        <Text
          {...testIdUtils.prop('card-breadcrumb-name')}
          fz={isSmallScreen ? 14 : 20}
          truncate
          maw={isMobile ? 70 : 300}
        >
          {card.name}
        </Text>
      ) : (
        <Skeleton width={100} height={30} />
      )}
      {hasNavigation ? (
        <Stack align="center" gap={0}>
          <NextLink style={{ display: 'flex' }} href={previousCard?.url ?? ''}>
            <AppActionIcon
              size={20}
              disabled={!previousCard?.url}
              Icon={IconChevronUp}
              position={align}
              tooltip={previousCard?.card.name ?? t('Previous')}
              {...testIdUtils.prop('card-breadcrumb-previous')}
            />
          </NextLink>
          <NextLink style={{ display: 'flex' }} href={nextCard?.url ?? ''}>
            <AppActionIcon
              size={20}
              disabled={!nextCard?.url}
              Icon={IconChevronDown}
              position={align}
              tooltip={nextCard?.card.name ?? t('Next')}
              {...testIdUtils.prop('card-breadcrumb-next')}
            />
          </NextLink>
        </Stack>
      ) : null}
    </Group>
  );
};

export const CardTypeBreadcrumb: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t: tCardType } = useTranslation('cardTypeComponent');
  const { currentCardType, isLoadingCardTypes } = useCardTypes();
  const pluralName = id === 'new' ? tCardType('Create card type') : (currentCardType?.pluralName ?? '');

  if (isLoadingCardTypes) return null;

  return (
    <Group gap={'xs'}>
      <AppIcon Icon={IconChevronRight} size={'1.4rem'} color="gray" />
      <Text fz={20}>{pluralName}</Text>
    </Group>
  );
};

export const OrganizationBreadcrumb: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const { data: org, isFetched } = api.adminConsole.getOrganization.useQuery(orgId, {
    enabled: Boolean(orgId),
  });
  return (
    <Group gap={'xs'}>
      <AppIcon Icon={IconChevronRight} size={'1.4rem'} color="gray" />
      {isFetched ? <Text fz={20}>{org?.name}</Text> : <Skeleton width={100} height={30} />}
    </Group>
  );
};

export const meetingReminderEmail = {
  'Meeting scheduled - {{title}}': 'Találkozó ütemezve - {{title}}',
  'Meeting changed - {{title}}': 'Találkozó módosítva - {{title}}',
  'Meeting canceled - {{title}}': 'Találkozó törölve - {{title}}',
  'Meeting reminder - {{title}}': 'Találkozó emlékeztető - {{title}}',
  Hi: 'Szia',
  'You have a been invited to a meeting. Here are the details:':
    'Meghívást kaptál egy találkozóra. Íme a részletek:',
  Title: 'Cím',
  Description: 'Leírás',
  Location: 'Helyszín',
  When: 'Mikor',
  Who: 'Kivel',
};

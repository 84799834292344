export const multiFiltersComponent = {
  EQUAL: 'שווה',
  CONTAINS: 'מכיל',
  EXISTS: 'קיים ערך',
  NOT_EXISTS: 'לא קיים ערך',
  BETWEEN: 'בין',
  NOT_BETWEEN: 'לא בין',
  GREATER_THAN: 'גדול ושווה',
  LESS_THAN: 'קטן ושווה',
  IN: 'אחד מ',
  NOT_IN: 'לא אחד מ',
  'Apply filters': 'החל סינון',
  Cancel: 'ביטול',
  'Add filter': 'הוסף סינון',
  'Search for field': 'חפש שדה',
  '{{count}} more...': `ועוד {{count}}...`,
  'not {{field}}': 'לא {{field}}',
  Yes: 'כן',
  No: 'לא',
  'Without value': 'ללא ערך',
  'Greater than or equal': 'גדול או שווה',
  'Less than or equal': 'קטן או שווה',
  TRUE: 'כן',
  FALSE: 'לא',
  Start: 'מתחיל ב',
  End: 'מסתיים ב',
  'Clear filters': 'נקה פילטרים',
  'Clear all filters': 'נקה את כל הפילטרים',
  'Are you sure you want to clear all filters?': 'האם אתה בטוח שברצונך לנקות את כל הפילטרים?',
};

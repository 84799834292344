import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { type LegacyRef, type RefObject, createRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '~/ui/hooks/locale.hook';
import { useMenuStore } from './menu.store';

type IToggleButtonProps = {
  label?: string;
  ref?: LegacyRef<HTMLButtonElement>;
};

const InnerButton: React.FC<IToggleButtonProps> = forwardRef<HTMLButtonElement, IToggleButtonProps>(
  (_, ref) => {
    const theme = useMantineTheme();
    const { isOpen, setIsOpen } = useMenuStore((state) => ({
      isOpen: state.isSubMenuOpen,
      setIsOpen: state.setIsSubMenuOpen,
    }));
    const { align } = useLocale();
    const rightChevron = (isOpen && align === 'right') || (!isOpen && align === 'left');
    return (
      <ActionIcon
        ref={ref}
        onClick={() => setIsOpen(!isOpen)}
        variant="filled"
        color={theme.primaryColor}
        size="xs"
        mt={'8px'}
        style={{
          transition: `${align} 300ms`,
          position: 'absolute',
          [align]: isOpen ? 200 : 50,
          zIndex: 20,
        }}
      >
        {rightChevron ? <IconChevronRight size="1.125rem" /> : <IconChevronLeft size="1.125rem" />}
      </ActionIcon>
    );
  },
);
InnerButton.displayName = 'InnerButton';

export const ToggleButton: React.FC = () => {
  const ref: RefObject<HTMLButtonElement> = createRef();
  const { t } = useTranslation('navbarComponent');
  const { isSubMenuOpen } = useMenuStore((state) => ({ isSubMenuOpen: state.isSubMenuOpen }));
  const label = t(isSubMenuOpen ? 'Close Navigation' : 'Open Navigation');

  return (
    <Tooltip label={label} position="right" withArrow openDelay={150}>
      <InnerButton ref={ref} />
    </Tooltip>
  );
};

import { Box, Button, Group, Modal, ScrollArea, Stack, Text, Title, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type Meeting as MeetingType,
  type Task as TaskType,
  isMeeting,
  isTask,
} from '~/server/services/activity/types';
import { appHeights } from '~/ui/app-constants';
import { useLocale } from '~/ui/hooks/locale.hook';
import { useSettings } from '~/ui/hooks/settings.hook';
import { dateTimeService } from '~/utils/date-time.service';
import { Meeting } from '../../Meeting/Meeting';
import { UpsertMeeting } from '../../Meeting/UpsertMeeting';
import { useUpsertMeetingStore } from '../../Meeting/upsert-meeting.hook';
import { Task } from '../../Task/Task';
import { UpsertTask } from '../../Task/UpsertTask';
import { useUpsertTaskStore } from '../../Task/upsert-task.hook';
import { useSnoozeReminder } from '../SnoozeReminder/snooze-reminder.hook';
import { useActiveReminders } from './active-reminders.hook';

const ActivityItem: React.FC<{ activity: MeetingType | TaskType; onAction: () => void }> = ({
  activity,
  onAction,
}) => {
  if (isMeeting(activity)) {
    return <Meeting key={activity.id} meeting={activity} onAction={onAction} />;
  }
  if (isTask(activity)) {
    return <Task key={activity.id} task={activity} onAction={onAction} />;
  }
  return null;
};

export const ActiveReminders: React.FC = () => {
  const { t } = useTranslation('activitiesComponent');
  const [opened, { close, open }] = useDisclosure(false);
  const { locale } = useLocale();
  const [timeouts, setTimeouts] = useState<Record<string, NodeJS.Timeout>>({});
  const { userSettings } = useSettings();
  const { futureReminders, isLoading } = useActiveReminders();
  const [currentActivity, setCurrentActivity] = useState<TaskType | MeetingType>();
  const { opened: openedTask } = useUpsertTaskStore();
  const { opened: openedMeeting } = useUpsertMeetingStore();
  const { isSnoozable, removeReminder, snooze, isUpdating } = useSnoozeReminder({
    activity: currentActivity,
    onSuccessCallback: close,
  });

  useEffect(() => {
    if (
      'Notification' in window &&
      Notification.permission === 'default' &&
      userSettings?.notifications.web
    ) {
      window.Notification.requestPermission();
    }
  }, [userSettings]);

  const showNotification = (activity: MeetingType | TaskType) => {
    if ('Notification' in window && window.Notification.permission === 'granted') {
      const notification = new window.Notification(t('{{type}} reminder', { type: t(activity.type) }), {
        body: t('Reminder for {{type} "{{title}}" \n that starts at {{time}}', {
          title: activity.title,
          type: t(activity.type),
          time: dateTimeService.formatTime(activity.date),
        }),
        badge: '/favicon.ico',
        data: { id: activity.id, type: activity.type },
        lang: locale,
        requireInteraction: true,
        icon: '/favicon.ico',
        tag: 'reminder',
      });

      notification.onclick = () => {
        setCurrentActivity(activity);
        open();
        window.focus();
      };
    }
  };

  useEffect(() => {
    if (futureReminders.length > 0) {
      const newTimeouts: Record<string, NodeJS.Timeout> = {};
      for (const activity of futureReminders) {
        if (!activity.reminder) return;
        const timeToTrigger = activity.reminder.getTime() - Date.now();
        const timeout = setTimeout(() => {
          setCurrentActivity(activity);
          open();
          showNotification(activity);
        }, timeToTrigger);

        newTimeouts[activity.id] = timeout;
      }
      setTimeouts(newTimeouts);
    }
    return () => {
      Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [futureReminders, open]);

  if (isLoading || futureReminders.length === 0) {
    return null;
  }

  return (
    <>
      {opened ? (
        <Modal
          opened={opened}
          keepMounted={false}
          onClose={removeReminder}
          title={<Text fz={'xl'}>{t('Reminders')}</Text>}
          size="xl"
        >
          <Stack>
            <ScrollArea.Autosize mah={appHeights.LARGE_MODAL_CONTENT} type="auto">
              <Stack gap={'xs'}>
                {currentActivity && (
                  <>
                    {currentActivity.reminder ? (
                      <Text>{dateTimeService.relativeTime(currentActivity.reminder)}</Text>
                    ) : null}
                    <ActivityItem key={currentActivity.id} activity={currentActivity} onAction={close} />
                  </>
                )}
                {futureReminders.length > 1 && <Title order={3}>{t('upcoming')}</Title>}
                {futureReminders
                  .filter((activity) => activity.id !== currentActivity?.id)
                  .map((activity) => {
                    return (
                      <Box key={activity.id}>
                        {activity.reminder ? (
                          <Text>{dateTimeService.relativeTime(activity.reminder)}</Text>
                        ) : null}
                        <ActivityItem key={activity.id} activity={activity} onAction={close} />
                      </Box>
                    );
                  })}
              </Stack>
            </ScrollArea.Autosize>
            <Group justify="end">
              <Tooltip label={t('Remove reminder')} openDelay={300}>
                <Button onClick={removeReminder} loading={isUpdating}>
                  {t('Got it')}
                </Button>
              </Tooltip>
              {isSnoozable && (
                <Button onClick={snooze} loading={isUpdating}>
                  {t('Remind me 5 minutes before')}
                </Button>
              )}
            </Group>
          </Stack>
        </Modal>
      ) : null}
      {openedMeeting && <UpsertMeeting />}
      {openedTask && <UpsertTask />}
    </>
  );
};

export const cardPage = {
  'Delete card': 'Kártya törlése',
  'Change card type': 'Kártyatípus módosítása',
  'Card deleted': 'A kártya törölve',
  "{{cardTypeName}} '{{cardName}}' deleted successfully":
    "{{cardTypeName}} '{{cardName}}' sikeresen törölve",
  'Edit Card Type - {{cardTypeName}}': 'Kártyatípus szerkesztése - {{cardTypeName}}',
  'Are you sure you want to delete {{cardTypeName}} "{{cardName}}"?':
    'Biztosan törölni szeretnéd a(z) {{cardTypeName}} "{{cardName}}" kártyát?',
  Previous: 'Előző',
  Next: 'Következő',
  'Options & Actions': 'Opciók és műveletek',
  'Status & Progress': 'Állapot és előrehaladás',
  Storage: 'Tárolás',
  Comments: 'Megjegyzések',
  Reminders: 'Emlékeztetők',
  // Relations
  Relations: 'Kapcsolatok',
  'Add card relation': 'Kapcsolat hozzáadása a kártyához',
  'Remove relation': 'Kapcsolat eltávolítása',
  'Delete relation': 'Kapcsolat törlése',
  'Edit relation values': 'Kapcsolati értékek szerkesztése',
  'Add field': 'Mező hozzáadása',
  History: 'Előzmények',

  Activities: 'Tevékenységek',
  Audit: 'Módosítások előzményei',
  'No Results Found': 'Nincs találat',
  'No activities for this record': 'Ehhez a rekordhoz nincsenek tevékenységek',
  'Relation removed successfully': 'A kapcsolat sikeresen eltávolítva',
  'Relation added successfully': 'A kapcsolat sikeresen hozzáadva',
  from: 'Feladó',
  to: 'Címzett',
  Reminder: 'Emlékeztető',
  Task: 'Feladat',
  LOW: 'Alacsony',
  MEDIUM: 'Közepes',
  HIGH: 'Magas',
  Status: 'Állapot',
  PENDING: 'Függőben',
  DONE: 'Kész',
  'Meeting Details': 'Találkozó részletei',
  'Task Details': 'Feladat részletei',
  Tasks: 'Feladatok',
  Meetings: 'Találkozók',
  Events: 'Események',
  'Meeting & Tasks': 'Találkozók és feladatok',
  Upcoming: 'Közelgő',
  Completed: 'Teljesítve',
  'Edit event': 'Esemény szerkesztése',
  Add: 'Hozzáadás',
  All: 'Összes',
  Cancel: 'Mégse',
  Delete: 'Törlés',
  Name: 'Név',
  Info: 'Információ',
  'Main View': 'Fő nézet',
  'Relation delete': 'Kapcsolat törlése',
  'Are you sure you want to delete this relation? the original card will not be deleted, but the relation and its values will be removed.':
    'Biztosan törölni szeretnéd ezt a kapcsolatot? Az eredeti kártya nem kerül törlésre, de a kapcsolat és annak értékei eltávolításra kerülnek.',
  'Duplicate card': 'Kártya másolása',
  'Adjust {{cardTypeName}} layout': '{{cardTypeName}} elrendezésének módosítása',
  'Edit {{cardTypeName}} fields': '{{cardTypeName}} mezőinek szerkesztése',
  'No Activities Yet': 'Még nincsenek tevékenységek',
  "It looks like you haven't created any activities yet. Start by adding your first activity to track progress and stay organized!":
    'Úgy tűnik, még nem hoztál létre tevékenységeket. Kezdd az első tevékenység hozzáadásával a haladás nyomon követését és az áttekinthetőséget!',
  'No Audit Records Found': 'Nincsenek ellenőrzési rekordok',
  'There are no audit records to display': 'Nincsenek ellenőrzési rekordok megjelenítésre',
};

import { useAuth } from '@clerk/nextjs';
import { Button, Divider, Drawer, Flex, Group, NavLink, ScrollArea, Text } from '@mantine/core';
import NextLink from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganizations } from '~/ui/hooks/organizations.hook';
import { useAppRouter, useQueryParams } from '../../../ui/hooks/app.router.hook';
import { useMenuStore } from '../Sidebar/menu.store';
import { type IMenuItem } from '../Sidebar/types';

type AppNavLinkProps = IMenuItem & {
  onClose?: () => void;
  ParentIcon?: React.FC;
};

const AppNavLink: React.FC<AppNavLinkProps> = ({ onClose, ParentIcon, disabled, ...item }) => {
  const pathname = usePathname();
  const {
    params: { view },
  } = useQueryParams<{ view?: string }>() ?? {};

  const Icon = item?.Icon ?? ParentIcon;
  const selected = item.path.includes(view ?? pathname);
  const disableItem = disabled && disabled.length > 0;

  return (
    <NavLink
      component={NextLink}
      href={item.path}
      label={
        <Group>
          {Icon && <Icon />}
          {item.title}
        </Group>
      }
      disabled={disableItem}
      active={selected}
      onClick={onClose}
      childrenOffset={28}
    />
  );
};

const MenuItems: React.FC<{ list: IMenuItem[]; onClose: () => void }> = ({ list, onClose }) => {
  const { selectedMenu, selectedSubMenu } = useMenuStore((state) => ({
    selectedMenu: state.selectedMenu,
    selectedSubMenu: state.selectedSubMenu,
  }));

  return list.map((navLink) => {
    const isSelected = selectedMenu?.id === navLink.id || selectedSubMenu?.id === navLink.id;
    if (navLink.hide ?? navLink.id === 'cards') return null;
    if (navLink?.children) {
      return (
        <NavLink
          key={navLink.id}
          component={NextLink}
          href={navLink.path}
          active={isSelected}
          label={
            <Group>
              {navLink?.Icon && <navLink.Icon />}
              {navLink.title}
            </Group>
          }
        >
          {navLink?.children?.map((item) => (
            <AppNavLink key={item.id} ParentIcon={navLink?.Icon} {...item} onClose={onClose} />
          ))}
        </NavLink>
      );
    }
    return <AppNavLink key={navLink.id} {...navLink} onClose={onClose} />;
  });
};

const OrganizationLabel: React.FC<{ name?: string }> = ({ name }) => {
  const { t } = useTranslation('headerComponent');

  return (
    <Group>
      <Text fz="xs">{t('Account')}</Text>
      <Text fw={'bold'} fz="sm">
        {name}
      </Text>
    </Group>
  );
};

const OrganizationMenu: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const {
    selectedOrganizationId,
    selectedOrganizationName,
    userMemberships,
    isLoading,
    selectOrganization,
  } = useOrganizations();

  const handleSelectOrganization = async (orgId: string) => {
    await selectOrganization(orgId);
    onClose();
  };

  if (isLoading) return null;

  if (userMemberships?.count === 1) {
    return <NavLink label={<OrganizationLabel name={selectedOrganizationName} />} />;
  }

  return (
    <NavLink label={<OrganizationLabel name={selectedOrganizationName} />}>
      {userMemberships.data?.map(({ organization: { id, name } }) => (
        <NavLink
          key={id}
          active={id === selectedOrganizationId}
          onClick={() => handleSelectOrganization(id)}
          label={name}
        />
      ))}
    </NavLink>
  );
};

export const HeaderDrawer: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const { isAppPage } = useAppRouter();
  const { t } = useTranslation('headerComponent');
  const { isSignedIn } = useAuth();
  const router = useRouter();

  const { menu, settingsMenu } = useMenuStore((state) => ({
    menu: state.menu,
    settingsMenu: state.settingsMenu,
  }));

  const menuList = useMemo(() => {
    return [...menu, ...settingsMenu];
  }, [menu, settingsMenu]);

  const cardsMenuItems = useMemo(() => {
    const cardsMenu = menu.find((item) => item.id === 'cards');
    if (!cardsMenu?.children) return [];

    return cardsMenu?.children?.map((item) => ({
      ...item,
      Icon: cardsMenu.Icon,
    }));
  }, [menu]);

  const handleLogin = () => {
    if (isSignedIn) {
      void router.push('/cards');
    } else {
      void router.push('/login');
    }
  };

  return (
    <Drawer
      opened={open}
      keepMounted={false}
      onClose={onClose}
      size="100%"
      padding="md"
      title="Next Zebra"
      zIndex={1000000}
    >
      <ScrollArea style={{ height: 'calc(100vh - 60px)' }} mx="-md">
        <Divider my="sm" />
        <Flex direction={'column'}>
          {isSignedIn && isAppPage && (
            <>
              <MenuItems list={cardsMenuItems} onClose={onClose} />
              <Divider my="sm" />
              <MenuItems list={menuList} onClose={onClose} />
            </>
          )}
        </Flex>
        <Divider my="sm" />
        <OrganizationMenu onClose={onClose} />

        <Divider my="sm" />
        <Group justify="center" grow pb="xl" px="md">
          {!isSignedIn && <Button onClick={handleLogin}>{t('Login')}</Button>}
        </Group>
      </ScrollArea>
    </Drawer>
  );
};

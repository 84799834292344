export const welcomePage = {
  Welcome: `Üdvözöljük`,
  'Setup your account': 'Állítsa be fiókját',
  'Account name': 'Fiók neve',
  'Organization name is required': 'A szervezet neve kötelező',
  'Organization name': 'Szervezet neve',
  'My Organization': 'Saját szervezet',
  'Finance documents': `Pénzügyi dokumentumok`,
  Template: `Sablon`,
  'Account created': `A fiók létrejött`,
  'Account "{{name}}" was created': `A(z) "{{name}}" fiók sikeresen létrejött`,
  'Create account': 'Hozzon létre egy fiókot',
  'Phone number': 'Telefonszám',
  Language: 'Nyelv',
  CRM: 'CRM',
  Sales: 'Értékesítés',
  Leads: 'Lehetőségek',
  Marketing: 'Marketing',
  Support: 'Támogatás',
  'Create new organization': 'Új szervezet létrehozása',
  Cancel: 'Mégse',
};

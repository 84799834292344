import { type TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc';
import { type ClientErrorCodes } from '~/server/services/errors/error-codes';

export const errorCodes: Record<ClientErrorCodes | TRPC_ERROR_CODE_KEY, string> = {
  MISSING_PERMISSION_DEFINITION: 'Nincs meghatározott jogosultságod ehhez a művelethez',
  MISSING_ROLE: 'Nincs hozzárendelt szerepköröd',
  NO_PERMISSION: 'Nincs jogosultságod a művelet végrehajtásához',
  CONTACT_ADMIN: 'Kérjük, lépj kapcsolatba a rendszergazdával',
  GENERAL_ERROR: 'Hiba történt',
  CONTACT_SUPPORT: 'Kérjük, lépj kapcsolatba az ügyfélszolgálattal',
  CARD_TYPE_DELETE_HAS_CARDS: 'Nem lehet törölni kártyatípust, amelynek már vannak kártyái',
  UNAUTHORIZED_NO_ORG: 'Nincs jogosultságod ehhez a művelethez',
  FINANCE_SETTINGS_NOT_FOUND:
    'A pénzügyi dokumentumok beállításai nem találhatók - menj a pénzügyi beállításokhoz és hozd létre azokat',
  SMS_DISABLED:
    'Az SMS szolgáltatás felfüggesztve az aktuális szervezeti tervhez - kérjük, lépj kapcsolatba az ügyfélszolgálattal',
  SMS_LIMIT_REACHED:
    'Elérted az SMS-korlátodat - kérjük, lépj kapcsolatba az ügyfélszolgálattal további vásárláshoz',

  // TRPC errors
  BAD_REQUEST: 'Érvénytelen kérés',
  CLIENT_CLOSED_REQUEST: 'A kérés lezárva az ügyfél által',
  CONFLICT: 'Már létezik',
  FORBIDDEN: 'Nincs jogosultságod a művelet végrehajtásához',
  INTERNAL_SERVER_ERROR: 'Szerverhiba történt',
  METHOD_NOT_SUPPORTED: 'Ez a művelet nem támogatott',
  NOT_FOUND: 'A keresett entitás nem található',
  NOT_IMPLEMENTED: 'Ez a művelet nem támogatott',
  PARSE_ERROR: 'Hiba az adatok feldolgozása során',
  PAYLOAD_TOO_LARGE: 'Túl nagy adatmennyiség',
  PRECONDITION_FAILED: 'Ez a művelet nem támogatott',
  TIMEOUT: 'Az idő lejárt',
  TOO_MANY_REQUESTS: 'Túl sok kérés',
  UNAUTHORIZED: 'Nincs jogosultságod a művelet végrehajtásához',
  UNPROCESSABLE_CONTENT: 'Érvénytelen tartalom',
  UNSUPPORTED_MEDIA_TYPE: 'Nem támogatott médiatípus',
  BAD_GATEWAY: 'Érvénytelen átjáró',
  GATEWAY_TIMEOUT: 'Az átjáró válaszadási ideje lejárt',
  SERVICE_UNAVAILABLE: 'A szolgáltatás nem elérhető',
};

import { NavLink, Stack, Text, Tooltip, rem } from '@mantine/core';
import NextLink from 'next/link';
import { useSearchParams } from 'next/navigation';
import { type CSSProperties } from 'react';
import { ConditionLink } from '~/Components/Shared/AppLink';
import { useLocale } from '~/ui/hooks/locale.hook';
import { MenuItemDescription } from './Description';
import classes from './SecondaryMenuBar.module.css';
import { MAIN_MAIN_WIDTH, SECONDARY_MENU_WIDTH, TOGGLE_MARGIN_TOP, useMenuStore } from './menu.store';

export const SecondaryMenuBar: React.FC = () => {
  const { selectedMenu, selectedSubMenu, open } = useMenuStore((state) => ({
    selectedMenu: state.selectedMenu,
    selectedSubMenu: state.selectedSubMenu,
    open: state.isSubMenuOpen,
  }));
  const searchParams = useSearchParams();
  const viewSlug = searchParams.get('view');
  const iconStyles: CSSProperties = { width: rem(14), height: rem(14) };
  const { align } = useLocale();
  if (!selectedMenu) return null;
  const { title, Icon: SelectedMenuIcon, children } = selectedMenu;
  return (
    <Stack
      h={'100%'}
      opacity={open ? 1 : 0}
      style={{ transition: 'opacity 1s, right 200ms' }}
      pos={'absolute'}
      w={SECONDARY_MENU_WIDTH}
      {...{ [align]: open ? MAIN_MAIN_WIDTH : -200 }}
    >
      <Stack px={0} gap={5} mt={TOGGLE_MARGIN_TOP}>
        <Text fw={500} mb="xs" px="sm">
          {title}
        </Text>
        {children?.map(({ id, path, title, Icon, desc, children: grandchildren, disabled }) => {
          const LinkIcon = Icon ?? SelectedMenuIcon ?? (() => null);
          if (grandchildren && grandchildren.length > 0) {
            return (
              <NavLink
                component="span"
                key={id}
                className={classes.navLink}
                px={8}
                py={5}
                style={{ textAlign: align }}
                label={title}
                variant="subtle"
                active={selectedSubMenu?.id === id}
                leftSection={<LinkIcon style={iconStyles} />}
                childrenOffset={10}
              >
                {grandchildren.map((grandchild) => (
                  <NextLink key={grandchild.id} href={grandchild.path} passHref>
                    <NavLink
                      component="span"
                      key={grandchild.id}
                      className={classes.navLink}
                      mb={5}
                      px={8}
                      py={5}
                      style={{ textAlign: align }}
                      label={<Text size="xs">{grandchild.title}</Text>}
                      active={viewSlug === grandchild.id}
                    />
                  </NextLink>
                ))}
              </NavLink>
            );
          }
          const disableItem = disabled && disabled.length > 0;

          return (
            <ConditionLink display={!disableItem} key={id} href={path} passHref>
              <Tooltip
                label={<MenuItemDescription title={title} desc={desc} disabled={disabled} />}
                position="right"
                withArrow
              >
                <NavLink
                  component="span"
                  disabled={disableItem}
                  title={disabled?.join('\n')}
                  className={classes.navLink}
                  px={8}
                  py={5}
                  style={{ textAlign: align }}
                  label={title}
                  active={selectedSubMenu?.id === id}
                  leftSection={<LinkIcon style={iconStyles} />}
                />
              </Tooltip>
            </ConditionLink>
          );
        })}
      </Stack>
    </Stack>
  );
};

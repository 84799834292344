import { financePage } from '../en/pages/finance';
import { common } from './common';
import { activitiesComponent } from './components/activities';
import { fieldsComponent } from './components/fields';
import { multiFiltersComponent } from './components/multi-filters';
import { navbarComponent } from './components/navbar';
import { errorCodes } from './errors-codes';
import { auditPage } from './pages/audit';
import { cardsMainPage } from './pages/cards-main';
import { settingsPage } from './pages/settings';

export const englishLocale = {
  // General
  common,
  errorCodes,

  // Pages
  cardsMainPage,
  settingsPage,
  financePage,
  auditPage,

  // Components
  navbarComponent,
  fieldsComponent,
  multiFiltersComponent,
  activitiesComponent,
};

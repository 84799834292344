export const welcomePage = {
  Welcome: `ברוך הבא`,
  'Setup your account': 'הגדר את החשבון שלך',
  'Account name': 'שם החשבון',
  'Organization name is required': 'שם הארגון נדרש',
  'Organization name': 'שם הארגון',
  'My Organization': 'הארגון שלי',
  'Finance documents': `מסמכים פיננסים`,
  Template: `תבנית`,
  'Account created': `החשבון נוצר`,
  'Account "{{name}}" was created': `החשבון "{{name}}" נוצר בהצלחה`,
  'Create account': 'צור חשבון',
  'Phone number': 'מספר טלפון',
  Language: 'שפה',
  CRM: 'CRM',
  Sales: 'מכירות',
  Leads: 'לידים',
  Marketing: 'שיווק',
  Support: 'תמיכה',
  'Create new organization': 'יצירת ארגון חדש',
  Cancel: 'ביטול',
};

import { useAuth } from '@clerk/nextjs';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { type Meeting, type Task } from '~/server/services/activity/types';
import { api } from '~/trpc';
import { useAppRouter } from '~/ui/hooks/app.router.hook';
import { getNextPageParam } from '~/ui/table/table.utils';

export const useActiveReminders = () => {
  const { isSignedIn, isLoaded, orgId } = useAuth();
  const { isMarketingPage } = useAppRouter();

  const nowMinusMinute = useMemo(() => {
    return dayjs().subtract(1, 'minute').startOf('minute').toDate();
  }, []);

  const next4Hours = useMemo(() => {
    return dayjs().add(4, 'hour').startOf('hour').toDate();
  }, []);

  const { data, isLoading } = api.activities.search.useInfiniteQuery(
    {
      limit: 100,
      filter: {
        type: { value: ['TASK', 'MEETING'], type: 'LIST' },
        reminder: { value: [nowMinusMinute, next4Hours], type: 'DATE' },
        done: { value: 'false', type: 'BOOLEAN' },
      },
      sort: [{ field: 'reminder', order: 'ASC' }],
    },
    {
      enabled: Boolean(isSignedIn) && isLoaded && !isMarketingPage && Boolean(orgId),
      getNextPageParam,
      gcTime: 5 * 60 * 60 * 1000,
      staleTime: 2 * 60 * 60 * 1000,
    },
  );
  const futureReminders = useMemo(() => {
    return (
      data?.pages.reduce<(Task | Meeting)[]>((res, curr) => {
        res.push(...(curr.results as (Task | Meeting)[]));
        return res;
      }, []) ?? []
    );
  }, [data?.pages]);

  return {
    futureReminders,
    isLoading,
  };
};

import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { useCallback } from 'react';
import { ConditionLink } from '~/Components/Shared/AppLink';
import { appColors } from '~/ui/app-colors';
import { MenuItemDescription } from './Description';
import { useMenuStore } from './menu.store';
import { type IMenuItem } from './types';

export const MenuItem: React.FC<IMenuItem & { selected: boolean }> = ({
  title,
  desc,
  Icon,
  selected,
  path,
  disabled,
  children,
}) => {
  const { setIsOpen, isOpen } = useMenuStore((state) => ({
    setIsOpen: state.setIsSubMenuOpen,
    isOpen: state.isSubMenuOpen,
  }));

  const handleToggle = useCallback(() => {
    if (!children) return;
    if (selected) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(true);
    }
  }, [children, selected, isOpen]);

  const disableItem = disabled && disabled.length > 0;

  return (
    <ConditionLink href={path} display={!selected} passHref>
      <Tooltip
        label={<MenuItemDescription title={title} desc={desc} disabled={disabled} />}
        position="right"
        withArrow
      >
        <Flex direction="column" align="center" justify="center" pb={'3px'}>
          <ActionIcon
            size="lg"
            radius="xl"
            disabled={disableItem}
            variant={selected ? 'light' : 'subtle'}
            color={selected ? appColors.themeColor : 'gray'}
            onClick={handleToggle}
          >
            {Icon && <Icon />}
          </ActionIcon>
        </Flex>
      </Tooltip>
    </ConditionLink>
  );
};

export const financePage = {
  'Create At': 'Létrehozva',
  Client: 'Ügyfél',
  'Serial Number': 'Sorszám',
  'Total Before Vat': 'Összesen ÁFA nélkül',
  Vat: 'ÁFA',
  'Total Including Vat': 'Összesen ÁFÁ-val',
  'Created By': 'Létrehozta',
  Balance: 'Egyenleg',
  'Document Type': 'Dokumentumtípus',
  'New Document': 'Új dokumentum',
  '{{type}} created': '{{type}} létrehozva',
  '{{type}} updated': '{{type}} frissítve',
  '{{type}} created successfully': '{{type}} sikeresen létrehozva',
  '{{type}} updated successfully': '{{type}} sikeresen frissítve',
  'Create {{type}}': 'Új {{type}} létrehozása',
  Create: 'Létrehozás',
  'Edit {{type}}': '{{type}} szerkesztése',
  'Edit draft': 'Piszkozat szerkesztése',
  Error: 'Hiba',
  Actions: 'Műveletek',
  'Create related document': 'Kapcsolódó dokumentum létrehozása',
  'General Settings': 'Általános beállítások',
  'Scroll to actions': 'Görgess le a műveletekhez vagy kattints ide',
  'Casual customer': 'Alkalmi ügyfél',
  Status: 'Állapot',
  'Invoices / receipts': 'Számlák / nyugták',
  'Open tax invoices': 'Nyitott adószámlák',
  'Casual customers': 'Alkalmi ügyfelek',
  'Clone document': 'Dokumentum klónozása',

  // Document Types
  TAX_INVOICE: 'Adószámla',
  RECEIPT: 'Nyugta',
  INVOICE_RECEIPT: 'Adószámla és nyugta',
  CREDIT_INVOICE: 'Helyesbítő számla',
  PROFORMA_INVOICE: 'Proforma számla',
  ORDER_CONFIRMATION: 'Rendelés visszaigazolás',
  PRICE_OFFER: 'Árajánlat',
  SHIPPING_CERTIFICATE: 'Szállítólevél',
  DEPOSIT: 'Előleg',
  RETAINER: 'Előfizetés',
  INVOICE_FOR_RECEIPT: 'Számla nyugtához',
  DRAFTS: 'Piszkozatok',
  DONATION: 'Adomány',

  // Document Status
  DRAFT: 'Piszkozat',
  PENDING: 'Függőben',
  APPROVED: 'Jóváhagyva',
  REJECTED: 'Elutasítva',
  CANCELED: 'Törölve',

  // Type of related card
  EXISTING: 'Meglévő ügyfél',
  CASUAL: 'Alkalmi ügyfél',
  NEW: 'Új ügyfél',

  // Form
  'For {{type}} number {{serialNumber}}': '{{type}} számú {{serialNumber}} részére',
  'Clone of {{type}} number {{serialNumber}}': '{{type}} számú {{serialNumber}} klónja',
  'Select a card': 'Válassz egy kártyát',
  'Select the card to which you want to associate this {{type}}':
    'Válassz egy kártyát, amelyhez ezt a(z) {{type}}-t társítod',
  'Create document': 'Dokumentum létrehozása',
  'Edit document': 'Dokumentum szerkesztése',
  Preview: 'Előnézet',
  'Save as draft': 'Mentés piszkozatként',
  'Update draft': 'Piszkozat frissítése',
  Draft: 'Piszkozat',
  Cancel: 'Mégse',
  "Your customer's name": 'Ügyfél neve',
  Customer: 'Ügyfél',
  'Customer name': 'Ügyfél neve',
  'Customer ID / Business ID': 'Ügyfélazonosító / Adószám',
  'Main email': 'Elsődleges e-mail',
  'Email addresses': 'E-mail címek',
  'You can add multiple email addresses': 'Több e-mail cím is hozzáadható',
  Date: 'Dátum',
  'The date can be selected from the day the last document of this type was produced until today':
    'A dátum kiválasztható az utolsó dokumentum elkészítésének napjától máig',
  Currency: 'Pénznem',
  'Document language': 'Dokumentum nyelve',
  'The type of currency in which the document will be issued': 'A dokumentum kiállításának pénzneme',
  'The language in which the document will be produced': 'A dokumentum nyelve',
  'Document title': 'Dokumentum címe',
  Notes: 'Jegyzetek',
  'Related documents': 'Kapcsolódó dokumentumok',

  // Form Title
  'Documents creation': 'Dokumentum létrehozása',
  'Number {{serialNumber}}': 'Szám: {{serialNumber}}',
  'Document status: {{status}}': 'Dokumentum állapota: {{status}}',
  View: 'Megtekintés',

  // Products
  VAT: 'ÁFA',
  SKU: 'Cikkszám',
  Description: 'Leírás',
  Quantity: 'Mennyiség',
  Price: 'Ár ÁFA nélkül',
  'Price incl. VAT': 'Ár ÁFÁ-val',
  'Total price without VAT': 'Teljes ár ÁFA nélkül',
  TAXABLE: 'Adóköteles',
  EXEMPTION: 'Adómentes',
  'Add product': 'Termék hozzáadása',
  Discount: 'Kedvezmény',
  'Round total': 'Kerekítés',
  'Total VAT liable': 'Teljes ÁFA-köteles összeg',
  'Rounded amount': 'Kerekített összeg',
  'Total VAT': 'Teljes ÁFA',
  'Before VAT': 'ÁFA előtt',
  'Total VAT liable after discount': 'Teljes ÁFA-köteles összeg kedvezmény után',
  'Total incl. VAT': 'Teljes összeg ÁFÁ-val',
  'Total Due': 'Fizetendő összeg',
  'SKU is required': 'A cikkszám kötelező',
  'Description is required': 'A leírás kötelező',
  'Quantity is required': 'A mennyiség kötelező',
  'Create products catalog': 'Termékkatalógus létrehozása',
  'By creating a product catalog, you can store your products and easily include them in your financial documents':
    'Termékkatalógus létrehozásával tárolhatod termékeidet, és könnyen hozzáadhatod őket pénzügyi dokumentumaidhoz',

  // Preview
  from: 'Feladó',
  id: 'Azonosító',
  Address: 'Cím',
  Phone: 'Telefon',
  Email: 'E-mail',
  'Email Address': 'E-mail cím',
  For: 'Címzett',
  'Id / Business Number': 'Azonosító / Adószám',
  notes: 'Jegyzetek',
  Signature: 'Aláírás',
  'View document': 'Dokumentum megtekintése',
  Download: 'Letöltés',
  'Download document': 'Dokumentum letöltése',
  'Download copy of document': 'Dokumentummásolat letöltése',
  'Back to documents': 'Vissza a dokumentumokhoz',
  ORIGINAL: 'Eredeti',
  COPY: 'Másolat',
  PREVIEW: 'Előnézet',

  // Signature
  'This document is digitally signed': 'Ez a dokumentum digitálisan aláírt',
  'This document has been generated by icom software inc.':
    'Ez a dokumentum az icom software inc. által lett generálva',
  // Email
  'Finance document - {{type}} - {{serialNumber}}': 'Pénzügyi dokumentum - {{type}} - {{serialNumber}}',
  'Hi {{customerName}}': 'Tisztelt {{customerName}}',
  'You have received a {{type}} - {{serialNumber}}':
    'Kapott egy {{type}} - {{serialNumber}} dokumentumot',
  'You can view the document by clicking the button below':
    'A dokumentumot az alábbi gombra kattintva tekintheti meg',
  'Document - {{type}} {{serialNumber}} for {{customerName}} from {{businessName}}':
    'Dokumentum - {{type}} {{serialNumber}} a(z) {{customerName}} részére a(z) {{businessName}} cégtől',

  Name: 'Név',
  Type: 'Típus',
  Amount: 'Összeg',
  Products: 'Termékek',
  Payments: 'Fizetések',

  CASH: 'Készpénz',
  CHECK: 'Csekk',
  CREDIT_CARD: 'Hitelkártya',
  BANK_TRANSFER: 'Banki átutalás',
  WITHHOLDING_TAX: 'Adólevonás',
  OTHER: 'Egyéb',

  // Payments
  'Payment Method': 'Fizetési mód',
  Bank: 'Bank',
  Branch: 'Fiók',
  'Account Number': 'Számlaszám',
  'Check Id': 'Csekk azonosító',
  'Transaction Id': 'Tranzakció azonosító',
  Sum: 'Összeg',
  Total: 'Összesen',
  'Payment Type': 'Fizetési típus',
  'Credit Card Id': 'Hitelkártya azonosító',
  'Credit Card Type': 'Hitelkártya típusa',
  'Add payment': 'Fizetés hozzáadása',
  'Payment Details': 'Fizetési adatok',

  'Total cash': 'Összes készpénz',
  'Total checks': 'Összes csekk',
  'Total credit card': 'Összes hitelkártya',
  'Total bank transfer': 'Összes banki átutalás',
  'Total other': 'Összes egyéb',
  'Total with holding tax': 'Összes adólevonás',
  'Total paid': 'Összes fizetve',

  // Credit Card
  UNKNOWN: 'Ismeretlen',
  MASTER_CARD: 'MasterCard',
  DINERS: 'Diners',
  ISRACARD: 'Isracard',
  AMERICAN_EXPRESS: 'American Express',
  VISA: 'Visa',

  // settings
  Organization: 'Szervezet',
  'Organization Name': 'Szervezet neve',
  'Business Number': 'Üzleti azonosító',
  'Business Type': 'Üzleti típus',
  Language: 'Nyelv',
  'Default VAT': 'Alapértelmezett ÁFA',
  'Default Currency': 'Alapértelmezett pénznem',
  'VAT Percentage': 'ÁFA százalék',
  'Theme Color': 'Témaszín',
  'Last doc: {{max}}': 'Utolsó dokumentum: {{max}}',
  Save: 'Mentés',
  'Serial Numbers': 'Sorszámok',
  Design: 'Dizájn',
  'Failed to save settings': 'Beállítások mentése sikertelen',
  Settings: 'Beállítások',
  'Missing settings': 'Hiányzó beállítások',
  'You need to set up your finance settings before you can use this feature':
    'Be kell állítanod a pénzügyi beállításokat, mielőtt használhatnád ezt a funkciót',
  'Create & Update settings': 'Beállítások létrehozása és frissítése',

  'Settings updated': 'Beállítások frissítve',
  'Finance settings updated successfully': 'Pénzügyi beállítások sikeresen frissítve',
  'Once a document is created, you cannot change the starting serial number':
    'Ha egy dokumentum már létrehozásra került, nem módosítható az induló sorszám',

  // Business types
  SOLE_PROPRIETORSHIP: 'Egyéni vállalkozó',
  AUTHORIZED_DEALER: 'Engedélyezett kereskedő',
  LIMITED_COMPANY: 'Korlátolt felelősségű társaság',
  NON_PROFIT_ORGANIZATION: 'Nonprofit szervezet',

  // Dashboard
  'Main overview': 'Fő áttekintés',
  'This month': 'Ez a hónap',
  'This year': 'Ez az év',
  'Cash Balance': 'Készpénzegyenleg',
  'Pending Payments': 'Függő fizetések',
  'Documents Count': 'Dokumentumok száma',
  'Total Revenue': 'Teljes bevétel',
  'Total Before VAT': 'Bevétel ÁFA nélkül',
  'Total Paid': 'Befizetett összeg',
  'Documents types': 'Dokumentumtípusok',
  'Money flow': 'Pénzáramlás',
  'vs Last month': 'az előző hónaphoz képest',
  'vs Last year': 'az előző évhez képest',
  'No data available': 'Nincsenek elérhető adatok',
};

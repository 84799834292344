export const homePage = {
  'Get your business under control': 'קח את העסק שלך תחת שליטה מלאה',
  'Rapidly tweak, test and roll-out your customer relations with Next Zebra':
    'פשוט לניהול, קל ליישום ועם אינסוף אפשרויות ',
  'Upgrade your sales strategy today with our turn-key crm solution!':
    'התקדם למערכת ניהול לקוחות שתיקח את העסק הרבה צעדים קדימה!',
  'Open free trial account': 'פתח מערכת חינם',
  'Try now! it’s free': 'נסה עכשיו! זה חינם',
  'Credit card is not required': 'ללא צורך בכרטיס אשראי',

  'Terms and Conditions': 'תנאי שימוש',
  'Privacy Policy': 'מדיניות פרטיות',

  'Stay up-to-date on all things Zebra and gain additional access to our team. No spam. We’re not that kind of company.':
    'הישארו מעודכנים בכל הנוגע ל-Next Zebra ויהיו בקשר עם הצוות. אנחנו לא שולחים ספאם - אנחנו לא חברה כזאת',

  // New home page
  'Next Zebra CRM': 'Next Zebra CRM',
  'Exceptional Customer Experiences': 'חוויות לקוח יוצאות דופן',
  'The next generation of CRM': 'הדור הבא של מערכות ה- CRM',

  'Next Zebra CRM unifies your sales, marketing, and customer service teams onto one platform, empowering you to deliver unforgettable customer experiences at every touchpoint.':
    'Next Zebra CRM מאחדת את צוותי המכירות, השיווק ושירות הלקוחות בפלטפורמה אחת, ומאפשרת לך לתת ללקוח חווית שירות מקסימלית בכל נקודת מגע.',
  'Streamline your business operations, optimize customer engagements, and boost ROI—all while prioritizing the needs of your customers at the center of every interaction.':
    'שדרגו את תהליכי העסק שלכם, חזקו את קשרי הלקוח, והעלו את ההחזר על ההשקעה - כל זאת לצד טרגוט צרכי הלקוח בכל אינטרקציה.',

  // Features
  'Discover the power of Next Zebra CRM to elevate your sales strategy, enhance customer relationships, and drive sustainable growth for your business.':
    'גלה את עוצמת Next Zebra CRM להעלאת אסטרטגיית המכירות שלך, שיפור הקשר עם הלקוחות, והובלת צמיחה עסקית.',
  'To learn more about how we prioritize data privacy and security, please review our Privacy Policy':
    'כדי ללמוד עוד על איך אנחנו מעניקים עדיפות לפרטיות ולאבטחת הנתונים, אנא בדקו את מדיניות הפרטיות שלנו',

  'Our core features': 'הפיצ׳רים שלנו',
  'Easy to Fit Your Needs': 'מותאם בדיוק לצרכים שלך',
  'Effortlessly organize your system to suit your business needs, including managing clients, contacts, projects, and more. Easily generate and filter reports for comprehensive insights.':
    'ארגנו את המערכת שלכם בקלות כך שתתאים בדיוק לכם, כולל ניהול לקוחות, אנשי קשר, פרויקטים ועוד. יצרו וסננו דוחות בקלות לקבלת תובנות עסקיות.',
  Automations: 'אוטומציות',
  'Streamline your workflows and save time with automated processes.':
    'פשט את תהליכי העבודה שלך וחסוך זמן עם תהליכים אוטומטיים.',
  'Task Management': 'ניהול משימות',
  'Efficiently manage tasks related to clients and projects etc., ensuring nothing falls through the cracks. Stay organized and on top of your workload with intuitive task management tools.':
    'נהל ביעילות משימות הקשורות ללקוחות, לפרויקטים וכו׳, תוך הקפדה על כך ששום דבר לא ייפול בין הכיסאות. הישאר מאורגן והקל על עומס העבודה שלך עם כלי ניהול משימות אינטואיטיבי.',
  'User-Friendly Interface': 'ממשק ידידותי למשתמש',
  'Enjoy a simple and intuitive user interface that makes navigation and operation a breeze. Whether on desktop or mobile, our responsive UI ensures a seamless experience for all users.':
    'ממשק משתמש פשוט ואינטואיטיבי שהופך את הניווט והתפעול לפשוט. בין אם במחשב או בנייד, ממשק המשתמש הרספונסיבי שלנו מבטיח חוויה חלקה לכל המשתמשים.',
  'Customizable Reports': 'דוחות מותאמים אישית',
  'Generate and customize reports to gain insights into your business operations. Filter data to focus on specific metrics and make data-driven decisions for your business.':
    'צור והתאם אישית דוחות כדי לקבל תובנות לגבי הפעילות העסקית שלך. סנן נתונים כדי להתמקד במדדים ספציפיים ולקבל החלטות מונחות נתונים עבור העסק שלך',
  'Real-Time Analytics': 'ניתוחים בזמן אמת',
  'Monitor your business performance with real-time analytics. Track key metrics, identify trends, and make informed decisions to optimize your business operations.':
    'עקוב אחר הביצועים העסקיים שלך עם ניתוח בזמן אמת. עקוב אחר מדדי מפתח, זהה מגמות וקבל החלטות מושכלות כדי לייעל את הפעילות העסקית שלך',
  'Data Security': 'אבטחת מידע',
  'Protect your data with advanced security features. Ensure that your business information is safe and secure with Next Zebra CRM.':
    'הגן על הנתונים שלך עם תכונות אבטחה מתקדמות. ודא שהמידע העסקי שלך בטוח ומאובטח עם Next Zebra CRM',
  'Storage & File Management': 'אחסון וניהול קבצים',
  'Store and manage files related to your clients, projects, and business operations. Access files from anywhere, anytime, and share them with your team.':
    'אחסן ונהל קבצים הקשורים ללקוחותיך, לפרויקטים ולפעילות העסקית שלך. גש לקבצים מכל מקום, בכל זמן, ושתף אותם עם הצוות שלך.',
  'Emails, Messages and more': 'אימיילים, הודעות ועוד',
  'Send emails, messages and more directly from Next Zebra CRM, or just record them. Communicate with your clients.':
    'שלח אימיילים, הודעות ועוד ישירות מ-Next Zebra CRM, או רשום אותם. תקשורת עם הלקוחות שלך.',
  'AI-powered assistant': 'עוזר מופעל ב-AI',
  'Automate customer service with an AI-powered assistant. Answer frequently asked questions based on your customer interactions and more.':
    'אוטומציה של שירות לקוחות מבוסס AI. ענה על שאלות נפוצות בהתאם לאינטראקציות של הלקוח ועוד.',
  'Finance Documents': 'מסמכים חשבונאיים',
  "Manage your organization's financial documents, as well as maintain a product catalog. This way, you can create invoices, receipts, and other financial documents according to your needs.": `נהלו את המסמכים החשבונאיים של הארגון בקלות וביעילות, ע"י ניהול קטלוג מוצרים מסודר. צרו חשבוניות, קבלות ומסמכים נוספים בהתאמה אישית והבטיחו ניהול כספים חכם, יעיל ומקצועי.`,

  // Security
  'Security and Compliance': 'אבטחת מידע',
  'Our platform ensures the highest standards of security and compliance, empowering your business to grow confidently while protecting customer data and meeting industry regulations.':
    'הפלטפורמה שלנו מבטיחה את סטנדרטי האבטחה וההתאמה הגבוהים ביותר, מאפשרת לעסק שלך לצמוח בביטחה ולהגן על נתוני הלקוחות ולעמוד בתקנות התעשייה.',
  'SOC 2 Type 2 Compliance': 'התאמה ל-SOC 2 Type 2',
  'Our services adhere to SOC 2 Type 2 compliance, covering security, availability, and confidentiality. This framework ensures your data remains secure and private, with regular audits confirming our commitment to these standards.':
    'השירותים שלנו עומדים בהתאמה ל-SOC 2 Type 2, המכסה אבטחה, זמינות וסודיות. המתכון הזה מבטיח שהנתונים שלך יישארו בטוחים ופרטיים, עם בדיקות רגילות המאשרות את המחויבות שלנו לתקנים אלה.',
  'ISO 27001 Certification': 'תעודת ISO 27001',
  'Our platform is certified under ISO 27001:2013, an internationally recognized standard for information security management. This ensures we have the necessary processes in place to protect your data from unauthorized access and vulnerabilities.':
    'הפלטפורמה שלנו מוסמכת בהתאמה ל-ISO 27001:2013, תקן בינלאומי מוכר לניהול אבטחת מידע. זה מבטיח שיש לנו את התהליכים הנחוצים להגנה על הנתונים שלך מפני גישה לא מורשית ופגיעות.',
  'Protection Against Data Breaches': 'הגנה מפני פריצות למידע',
  'We implement robust data encryption, both in transit and at rest, to protect against potential breaches. Additionally, we maintain GDPR compliance, ensuring any personal data is handled with the utmost care and transparency.':
    'אנו מיישמים הצפנת נתונים חזקה, הן במהלך ההעברה והן במנוחה, כדי להגן מפני פריצות אפשריות. בנוסף, אנו שומרים על עמיתות GDPR, ומבטיחים שכל נתון אישי ייטופל בזהירות ובשקיפות מרבית.',
  'Secure User Authentication': 'אימות משתמש מאובטח',
  'Clerk’s authentication system ensures that user data is securely handled with multi-factor authentication (MFA) and encryption. This guarantees that only authorized users have access to sensitive information, safeguarding against unauthorized access.':
    'מערכת האימות של Clerk מבטיחה שנתוני המשתמש ייטופלו באופן מאובטח עם אימות מרובה גורמים (MFA) והצפנה. זה מבטיח שרק משתמשים מורשים יכולים לגשת למידע רגיש, ומגן מפני גישה לא מורשית.',
  'PCI DSS Compliance': 'התאמה ל-PCI DSS',
  'For customers handling payment data, our infrastructure supports PCI DSS compliance, ensuring that all credit card transactions are securely processed according to the latest security standards.':
    'ללקוחות המטפלים בנתוני תשלום, התשתית שלנו תומכת בהתאמה ל-PCI DSS, ומבטיחה שכל העסקאות בכרטיסי אשראי יעובדו באופן מאובטח על פי סטנדרטים אבטחה מתקדמים.',
  'Read more': 'קרא עוד',
  'Database Security': 'אבטחת מסד נתונים',
  "Our infrastructure is hosted in Amazon's secure data centers, backed by AWS Cloud Security. Data-at-rest is encrypted using XTS-AES-256 on NVMe SSD volumes, ensuring compliance with industry standards and protecting against unauthorized access.": `התשתית שלנו מאוחסנת במרכזי נתונים מאובטחים של אמזון, תוך תמיכה באבטחת ענן של AWS. הנתונים במנוחה מוצפנים באמצעות XTS-AES-256 על כרטיסי NVMe SSD, מבטיחים עמיתות עם תקנים תעשייתיים ומגנים מפני גישה לא מורשית.`,
  // Description
  'CRM system for leads management, customer relations, accounting, marketing automation and more':
    'מערכת CRM לניהול לידים, קשרי לקוחות, הנהלת חשבונות, מרקטינג אוטומיישן ועוד',
  'Next Zebra is a powerful CRM solution designed to help businesses of all sizes effectively manage customers, streamline sales processes, and enhance customer service efficiency':
    'זברה נקסט היא מערכת CRM עוצמתית שמסייעת לעסקים בכל הגדלים לנהל לקוחות בצורה יעילה, לייעל תהליכי מכירות ולשפר את היעילות של שירות הלקוחות',

  // Get in touch
  'Get in touch': 'צרו קשר',
  Name: 'שם',
  'Your Name': 'השם שלך',
  Company: 'חברה',
  Phone: 'טלפון',
  'Your Phone': 'הטלפון שלך',
  'Your Company': 'שם חברה',
  Email: 'אימייל',
  'Your Email': 'האימייל שלך',
  Message: 'הודעה',
  'Your Message': 'ההודעה שלך',
  "Still not convinced to try our app? No worries! Reach out to us, and one of our team members will get in touch with you shortly. We're here to answer any questions and provide you with all the information you need": `עדיין לא השתכנעת לנסות את האפליקציה שלנו? אל תדאג! צרו איתנו קשר, ואחד מחברי הצוות שלנו ייצור איתך קשר בקרוב. אנחנו כאן כדי לענות על כל השאלות ולספק לך את כל המידע שאתה צריך`,
  'Hit Us Up': 'צרו איתנו קשר',

  'All good': 'הכל בסדר',
  'We got your message and will get back to you soon': 'קיבלנו את ההודעה שלך ונחזור אליך בקרוב',
  'Something went wrong': 'משהו השתבש',
  'Failed to send message, please try again later': 'נכשל בשליחת ההודעה, אנא נסה שוב מאוחר יותר',
};

import { type DefaultMantineColor } from '@mantine/core';

export const colorsComponent: Record<DefaultMantineColor, string> = {
  blue: 'Kék',
  cyan: 'Türkiz',
  gray: 'Szürke',
  dark: 'Sötét',
  grape: 'Szőlő',
  green: 'Zöld',
  indigo: 'Indigókék',
  lime: 'Lime',
  orange: 'Narancs',
  pink: 'Rózsaszín',
  red: 'Piros',
  teal: 'Pávakék',
  violet: 'Ibolya',
  yellow: 'Sárga',
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import { i18Config } from './i18n.config';

export const i18nInstance = i18n.use(initReactI18next);

i18nInstance
  .init(i18Config)
  .then(() => {
    z.setErrorMap(zodI18nMap);
  })
  .catch((err) => {
    console.error(err);
  });

import { Title } from '@mantine/core';
import NextLink from 'next/link';
import { appFonts } from '~/ui/fonts';

export const MarketingLogo: React.FC = () => (
  <NextLink href={'/'} style={{ textDecoration: 'none' }}>
    <Title order={2} fw={500} ff={appFonts.playfair.style.fontFamily}>
      Next Zebra
    </Title>
  </NextLink>
);

import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { hasPermission, usePermission } from '~/Components/Settings/RolesPermissions/permissions.hook';
import { useAppRouter } from '~/ui/hooks/app.router.hook';
import { useFeatureFlags } from '~/ui/hooks/feature-flag.hook';
import { useImmediateEffect } from '~/ui/hooks/immediate-effect.hook';
import { useSettings } from '~/ui/hooks/settings.hook';
import { type CardType } from '../../../../db/types';
import { useCardTypes } from '../../Cards/card-types.hook';
import { getMainMenuItems, getSettingsMenuItems } from './main-menu.items';
import { useMenuStore } from './menu.store';
import { type IMenuItem } from './types';

type CardTypeWithTableViews = CardType & {
  cardsTableViews: { id: string; title: string; slug: string }[];
};

export const useActiveMenuItem = () => {
  const { menu, settingsMenu } = useMenuStore((state) => state);

  const getItemTitle = useCallback(
    (path: string) => {
      const allMenu = [...menu, ...settingsMenu];
      const mainMenu = allMenu.find((item) => path.includes(item.path));
      const subMenu = mainMenu?.children?.find((item) => path.includes(item.path));
      let title = '';
      if (mainMenu?.title) {
        title += mainMenu.title;
        if (subMenu?.title) title += ` - ${subMenu.title}`;
      } else if (subMenu?.title) {
        title += subMenu.title;
      }

      return title;
    },
    [menu],
  );
  return {
    getItemTitle,
  };
};

export const useMenu = () => {
  const pathname = usePathname();
  const { t } = useTranslation('navbarComponent');
  const { t: tSettings } = useTranslation('settingsPage');

  const { isMarketingPage } = useAppRouter();
  const { isAdminConsole, plan: orgPlan, userRole } = useSettings();
  const { menu, setSelectedMenu, setSelectedSubMenu, setMenu, setSettingsMenu } = useMenuStore(
    (state) => state,
  );
  const { cardTypes, isLoadingCardTypes } = useCardTypes();
  const mainMenuItems = useMemo(() => getMainMenuItems(t), [t]);
  const settingsMenuItems = useMemo(() => getSettingsMenuItems(t, isAdminConsole), [t, isAdminConsole]);
  const featureFlags = useFeatureFlags();
  usePermission;
  useEffect(() => {
    setSettingsMenu(settingsMenuItems);
  }, [settingsMenuItems]);

  const addGuards = useCallback(
    (item: IMenuItem): IMenuItem => {
      const { featureFlag, permission, plan, hide } = item;
      const shouldHide = hide && featureFlag ? !featureFlags.includes(featureFlag) : hide;
      item.disabled = [];
      if (orgPlan?.settings && plan?.(orgPlan.settings) === false) {
        item.disabled = [
          tSettings('Content is not a part of the plan'),
          tSettings('Please contact support to upgrade your plan'),
        ];
      }
      if (permission && userRole) {
        const [type, action] = permission;
        if (!hasPermission(userRole, type, action)) {
          item.disabled = [
            tSettings('You do not have permission to access this page'),
            tSettings('Please contact your organization administrator'),
          ];
        }
      }
      return { ...item, hide: shouldHide, children: item.children?.map(addGuards) };
    },
    [orgPlan, userRole, tSettings, featureFlags],
  );

  useImmediateEffect(() => {
    setMenu(mainMenuItems);
    setSettingsMenu(settingsMenuItems);
  });

  useEffect(() => {
    if (isLoadingCardTypes || isMarketingPage || !cardTypes) return;

    const updatedMenu = addDynamicCardsTypes(mainMenuItems, cardTypes).map(addGuards);
    const allMenus = [...updatedMenu, ...settingsMenuItems];
    setMenu(updatedMenu.map((item) => addGuards(item)));
    setSettingsMenu(settingsMenuItems.map((item) => addGuards(item)));

    const selectedMenu = allMenus.find(({ path }) => pathname?.includes(path)) ?? menu[0];
    if (!selectedMenu) return;
    setSelectedMenu(selectedMenu);
    setSelectedSubMenu();

    if (selectedMenu.children) {
      const selectedSubMenu = selectedMenu.children?.find(({ path }) => pathname?.includes(path));
      if (selectedSubMenu) {
        setSelectedSubMenu(selectedSubMenu);
      }
    }
  }, [
    pathname,
    cardTypes,
    userRole,
    isLoadingCardTypes,
    isMarketingPage,
    mainMenuItems,
    settingsMenuItems,
    orgPlan,
    userRole,
    featureFlags,
  ]);
};

const addDynamicCardsTypes = (
  mainItems: IMenuItem[],
  dynamicCards: CardTypeWithTableViews[],
): IMenuItem[] => {
  const updatedSubList = dynamicCards.map(({ slug, pluralName, cardsTableViews }) => {
    return {
      title: pluralName,
      path: `/cards/${slug}`,
      id: slug,
      children: cardsTableViews?.map(({ slug: viewSlug, title }) => ({
        title,
        path: `/cards/${slug}?view=${viewSlug}`,
        id: viewSlug,
      })),
    };
  });
  const cardsMenu = mainItems.find(({ id }) => id === 'cards');
  if (cardsMenu && updatedSubList.length > 0) {
    cardsMenu.children = updatedSubList;
  }
  return mainItems;
};

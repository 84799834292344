export const meetingReminderSms = {
  'Hi {{name}}, This is a reminder from {{from}} for the {{title}} at {{time}} at {{location}}.':
    'Szia {{name}}, Ez egy emlékeztető a(z) {{from}} által a(z) {{title}} találkozóra, amely {{time}}-kor lesz a(z) {{location}} helyszínen.',
  'Hi {{name}}, a meeting {{title}} with {{from}} scheduled for {{time}} at {{location}}.':
    'Szia {{name}}, A(z) {{title}} találkozó a(z) {{from}}-val/időponttal {{time}}-kor van ütemezve a(z) {{location}} helyszínen.',
  'Hi {{name}}, Your {{title}} with {{from}} scheduled for {{time}} at {{location}} has been canceled.':
    'Szia {{name}}, A(z) {{title}} találkozód a(z) {{from}}-val/időponttal, amelyre {{time}}-kor került volna sor a(z) {{location}} helyszínen, törölve lett.',
  'Hi {{name}}, Your {{title}} with {{from}} has been rescheduled to {{time}} at {{location}}.':
    'Szia {{name}}, A(z) {{title}} találkozód a(z) {{from}}-val/időponttal átütemezve: {{time}}, helyszín: {{location}}.',
};

export const taskReminderSms = {
  'Hi {{name}}, This is a reminder from {{from}} for the {{title}} at {{time}} at {{location}}.':
    'Szia {{name}}, Ez egy emlékeztető a(z) {{from}} által a(z) {{title}} feladatra, amely {{time}}-kor lesz a(z) {{location}} helyszínen.',
  'Hi {{name}}, a task {{title}} with {{from}} scheduled for {{time}} at {{location}}.':
    'Szia {{name}}, A(z) {{title}} feladat a(z) {{from}}-val/időponttal {{time}}-kor van ütemezve a(z) {{location}} helyszínen.',
  'Hi {{name}}, Your {{title}} with {{from}} scheduled for {{time}} at {{location}} has been canceled.':
    'Szia {{name}}, A(z) {{title}} feladatod a(z) {{from}}-val/időponttal, amelyre {{time}}-kor került volna sor a(z) {{location}} helyszínen, törölve lett.',
  'Hi {{name}}, Your {{title}} with {{from}} has been rescheduled to {{time}} at {{location}}.':
    'Szia {{name}}, A(z) {{title}} feladatod a(z) {{from}}-val/időponttal átütemezve: {{time}}, helyszín: {{location}}.',
};

'use client';
import { useAuth } from '@clerk/nextjs';
import { Notifications } from '@mantine/notifications';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { GlobalAppRouter } from '~/ui/hooks/app.router.hook';
import { useImmediateEffect } from '~/ui/hooks/immediate-effect.hook';
import { Language } from '~db/types';
import { i18nInstance } from '../../core/i18next/i18n';
import { ActiveReminders } from '../Card/Activities/Events/Event/ActiveReminders/ActiveReminders';
import { AnalyticsPageView } from '../Integrations/Analytics/AnalyticsPageView';
import { AnalyticsProvider } from '../Integrations/Analytics/AnalyticsProvider';
import { MonitoringTracker } from '../Integrations/Monitoring/Monitoring';
import { Realtime } from '../Integrations/Realtime/Realtime';
import { Shell } from '../Shell/Shell';
import { AuthProvider } from './AuthProvider';
import { StyleProviderClient } from './Styles/StyleProviderClient';
import { TrpcProvider } from './TrpcProvider';

const FrontendProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useAuth();
  return (
    <TrpcProvider auth={auth}>
      <AnalyticsProvider>
        <StyleProviderClient>
          <MonitoringTracker />
          <Notifications position="bottom-left" limit={1} />
          <ActiveReminders />
          <Shell>{children}</Shell>
          <GlobalAppRouter />
          <AnalyticsPageView />
          <Realtime />
        </StyleProviderClient>
      </AnalyticsProvider>
    </TrpcProvider>
  );
};

export const AppProviders: React.FC<{ children: React.ReactNode; language: Language }> = ({
  children,
  language,
}) => {
  useImmediateEffect(() => {
    void i18nInstance.changeLanguage(language);
  });
  return (
    <I18nextProvider i18n={i18nInstance}>
      <AuthProvider>
        <FrontendProviders>{children}</FrontendProviders>
      </AuthProvider>
    </I18nextProvider>
  );
};

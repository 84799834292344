export const common = {
  // Languages
  en: 'English',
  he: 'Hebrew',
  hu: 'Hungarian',

  // Currency
  usd: 'USD',
  ils: 'ILS',
  eur: 'EUR',
  gbp: 'GBP',
  huf: 'HUF',
  jpy: 'JPY',
  cad: 'CAD',
  pln: 'PLN',
};

import { UserButton, useUser } from '@clerk/nextjs';
import { Box, Burger, Container, Flex, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CreateCardButton } from '~/Components/Card/CreateCardButton';
import { useAppRouter } from '~/ui/hooks/app.router.hook';
import { useClerk } from '~/ui/hooks/clerk.hook';
import { useAppMediaQuery } from '~/ui/hooks/media-query.hook';
import { MarketingLogo } from '../../Logo/MarketingLogo';
import { useMenu } from '../Sidebar/menu.hook';
import { AppBreadcrumbs } from './AppBreadcrumbs/AppBreadcrumbs';
import { BackToAppButton } from './BackToAppButton';
import { HeaderDrawer } from './Drawer';
import { GlobalSearch } from './GlobalSearch/GlobalSearch';
import { LanguageSelector } from './LanguageSelector';
import { Notifications } from './Notifications/Notifications';
import { OrganizationSelector } from './OrganizationSelector';
import { PlanNotifications } from './PlanNotifications';
import { Support } from './Support';
import { ThemeToggle } from './ThemeToggle';

export const MainHeader: React.FC = () => {
  useMenu();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const { isSignedIn } = useUser();
  const { isMarketingPage, isAppPage } = useAppRouter();
  const { appearance } = useClerk();

  const smallerThanXl = useAppMediaQuery('xl');
  const smallerThanLg = useAppMediaQuery('lg');
  const smallerThanMd = useAppMediaQuery('md');
  return (
    <Box>
      <Container size={isAppPage ? undefined : 'xl'} p={'sm'} fluid={isAppPage}>
        <Flex justify={'space-between'}>
          <Flex gap={10} align="center">
            {smallerThanLg && isAppPage && (
              <Burger
                aria-label="Open navigation menu"
                opened={drawerOpened}
                onClick={toggleDrawer}
                size="sm"
              />
            )}
            <Group>
              {isMarketingPage && <MarketingLogo />}
              {isAppPage && isSignedIn && <AppBreadcrumbs />}
            </Group>
          </Flex>
          <Flex align={'center'} justify={'flex-end'} gap={smallerThanMd ? 'xs' : 'lg'}>
            {!smallerThanLg && (
              <Group>
                <GlobalSearch />
                <Notifications />
                {!smallerThanXl && (
                  <>
                    <ThemeToggle />
                    <PlanNotifications />
                  </>
                )}
                <Support />
              </Group>
            )}
            <CreateCardButton />
            <Group>
              {!smallerThanMd && <OrganizationSelector />}
              {isSignedIn && smallerThanMd && <UserButton appearance={appearance} />}
              {isMarketingPage && !isSignedIn && <LanguageSelector />}
              <BackToAppButton />
            </Group>
          </Flex>
        </Flex>
      </Container>
      {smallerThanLg && <HeaderDrawer open={drawerOpened} onClose={closeDrawer} />}
    </Box>
  );
};

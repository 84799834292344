import { type InitOptions } from 'i18next';
import { englishLocale } from 'public/locales/en/locale';
import { hebrewLocale } from 'public/locales/he/locale';
import { hungarianLocale } from 'public/locales/hu/locale';

export const defaultNS = 'common';
export const resources = {
  he: {
    ...hebrewLocale,
  },
  en: {
    ...englishLocale,
  },
  hu: {
    ...hungarianLocale,
  },
} as const;

export const i18Config: InitOptions = {
  resources,
  fallbackLng: 'en',
  lng: 'en',
  debug: false,
  defaultNS,
  interpolation: {
    escapeValue: false,
  },
};

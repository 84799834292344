export const activitiesComponent = {
  MAIL: 'Mail',
  CALL: 'Call',
  SMS: 'SMS Message',
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  LINKEDIN: 'LinkedIn',
  TWITTER: 'Twitter',
  MEETING: 'Meeting',
  TASK: 'Task',
  COMMENT: 'Comment',
  All: 'All',
  'Filter by type': 'Filter by type',
  ALL: 'Show All',

  today: 'Today',
  upcoming: 'Upcoming',
  past: 'Past',
  filter: 'Filter',
};

import(/* webpackMode: "eager", webpackExports: ["DirectionProvider"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/DirectionProvider/DirectionProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["defaultVariantColorsResolver"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/color-functions/default-variant-colors-resolver/default-variant-colors-resolver.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/ui/fonts.ts\",\"import\":\"Playfair_Display\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"playfair\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AppProviders"] */ "/vercel/path0/src/Components/Providers/AppProviders.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/ui/fonts.ts\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"hebrew\"],\"display\":\"swap\"}],\"variableName\":\"openSans\"}");

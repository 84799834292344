import { DateTimePicker, type DateTimePickerProps } from '@mantine/dates';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { type IBaseFormInputProps } from '../shared/FormInputTypes';
import { Label } from '../shared/Label';
import { parseFormErrorMessage } from '../shared/error-message.util';
import { dateComponentUtils } from './date.component.utils';

export const FormDateTimePicker: React.FC<DateTimePickerProps & IBaseFormInputProps> = ({
  name,
  label,
  description,
  required = false,
  defaultValue = new Date(),
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { value, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const errorMessage = parseFormErrorMessage(error?.message);
  const dateValue: Date = typeof value === 'string' ? new Date(value) : value;
  return (
    <DateTimePicker
      locale=""
      id={name}
      value={dateValue}
      defaultValue={defaultValue}
      renderDay={dateComponentUtils.dayRenderer}
      label={label ? <Label label={label} description={description} required={required} /> : undefined}
      error={errorMessage}
      styles={{ label: { width: '100%' } }}
      {...rest}
      {...props}
    />
  );
};

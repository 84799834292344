import { Open_Sans, Playfair_Display } from 'next/font/google';

const openSans = Open_Sans({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['hebrew'],
  display: 'swap',
});

const playfair = Playfair_Display({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
});

export const appFonts = {
  openSans,
  playfair,
};

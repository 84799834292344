export const activitiesComponent = {
  // Communication
  '{{type}} created': 'נוצרה {{type}}',
  '{{type}} updated': 'עודכנה {{type}}',
  '{{type}} deleted': 'נמחקה {{type}}',
  '{{type}} created successfully': '{{type}} נוצרה בהצלחה',
  '{{type}} updated successfully': '{{type}} עודכנה בהצלחה',

  'Communication delete': 'נמחקה התקשורת',
  'Create communication': 'הוספת התקשרות',
  'Edit communication': 'עריכת התקשרות',
  'Create {{type}}': 'הוספת {{type}}',
  'Edit {{type}}': 'עריכת {{type}}',
  'for {{name}}': 'עבור {{name}}',
  'Communication type': 'סוג התקשורת',

  // Meeting
  'Create meeting': 'הוספת פגישה',
  'Edit meeting': 'עריכת פגישה',
  'Meeting created': 'נוספה פגישה',
  'Meeting updated': 'עודכנה פגישה',
  'Meeting delete': 'נמחקה פגישה',
  'Meeting created successfully': 'הפגישה נוצרה בהצלחה',
  'Meeting updated successfully': 'הפגישה עודכנה בהצלחה',
  'Meeting with': 'פגישה עם',
  'Send email invitations and updates': 'שלח הזמנות ועדכונים באימייל',
  'Send SMS invitations and updates': 'שלח הזמנות ועדכונים ב-SMS',
  'Send SMS reminder and updates': 'שלח תזכורות ועדכונים ב-SMS',
  'Send SMS on changes, cancel and reminder': 'שלח SMS על שינויים, ביטול ותזכורת',
  'send email invitations and update on rescheduling, canceling and reminders':
    'שלח הזמנות ועדכונים על שינויים, ביטולים ותזכורות',
  'send SMS invitations and update on rescheduling, canceling and reminders':
    'שלח הזמנות ועדכונים על שינויים, ביטולים ותזכורות',

  // Task
  Tasks: 'משימות',
  'Create task': 'הוספת משימה',
  'Edit task': 'עריכת משימה',
  'Task created': 'נוספה משימה',
  'Task updated': 'המשימה עודכנה',
  'Task delete': 'המשימה נמחקה',
  'Task created successfully': 'המשימה נוצרה בהצלחה',
  'Task updated successfully': 'המשימה עודכנה בהצלחה',
  'Task for': 'משימה עבור',

  // Audit
  'was changed from': 'השתנה מ-',
  'was changed from empty value': 'השתנה מערך ריק',
  'The field': 'השדה',
  from: 'מ-',
  'to empty value': 'לערך ריק',
  to: 'ל-',

  // Active reminders
  Reminders: 'תזכורות',
  'Got it': 'הבנתי',
  'Remind me later': 'תזכיר לי מאוחר יותר',
  'Remind me 5 minutes before': 'תזכיר לי 5 דקות לפני ',
  'Remove reminder': 'הסר תזכורת',

  '{{type}} reminder': 'תזכורת ל{{type}}',
  'Reminder for {{type} "{{title}}" \n that starts at {{time}}':
    'תזכורת ל{{type}} "{{title}}" \n שמתחיל ב-{{time}}',

  Error: 'שגיאה',
  Description: 'תיאור',
  Location: 'מיקום',
  Title: 'כותרת',
  Date: 'תאריך',
  Card: 'כרטיס',
  'Created By': 'נוצר על ידי',
  Update: 'עדכן',
  Create: 'הוסף',
  Inbound: 'נכנסת',
  Outbound: 'יוצאת',
  When: 'מתי',
  Participants: 'משתתפים',
  'Add participants': 'הוספת משתתפים',
  'Select communication type': 'בחר סוג התקשורת',
  'Send from my Gmail': 'שלח מה-Gmail שלי',
  "Gmail is not connected, configure it in User's Settings":
    'תיבת ה GMAIL איננה מחוברת, יש להגדירה בהגדרות המשתמש',
  'Gmail is connected': 'תיבת ה GMAIL מחוברת',
  'Send SMS': 'שלח SMS',
  'No SMS plan found': 'לא נמצא תוכנית SMS',
  'SMS is disabled in the current plan': 'התוכנית שלך לא כוללת SMS',
  'You have no SMS left in your balance': 'אין לך SMS זמינים ביתרתך',
  '{{name}} does not have a phone number, please add one and try again':
    'ל-{{name}} אין מספר טלפון, אנא הזן ולאחר מכן נסה שוב',
  'Please set up a "sender number" for SMS in Organization Settings':
    'אנא הגדר "מספר שולח" עבור SMS בהגדרות הארגון',
  'You do not have permission to send SMS - please contact your administrator':
    'אין לך הרשאה לשלוח SMS - אנא פנה למנהל שלך',
  'No card found, please select a card and try again': 'לא נמצא כרטיס, אנא בחר כרטיס ונסה שוב',
  'You have {{balance}} SMS left': 'יש לך {{balance}} SMS זמינים',

  Message: 'הודעה',
  'Create without sending': 'הוספה ללא שליחה',
  'Send invitation': 'שלח הזמנה',
  'New Event': 'אירוע חדש',
  CALL: 'שיחת טלפון',
  MAIL: 'אימייל',
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  LINKEDIN: 'LinkedIn',
  SMS: 'הודעת SMS',
  TASK: 'משימה',
  MEETING: 'פגישה',
  COMMENT: 'הערה',
  // ai section
  'Based on the following history, write a new {{type}}, no more than a short paragraph':
    'בהתבסס על ההיסטוריה הבאה, כתוב {{type}} חדש, לא יותר מפסקה קצרה',

  Duration: 'משך זמן הפגישה',
  min: 'דקות',
  All: 'הכל',
  Meetings: 'פגישות',

  today: 'היום',
  upcoming: 'בקרוב',
  past: 'מאוחר יותר',
  filter: 'סינון',
  'New Meeting': 'פגישה חדשה',
  'New Task': 'משימה חדשה',
  Until: 'עד',
  Reminder: 'תזכורת',
  Where: 'איפה',
  Assignee: 'מי אחראי',
  Status: 'סטטוס',
  Done: 'הושלם',
  'Is done': 'האם הושלם',
  'Not done': 'לא הושלם',
  'minutes before': 'דקות לפני',
  'hour before': 'שעה לפני',
  'day before': 'יום לפני',
  None: 'ללא',
  '5 minutes before': '5 דקות לפני',
  '10 minutes before': '10 דקות לפני',
  '15 minutes before': '15 דקות לפני',
  '30 minutes before': '30 דקות לפני',
  '45 minutes before': '45 דקות לפני',
  '1 hour before': 'שעה לפני',
  '1 day before': 'יום לפני',
  '15 minutes': '15 דקות',
  '30 minutes': '30 דקות',
  '45 minutes': '45 דקות',
  '1 hour': 'שעה',
  '1 hour and 15 minutes': 'שעה ורבע',
  '1 hour and 30 minutes': 'שעה וחצי',
  '1 hour and 45 minutes': 'שעה ושלושת רבעי',
  '2 hours': 'שעתיים',
  minutes: 'דקות',
  hour: 'שעה',
  hours: 'שעות',
  'Meeting duration': 'משך זמן הפגישה',
  'Mark as done': 'סמן כהושלם',
  'Mark as undone': 'סמן כלא הושלם',
  Add: 'הוסף',
  'Task deleted': 'משימה נמחקה',
  'Task deleted successfully': 'משימה נמחקה בהצלחה',
  'Meeting deleted': 'פגישה נמחקה',
  'Meeting deleted successfully': 'פגישה נמחקה בהצלחה',
  'Communication deleted': 'תקשורת נמחקה',
  'Communication deleted successfully': 'תקשורת נמחקה בהצלחה',
  Direction: 'כיוון',
  Body: 'תוכן',
  Attachments: 'קבצים מצורפים',
  Delete: 'מחק',
  'Are you sure you want to delete this task?': 'האם אתה בטוח שברצונך למחוק משימה זו?',
  'Are you sure you want to delete this meeting?': 'האם אתה בטוח שברצונך למחוק פגישה זו?',
  'Are you sure you want to delete this communication?': 'האם אתה בטוח שברצונך למחוק שיחה זו?',
  'Yes, delete': 'כן, מחק',
  'No, keep it': 'לא, השאר',
  'Filter by type': 'סינון לפי סוג',
  ALL: 'הצג הכל',
  Today: 'היום',
  'Done?': 'בוצע?',
  'Select the card to which you want to associate this task': 'בחר את הכרטיס אליו תרצה לשייך את המשימה',
  'Select the card to which you want to associate this meeting':
    'בחר את הכרטיס אליו תרצה לשייך את הפגישה',
  Remove: 'הסר',
  'Select a card': 'בחר כרטיס',
  'Due Date': 'תאריך יעד',
  'Reminder?': 'תזכורת?',
  'New activity': 'פעילות חדשה',

  // Quick filters
  Me: 'אני',
  Overdue: 'באיחור',
  Unassigned: 'לא משוייך',

  // Global comment
  'Write a comment...': 'כתוב הערה...',
};

import { type DefaultMantineColor } from '@mantine/core';

export const colorsComponent: Record<DefaultMantineColor, string> = {
  blue: 'כחול',
  cyan: 'טורקיז',
  gray: 'אפור',
  dark: 'כהה',
  grape: 'עינב',
  green: 'ירוק',
  indigo: 'כחול כהה',
  lime: 'לימון',
  orange: 'כתום',
  pink: 'ורוד',
  red: 'אדום',
  teal: 'מנטה',
  violet: 'סגול',
  yellow: 'צהוב',
};

import { Flex, UnstyledButton } from '@mantine/core';
import Image from 'next/image';
import NextLink from 'next/link';
import NzLogo from '../../../assets/nz-logo.png';

export const AppLogo: React.FC = () => (
  <NextLink href="/cards">
    <UnstyledButton>
      <Flex justify={'center'} pos="relative" mih={'40px'}>
        <Image priority height={30} width={30} alt="next-zebra-logo" src={NzLogo} />
      </Flex>
    </UnstyledButton>
  </NextLink>
);

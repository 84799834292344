import { Button, Flex, Menu, rem } from '@mantine/core';
import { IconCards, IconChevronDown } from '@tabler/icons-react';
import NextLink from 'next/link';
import { appColors } from '~/ui/app-colors';
import { useMenuStore } from '../../Sidebar/menu.store';

export const SubMenuSelector: React.FC = () => {
  const { selectedMenu, selectedSubMenu } = useMenuStore((state) => ({
    selectedMenu: state.selectedMenu,
    selectedSubMenu: state.selectedSubMenu,
  }));

  if (!selectedMenu) return null;
  const { title, children } = selectedMenu;
  return (
    <Menu shadow="md" width={200} withArrow position="right-start">
      <Flex justify="center">
        <Menu.Target>
          {selectedSubMenu ? (
            <Button fz={20} variant="subtle" rightSection={<IconChevronDown size={16} />}>
              <b>{selectedSubMenu?.title}</b>
            </Button>
          ) : (
            <span />
          )}
        </Menu.Target>
        <Menu.Dropdown>
          {title && <Menu.Label>{title}</Menu.Label>}
          {children?.map((item) => (
            <NextLink key={item.id} href={item.path} passHref>
              <Menu.Item
                rightSection={
                  item?.Icon ? (
                    <item.Icon style={{ width: rem(14), height: rem(14) }} />
                  ) : (
                    <IconCards style={{ width: rem(14), height: rem(14) }} />
                  )
                }
                color={selectedSubMenu?.id === item.id ? appColors.themeColor : undefined}
              >
                {item.title}
              </Menu.Item>
            </NextLink>
          ))}
        </Menu.Dropdown>
      </Flex>
    </Menu>
  );
};

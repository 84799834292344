import { ActionIcon, Menu } from '@mantine/core';
import { IconCheck, IconLanguage } from '@tabler/icons-react';
import { languageOptions, useChangeLanguage, useLocale } from '~/ui/hooks/locale.hook';
import { useMantineColor } from '~/ui/hooks/mantine-color.hook';
import { type Language } from '~db/types';

export const LanguageSelector: React.FC = () => {
  const { locale } = useLocale();
  const { change } = useChangeLanguage();
  const { color } = useMantineColor('gray', 8);

  return (
    <Menu shadow="md">
      <Menu.Target>
        <ActionIcon variant="subtle" color={color} aria-label="Change language">
          <IconLanguage size="1.125rem" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {languageOptions.map((option) => (
          <Menu.Item
            key={option.value}
            onClick={() => change(option.value as Language)}
            rightSection={option.value === locale ? <IconCheck size={'0.9rem'} /> : null}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

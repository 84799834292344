import { Stack, Text } from '@mantine/core';
import { type IMenuItem } from './types';

export const MenuItemDescription = ({
  title,
  desc,
  disabled,
}: Pick<IMenuItem, 'title' | 'desc' | 'disabled'>) => {
  const disableItem = disabled && disabled.length > 0;
  return (
    <Stack gap={0}>
      <Text>{desc ?? title}</Text>
      {disableItem
        ? disabled.map((item) => (
            <Text size="sm" key={item}>
              {item}
            </Text>
          ))
        : null}
    </Stack>
  );
};

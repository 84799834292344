export const homePage = {
  'Get your business under control': 'Vegye kézbe vállalkozását',
  'Rapidly tweak, test and roll-out your customer relations with Next Zebra':
    'Egyszerűen kezelhető, könnyen alkalmazható és végtelen lehetőségeket kínál.',
  'Upgrade your sales strategy today with our turn-key crm solution!':
    'Lépjen szintet az ügyfélkapcsolat-kezeléssel, amely messzire repíti vállalkozását!',
  'Open free trial account': 'Nyisson ingyenes próbafiókot',
  'Try now! it’s free': 'Próbálja ki most! Ez ingyenes',
  'Credit card is not required': 'Nincs szükség hitelkártyára',

  'Terms and Conditions': 'Általános Szerződési Feltételek',
  'Privacy Policy': 'Adatvédelmi szabályzat',

  'Stay up-to-date on all things Next Zebra and gain additional access to our team. No spam. We’re not that kind of company.':
    'Maradjon naprakész a Next Zebra kapcsán, és tartsa a kapcsolatot csapatunkkal. Nem küldünk kéretlen leveleket – mi nem ilyen vállalat vagyunk.',

  // New home page
  'Next Zebra CRM': 'Next Zebra CRM',
  'Exceptional Customer Experiences': 'Kivételes ügyfélélmények',
  'The next generation of CRM': 'A CRM következő generációja',

  'Next Zebra CRM unifies your sales, marketing, and customer service teams onto one platform, empowering you to deliver unforgettable customer experiences at every touchpoint.':
    'A Next Zebra CRM egyesíti az értékesítési, marketing- és ügyfélszolgálati csapatokat egy platformon, lehetővé téve felejthetetlen ügyfélélmények nyújtását minden érintkezési ponton.',
  'Streamline your business operations, optimize customer engagements, and boost ROI—all while prioritizing the needs of your customers at the center of every interaction.':
    'Egyszerűsítse üzleti folyamatait, optimalizálja az ügyfélkapcsolatokat, és növelje a befektetés megtérülését – mindezt úgy, hogy az ügyfelei igényeit helyezi minden interakció középpontjába.',

  // Features
  'Discover the power of Next Zebra CRM to elevate your sales strategy, enhance customer relationships, and drive sustainable growth for your business.':
    'Fedezze fel a Next Zebra CRM erejét, hogy fejlessze értékesítési stratégiáját, javítsa az ügyfélkapcsolatokat és fenntartható növekedést érjen el vállalkozása számára.',
  'To learn more about how we prioritize data privacy and security, please review our Privacy Policy':
    'További információért arról, hogyan helyezzük előtérbe az adatok védelmét és biztonságát, kérjük, tekintse meg Adatvédelmi szabályzatunkat.',

  'Our core features': 'Alapvető funkcióink',
  'Easy to Fit Your Needs': 'Könnyen testreszabható az igényei szerint',
  'Effortlessly organize your system to suit your business needs, including managing clients, contacts, projects, and more. Easily generate and filter reports for comprehensive insights.':
    'Egyszerűen szervezze rendszerét az üzleti igényeihez igazítva, beleértve az ügyfelek, kapcsolatok és projektek kezelését. Készítsen és szűrjön jelentéseket könnyedén a teljes körű betekintés érdekében.',
  Automations: 'Automatizálások',
  'Streamline your workflows and save time with automated processes.':
    'Egyszerűsítse a munkafolyamatokat és takarítson meg időt automatizált folyamatokkal.',
  'Task Management': 'Feladatkezelés',
  'Efficiently manage tasks related to clients and projects etc., ensuring nothing falls through the cracks. Stay organized and on top of your workload with intuitive task management tools.':
    'Hatékonyan kezelje az ügyfelekhez, projektekhez stb. kapcsolódó feladatokat, biztosítva, hogy semmi ne maradjon el. Maradjon szervezett és könnyítse meg a munkaterhelést intuitív feladatkezelő eszközökkel.',
  'User-Friendly Interface': 'Felhasználóbarát felület',
  'Enjoy a simple and intuitive user interface that makes navigation and operation a breeze. Whether on desktop or mobile, our responsive UI ensures a seamless experience for all users.':
    'Élvezze az egyszerű és intuitív felhasználói felületet, amely megkönnyíti a navigációt és a használatot. Akár asztali számítógépen, akár mobilon, a reszponzív felületünk zökkenőmentes élményt biztosít minden felhasználó számára.',
  'Customizable Reports': 'Testreszabható jelentések',
  'Generate and customize reports to gain insights into your business operations. Filter data to focus on specific metrics and make data-driven decisions for your business.':
    'Készítsen és szabjon testre jelentéseket, hogy betekintést nyerjen üzleti folyamataiba. Szűrje az adatokat konkrét mutatókra összpontosítva, és hozzon adatvezérelt döntéseket vállalkozása számára.',
  'Real-Time Analytics': 'Valós idejű elemzések',
  'Monitor your business performance with real-time analytics. Track key metrics, identify trends, and make informed decisions to optimize your business operations.':
    'Kövesse nyomon vállalkozása teljesítményét valós idejű elemzésekkel. Kövesse a kulcsfontosságú mutatókat, azonosítsa a trendeket, és hozzon megalapozott döntéseket az üzleti tevékenységek optimalizálása érdekében.',
  'Data Security': 'Adatbiztonság',
  'Protect your data with advanced security features. Ensure that your business information is safe and secure with Next Zebra CRM.':
    'Védje adatait fejlett biztonsági funkciókkal. Biztosítsa, hogy üzleti információi biztonságban legyenek a Next Zebra CRM segítségével.',
  'Storage & File Management': 'Tárhely és fájlkezelés',
  'Store and manage files related to your clients, projects, and business operations. Access files from anywhere, anytime, and share them with your team.':
    'Tárolja és kezelje ügyfeleihez, projektjeihez és üzleti tevékenységeihez kapcsolódó fájlokat. Férjen hozzá a fájlokhoz bárhonnan, bármikor, és ossza meg azokat csapatával.',
  'Emails, Messages and more': 'E-mailek, üzenetek és még sok más',
  'Send emails, messages and more directly from Next Zebra CRM, or just record them. Communicate with your clients.':
    'Küldjön e-maileket, üzeneteket és még sok mást közvetlenül a Next Zebra CRM-ből, vagy rögzítse őket. Kommunikáljon ügyfeleivel.',
  'AI-powered assistant': 'AI-alapú asszisztens',
  'Automate customer service with an AI-powered assistant. Answer frequently asked questions based on your customer interactions and more.':
    'Automatizálja az ügyfélszolgálatot egy AI-alapú asszisztenssel. Válaszoljon a gyakran feltett kérdésekre ügyfélinterakciói alapján, és még sok másra.',
  'Finance Documents': 'Pénzügyi dokumentumok',
  "Manage your organization's financial documents, as well as maintain a product catalog. This way, you can create invoices, receipts, and other financial documents according to your needs.":
    'Kezelje szervezete pénzügyi dokumentumait, valamint tartson fenn termékkatalógust. Így számlákat, nyugtákat és egyéb pénzügyi dokumentumokat készíthet igényei szerint.',

  // Security
  'Security and Compliance': 'Biztonság és Megfelelőség',
  'Our platform ensures the highest standards of security and compliance, empowering your business to grow confidently while protecting customer data and meeting industry regulations.':
    'Platformunk a legmagasabb biztonsági és megfelelőségi szabványokat biztosítja, lehetővé téve vállalkozása magabiztos növekedését, miközben védi az ügyféladatokat és teljesíti az iparági szabályozásokat.',
  'SOC 2 Type 2 Compliance': 'SOC 2 Type 2 Megfelelőség',
  'Our services adhere to SOC 2 Type 2 compliance, covering security, availability, and confidentiality. This framework ensures your data remains secure and private, with regular audits confirming our commitment to these standards.':
    'Szolgáltatásaink megfelelnek a SOC 2 Type 2 szabványoknak, amely kiterjed a biztonságra, elérhetőségre és bizalmasságra. Ez a keretrendszer biztosítja, hogy adatai biztonságban és privát módon maradjanak, rendszeres auditálásokkal alátámasztva elkötelezettségünket ezen szabványok iránt.',
  'ISO 27001 Certification': 'ISO 27001 Tanúsítvány',
  'Our platform is certified under ISO 27001:2013, an internationally recognized standard for information security management. This ensures we have the necessary processes in place to protect your data from unauthorized access and vulnerabilities.':
    'Platformunk tanúsítvánnyal rendelkezik az ISO 27001:2013 szabvány szerint, amely nemzetközileg elismert információbiztonsági irányítási szabvány. Ez biztosítja, hogy megfelelő folyamatokkal rendelkezünk adatai jogosulatlan hozzáférés elleni védelmére.',
  'Protection Against Data Breaches': 'Védelem adatszivárgás ellen',
  'We implement robust data encryption, both in transit and at rest, to protect against potential breaches. Additionally, we maintain GDPR compliance, ensuring any personal data is handled with the utmost care and transparency.':
    'Erős adatvédelmet alkalmazunk, mind átvitel közben, mind nyugalmi állapotban, hogy megvédjük az esetleges szivárgásoktól. Emellett megfelelünk a GDPR előírásoknak, biztosítva, hogy minden személyes adatot a legnagyobb gondossággal és átláthatósággal kezelünk.',
  'Secure User Authentication': 'Biztonságos felhasználói hitelesítés',
  'Clerk’s authentication system ensures that user data is securely handled with multi-factor authentication (MFA) and encryption. This guarantees that only authorized users have access to sensitive information, safeguarding against unauthorized access.':
    'A Clerk hitelesítési rendszere biztosítja, hogy a felhasználói adatokat biztonságosan kezeljük többtényezős hitelesítéssel (MFA) és titkosítással. Ez garantálja, hogy csak jogosult felhasználók férjenek hozzá érzékeny információkhoz, védve a jogosulatlan hozzáférés ellen.',
  'PCI DSS Compliance': 'PCI DSS Megfelelőség',
  'For customers handling payment data, our infrastructure supports PCI DSS compliance, ensuring that all credit card transactions are securely processed according to the latest security standards.':
    'Azoknak az ügyfeleknek, akik fizetési adatokat kezelnek, infrastruktúránk támogatja a PCI DSS megfelelőséget, biztosítva, hogy az összes hitelkártyás tranzakció a legújabb biztonsági szabványoknak megfelelően történjen.',
  'Read more': 'További információ',
  'Database Security': 'Adatbázis-biztonság',
  "Our infrastructure is hosted in Amazon's secure data centers, backed by AWS Cloud Security. Data-at-rest is encrypted using XTS-AES-256 on NVMe SSD volumes, ensuring compliance with industry standards and protecting against unauthorized access.":
    'Infrastruktúránkat az Amazon biztonságos adatközpontjaiban üzemeltetjük, az AWS Cloud Security támogatásával. Az adatok nyugalmi állapotban XTS-AES-256 titkosítással védettek NVMe SSD meghajtókon, biztosítva az iparági szabványoknak való megfelelést és a jogosulatlan hozzáférés elleni védelmet.',

  // Description
  'CRM system for leads management, customer relations, accounting, marketing automation and more':
    'CRM rendszer az érdeklődők kezelésére, ügyfélkapcsolatokra, könyvelésre, marketing automatizációra és még sok másra',
  'Next Zebra is a powerful CRM solution designed to help businesses of all sizes effectively manage customers, streamline sales processes, and enhance customer service efficiency':
    'A Next Zebra egy hatékony CRM-megoldás, amely segíti a különböző méretű vállalkozásokat az ügyfelek hatékony kezelésében, az értékesítési folyamatok optimalizálásában és az ügyfélszolgálat hatékonyságának növelésében',
  // Get in touch
  'Get in touch': 'Lépjen kapcsolatba velünk',
  Name: 'Név',
  'Your Name': 'Az Ön neve',
  Company: 'Cég',
  Phone: 'Telefon',
  'Your Phone': 'Az Ön telefonszáma',
  'Your Company': 'Az Ön cége',
  Email: 'E-mail',
  'Your Email': 'Az Ön e-mail címe',
  Message: 'Üzenet',
  'Your Message': 'Az Ön üzenete',
  "Still not convinced to try our app? No worries! Reach out to us, and one of our team members will get in touch with you shortly. We're here to answer any questions and provide you with all the information you need":
    'Még nem győzte meg, hogy próbálja ki az alkalmazásunkat? Semmi gond! Vegye fel velünk a kapcsolatot, és csapatunk egyik tagja hamarosan felveszi Önnel a kapcsolatot. Itt vagyunk, hogy válaszoljunk minden kérdésére, és biztosítsuk az összes szükséges információt.',
  'Hit Us Up': 'Keressen minket',

  'All good': 'Minden rendben',
  'We got your message and will get back to you soon': 'Megkaptuk az üzenetét, hamarosan válaszolunk',
  'Something went wrong': 'Valami hiba történt',
  'Failed to send message, please try again later':
    'Nem sikerült elküldeni az üzenetet, próbálja meg később',
};

import { type IAuditAction } from '~/server/services/audit/types';

const actions: Record<IAuditAction, string> = {
  // Card Types
  CREATE_CARD_TYPE: 'Create card type',
  UPDATE_CARD_TYPE: 'Update card type',
  CREATE_FIELDS: 'Create fields',
  DELETE_CARD_TYPE: 'Delete card type',

  // Card Type Fields
  DELETE_CARD_FIELD: 'Delete card field',
  UPDATE_CARD_FIELD: 'Update card field',

  // Card type layouts
  CREATE_CARD_LAYOUT: 'Create card layout',
  UPDATE_CARD_LAYOUT: 'Update card layout',
  DELETE_CARD_LAYOUT: 'Delete card layout',

  // Cards
  CREATE_CARD: 'Create card',
  UPLOAD_CARDS: 'Upload cards',
  UPDATE_CARD: 'Update card',
  DELETE_CARD: 'Delete card',
  DELETE_CARDS: 'Delete cards',
  UPDATE_CARD_RELATION: 'Update card relation',

  // Activities
  CREATE_EVENT: 'Create event',
  UPDATE_EVENT: 'Update event',
  CREATE_TASK: 'Create task',
  UPDATE_TASK: 'Update task',
  CREATE_COMMUNICATION: 'Create communication',
  UPDATE_COMMUNICATION: 'Update communication',
  DELETE_ACTIVITY: 'Delete activity',

  // AI
  AI_GENERATE_DESCRIPTION: 'AI generate description',

  // Roles and Permissions
  CREATE_ROLE: 'Create role',
  UPDATE_ROLE: 'Update role',
  DELETE_ROLE: 'Delete role',
  UPDATE_USER_ROLE: 'Update user role',
  CREATE_USER_ROLE: 'Create user role',
  DELETE_USER_ROLE: 'Delete user role',

  // Card Table Views
  CREATE_CARDS_TABLE_VIEW: 'Create cards table view',
  UPDATE_CARDS_TABLE_VIEW: 'Update cards table view',
  DELETE_CARDS_TABLE_VIEW: 'Delete cards table view',

  // Organizations
  UPDATE_PLAN: 'Update plan',
  CREATE_ORGANIZATION: 'Create organization',
  UPDATE_ORGANIZATION_ACCESS: 'Update organization access',
  INVITE_USER: 'Invite user',

  // Storage
  DELETE_FILE: 'Delete file',
  UPLOAD_FILE: 'Upload file',

  // API Tokens
  CREATE_API_TOKEN: 'Create API token',
  DELETE_API_TOKEN: 'Delete API token',

  // Admin Console
  DELETE_ORG: 'Delete org',
  IMPERSONATE_USER: 'Impersonate user',

  // Automations
  CREATE_AUTOMATION: 'Create automation',
  UPDATE_AUTOMATION: 'Update automation',
  DELETE_AUTOMATION: 'Delete automation',
  EXECUTE_AUTOMATION: 'Execute automation',

  // Finance Documents
  CREATE_FINANCE_DOCUMENT: 'Create finance document',
  UPDATE_FINANCE_DOCUMENT: 'Update finance document',
  DELETE_FINANCE_DOCUMENT: 'Delete finance document',

  // SMS
  SEND_SMS: 'Send SMS',
};

export const auditPage = {
  action: actions,
};

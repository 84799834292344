import { Button, Group, Menu, Stack, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { appColors } from '~/ui/app-colors';
import { useAppMediaQuery } from '~/ui/hooks/media-query.hook';
import { useOrganizations } from '~/ui/hooks/organizations.hook';
import { useAppRouter } from '../../../ui/hooks/app.router.hook';

export const OrganizationSelector: React.FC = () => {
  const { t } = useTranslation('headerComponent');
  const { isMarketingPage } = useAppRouter();
  const isSmallScreen = useAppMediaQuery('sm');
  const {
    selectedOrganizationId,
    selectedOrganizationName,
    userMemberships,
    isLoading,
    selectOrganization,
  } = useOrganizations();

  if (isLoading || userMemberships.count === 0) return null;

  if (isMarketingPage) return null;

  if (userMemberships?.count === 1) {
    return (
      <Stack align={'start'} gap={0}>
        <Text fz="xs">{t('Account')}</Text>
        <Text fw={'bold'} fz="sm">
          {selectedOrganizationName}
        </Text>
      </Stack>
    );
  }

  return (
    <Menu width={200} shadow="md">
      <Group gap={0}>
        {!isSmallScreen && <Text fz="xs">{t('Account')}:</Text>}
        <Menu.Target>
          <Button variant="subtle" rightSection={<IconChevronDown size={16} />} p="xs" tt="capitalize">
            {selectedOrganizationName}
          </Button>
        </Menu.Target>
      </Group>
      <Menu.Dropdown>
        {userMemberships?.data?.map(({ organization: { id, name } }) => (
          <Menu.Item
            key={id}
            color={id === selectedOrganizationId ? appColors.themeColor : undefined}
            onClick={() => selectOrganization(id)}
          >
            {name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

import { Group, Loader, Stack, Table, Text, Title } from '@mantine/core';
import { IconHistory } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useAudit } from '~/Components/Audit/audit.hook';
import { User } from '~/Components/Form/Lookup/UserSelect';
import { AppLinkIcon } from '~/Components/Shared/Icon/AppLinkIcon';
import { dateTimeService } from '~/utils/date-time.service';

export const AuditList: React.FC = () => {
  const { items, isLoadingAudit } = useAudit({ limit: 10 });
  const { t } = useTranslation('auditPage');
  return (
    <Stack>
      <Group justify="space-between">
        <Title order={1} size="sm">
          {t('Audit')}
        </Title>
        {isLoadingAudit && <Loader size={'xs'} type="bars" />}
        <AppLinkIcon href="/settings/audit" Icon={IconHistory} tooltip={t('See all audit history')} />
      </Group>
      <Table>
        <Table.Tbody>
          {items.map((item) => {
            return (
              <Table.Tr key={item.id}>
                <Table.Td>
                  <User userId={item.userId} />
                </Table.Td>
                <Table.Td>
                  <Text size="sm">{t(`action.${item.action}`)}</Text>
                </Table.Td>
                <Table.Td>
                  <Text size="sm">{dateTimeService.relativeTime(item.createdAt)}</Text>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </Stack>
  );
};

export const automationsPage = {
  'When this happens': 'כאשר זה קורה',
  'Do this': 'עשה זאת',
  'Automation description': 'תיאור אוטומציה',
  Automation: 'אוטומציה',
  Description: 'תיאור',
  Field: 'שדה',
  'Create automation': 'צור אוטומציה',
  'Card type': 'סוג כרטיס',
  Save: 'שמור',
  'Please select a trigger': 'בבקשה בחר טריגר',
  'When field': 'כאשר שדה',
  changes: 'משתנה',
  'changes to': 'משתנה ל',
  'Change field': 'שנה שדה',
  to: 'ל-',
  on: 'ב-',
  'on the': 'ב-',
  and: 'וגם',
  'Send email to': 'שלח אימייל ל-',
  'Field: {{fieldName}}': 'שדה: {{fieldName}}',
  'When a {{cardType}} is created': 'כאשר כרטיס {{cardType}} נוצר',
  'When a {{cardType}} is deleted': 'כאשר כרטיס {{cardType}} נמחק',
  'with subject:': 'עם נושא:',
  Subject: 'נושא',
  'and body': 'ותוכן',
  Email: 'אימייל',
  'Email Body': 'תוכן אימייל',
  Participants: 'משתתפים',
  Creator: 'יוצר',

  // Label
  'When user assigned': 'כאשר משתמש משוייך',
  'When button clicked': 'כאשר כפתור נלחץ',
  'When email received': 'כאשר אימייל התקבל',
  'When card created': 'כאשר כרטיס נוצר',
  'When card deleted': 'כאשר כרטיס נמחק',
  'changes from': 'משתנה מ-',
  Every: 'כל',
  at: 'ב-',
  'On due date': 'בתאריך היעד',
  'send email to': 'שלח אימייל ל-',
  create: 'צור',
  with: 'עם',
  'send SMS to': 'שלח SMS ל-',
  // Task
  Title: 'כותרת',
  after: 'לעוד',
  Value: 'ערך',
  Unit: 'יחידה',
  before: 'לפני',
  'with description': 'עם תיאור',
  'assigned to': 'משוייך ל',
  'with reminder': 'עם תזכורת',
  // SMS
  'send sms': 'שלח SMS',
  Message: 'הודעה',
  'Total characters: {{count}}': "סה''כ תווים: {{count}}",

  // Webhook
  Remove: 'מחק',

  // Actions
  'Attach files from archive...': 'צרף קבצים מהארכיון...',

  // units
  Minutes: 'דקות',
  Hours: 'שעות',
  Days: 'ימים',
  Weeks: 'שבועות',
  Months: 'חודשים',
  Years: 'שנים',
  day: 'יום',
  week: 'שבוע',
  month: 'חודש',
  days: 'ימים',
  weeks: 'שבועות',
  months: 'חודשים',
  // delete
  'Delete automation': 'מחק אוטומציה',
  'Are you sure you want to delete automation?': 'האם אתה בטוח שאתה רוצה למחוק את האוטומציה?',
  'Automation deleted': 'האוטומציה נמחקה',
  'Automation was successfully deleted': 'האוטומציה נמחקה בהצלחה',

  // triggers
  'status change': 'סטטוס משתנה',
  'user assigned': 'משתמש משוייך',
  'button clicked': 'כפתור נלחץ',
  'email received': 'אימייל התקבל',
  'card created': 'כרטיס נוצר',
  'card deleted': 'כרטיס נמחק',
  'every time period': 'כל תקופת זמן',
  'date arrives': 'תאריך מגיע',
  // actions
  notify: 'שלח התרעה',
  'change field': 'שנה שדה',
  'send email': 'שלח אימייל',
  'create card': 'צור כרטיס',
  'create meeting': 'צור פגישה',
  'create task': 'צור משימה',
  'send SMS': 'שלח SMS',
  'call webhook': 'קרא ל-webhook',

  //days
  Sunday: 'ראשון',
  Monday: 'שני',
  Tuesday: 'שלישי',
  Wednesday: 'רביעי',
  Thursday: 'חמישי',
  Friday: 'שישי',
  Saturday: 'שבת',
};

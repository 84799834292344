import { z } from 'zod';
import { zodUtils } from '~/utils/zod-utils';
import { type Card } from '~db/types';

export const contactUsFormSchema = z.object({
  mainContact: z.string().trim().min(1),
  company: z.string().trim().min(1),
  email: zodUtils.emailSchema,
  phone: zodUtils.phoneSchema,
  description: z.string().trim().min(1),
});

export type ContactUsForm = z.infer<typeof contactUsFormSchema>;

export type CrmSearchResponse = {
  results: Card[];
};

export type ExtendedCard = Card & Record<string, any>;

export const supportTicketSchema = z.object({
  title: z.string().trim().min(1),
  description: z.string().trim().min(1),
});

export type SupportTicket = z.infer<typeof supportTicketSchema>;

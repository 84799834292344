import {
  CloseButton,
  Combobox,
  type ComboboxItem,
  Group,
  InputBase,
  Loader,
  ScrollArea,
  useCombobox,
} from '@mantine/core';
import { IconCheck, IconSearch } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppIcon } from '~/Components/Shared/Icon/AppIcon';
import { DisplayOptions } from '../shared/DisplayOptions';
import { Label } from '../shared/Label';
import { SelectItemComponent } from '../shared/SelectItemComponent';
import { parseFormErrorMessage } from '../shared/error-message.util';
import { selectUtils } from '../shared/select.utils';
import type { FormSelectProps } from './types';

export const FormSelectCreate = ({
  name,
  icon,
  loading,
  description,
  label,
  size = 'sm',
  disabled,
  required = false,
  clearable,
  creatable = true,
  placeholder,
  itemComponent,
  onChange: handleChange,
  onSearchChange,
  data = [],
  miw,
  variant,
  hideError = false,
  ...props
}: FormSelectProps) => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();
  const [isOpened, setIsOpened] = useState(false);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onOpenedChange(opened) {
      setIsOpened(opened);
    },
  });
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<Record<string, string>>({
    name,
    control,
  });
  const selectedOption = value
    ? (data.find((item) => item.value === value) ?? { label: value, value })
    : null;
  const [search, setSearch] = useState('');
  const placeholderText = useMemo(() => placeholder ?? t('Select, search or create...'), [placeholder]);
  const filteredData = useMemo(
    () => (search ? data.filter((item) => selectUtils.filterFn(item, search)) : data),
    [search, data],
  );
  const exactOptionMatch = data.some((item) => item.value === search);
  const createLabel = useMemo<string>(() => t('Create {{search}}', { search }), [search]);
  const displayedValue = useMemo(() => {
    if (isOpened && search === '') return '';
    if (search) return search;
    if (selectedOption) return selectedOption.label;
    return '';
  }, [selectedOption, search, isOpened, exactOptionMatch]);
  const ItemComponent = itemComponent ?? SelectItemComponent;
  const options = useMemo(
    () =>
      filteredData.map((item: ComboboxItem) => (
        <Combobox.Option
          value={item.value}
          component={Group}
          key={item.value}
          disabled={item.disabled}
          style={{ justifyContent: 'space-between' }}
        >
          <ItemComponent query={search} {...item} />
          {item.value === value && <AppIcon size={'1rem'} color={'gray'} Icon={IconCheck} />}
        </Combobox.Option>
      )),
    [filteredData, search, ItemComponent, value],
  );
  const errorMessage = parseFormErrorMessage(error?.message);
  const iconToShow = useMemo(() => {
    if (loading) return <Loader size="sm" />;
    const displayIcon = icon ?? <IconSearch size={'1rem'} />;
    return displayIcon;
  }, [loading, icon]);

  const showClear = clearable && Boolean(value);

  return (
    <Combobox
      width={'max-content'}
      position="bottom-start"
      store={combobox}
      onOptionSubmit={(val) => {
        if (val === '$create') {
          if (!creatable || !search) return;
          onChange(search);
          handleChange?.(search);
        } else {
          onChange(val);
          handleChange?.(val);
        }
        setSearch('');

        combobox.closeDropdown();
      }}
      {...props}
    >
      <Combobox.Target>
        <InputBase
          label={
            label ? <Label label={label} description={description} required={required} /> : undefined
          }
          styles={{ label: { width: '100%' } }}
          size={size}
          variant={variant}
          disabled={disabled}
          name={name}
          value={displayedValue}
          onBlur={() => {
            if (creatable && !exactOptionMatch && search) {
              onChange(search);
              handleChange?.(search);
            }
          }}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
            onSearchChange?.(event.currentTarget.value);
          }}
          id={name}
          error={hideError ? Boolean(errorMessage) : errorMessage}
          rightSection={
            showClear ? (
              <CloseButton
                size="xs"
                style={{ color: 'var(--mantine-color-dimmed)' }}
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                  onChange(null);
                  combobox.resetSelectedOption();
                  handleChange?.('');
                  setSearch('');
                }}
                aria-label="Clear value"
              />
            ) : (
              (iconToShow ?? <Combobox.Chevron />)
            )
          }
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents={showClear ? 'all' : 'none'}
          multiline
          miw={miw}
          placeholder={placeholderText}
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize mah={200} type="auto">
            <DisplayOptions
              exactOptionMatch={exactOptionMatch}
              creatable={creatable}
              search={search}
              options={options}
              loading={loading}
              createLabel={createLabel}
            />
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

import { Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { type TRPCLink } from '@trpc/client';
import { observable } from '@trpc/server/observable';
import { i18nInstance } from '~/core/i18next/i18n';
import { type AppRouter } from '~/server/api/root';
import { AppLink } from '../Shared/AppLink';
import { WhiteSpace } from '../Shared/WhiteSpace';

export const errorHandlerLink: TRPCLink<AppRouter> = () => {
  // here we just got initialized in the app - this happens once per app
  // useful for storing cache for instance
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          observer.error(err);
          const t = i18nInstance.getFixedT(null, 'errorCodes');
          const tCommon = i18nInstance.getFixedT(null, 'common');
          const errorCode = err.data?.client?.titleCode ?? err.data?.code;
          const messageCode = err.data?.client?.messageCode;
          const goTo = err.data?.client?.goTo;
          if (errorCode === 'UNAUTHORIZED_NO_ORG') {
            window.location.href = '/welcome';
            return;
          }
          const id = errorCode ?? messageCode ?? 'UNKNOWN_ERROR';
          notifications.hide(id);
          notifications.show({
            id,
            title: errorCode ? t(errorCode) : t('GENERAL_ERROR'),
            message: messageCode ? (
              <Text inline>
                {t(messageCode)}
                <WhiteSpace />
                {goTo && <AppLink href={goTo}>{tCommon('go')}</AppLink>}
              </Text>
            ) : (
              err.message
            ),
            color: 'red',
            autoClose: 10_000,
          });
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};

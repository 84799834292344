import { type IAuditAction } from '~/server/services/audit/types';

const actions: Record<IAuditAction, string> = {
  // Card Types
  CREATE_CARD_TYPE: 'Kártyatípus létrehozása',
  UPDATE_CARD_TYPE: 'Kártyatípus frissítése',
  CREATE_FIELDS: 'Mezők létrehozása',
  DELETE_CARD_TYPE: 'Kártyatípus törlése',

  // Card Type Fields
  DELETE_CARD_FIELD: 'Kártyamező törlése',
  UPDATE_CARD_FIELD: 'Kártyamező frissítése',

  // Card type layouts
  CREATE_CARD_LAYOUT: 'Kártyaelrendezés létrehozása',
  UPDATE_CARD_LAYOUT: 'Kártyaelrendezés frissítése',
  DELETE_CARD_LAYOUT: 'Kártyaelrendezés törlése',

  // Cards
  CREATE_CARD: 'Kártya létrehozása',
  UPLOAD_CARDS: 'Kártyák feltöltése',
  UPDATE_CARD: 'Kártya frissítése',
  DELETE_CARD: 'Kártya törlése',
  DELETE_CARDS: 'Kártyák törlése',
  UPDATE_CARD_RELATION: 'Kártyakapcsolat frissítése',

  // Activities
  CREATE_EVENT: 'Esemény létrehozása',
  UPDATE_EVENT: 'Esemény frissítése',
  CREATE_TASK: 'Feladat létrehozása',
  UPDATE_TASK: 'Feladat frissítése',
  CREATE_COMMUNICATION: 'Kommunikáció létrehozása',
  UPDATE_COMMUNICATION: 'Kommunikáció frissítése',
  DELETE_ACTIVITY: 'Tevékenység törlése',

  // AI
  AI_GENERATE_DESCRIPTION: 'Leírás generálása AI segítségével',

  // Roles and Permissions
  CREATE_ROLE: 'Szerep létrehozása',
  UPDATE_ROLE: 'Szerep frissítése',
  DELETE_ROLE: 'Szerep törlése',
  UPDATE_USER_ROLE: 'Felhasználói szerep frissítése',
  CREATE_USER_ROLE: 'Felhasználói szerep létrehozása',
  DELETE_USER_ROLE: 'Felhasználói szerep törlése',

  // Card Table Views
  CREATE_CARDS_TABLE_VIEW: 'Kártyatáblázat nézet létrehozása',
  UPDATE_CARDS_TABLE_VIEW: 'Kártyatáblázat nézet frissítése',
  DELETE_CARDS_TABLE_VIEW: 'Kártyatáblázat nézet törlése',

  // Organizations
  UPDATE_PLAN: 'Terv frissítése',
  CREATE_ORGANIZATION: 'Szervezet létrehozása',
  UPDATE_ORGANIZATION_ACCESS: 'Szervezeti hozzáférés frissítése',
  INVITE_USER: 'Felhasználó meghívása',

  // Storage
  DELETE_FILE: 'Fájl törlése',
  UPLOAD_FILE: 'Fájl feltöltése',

  // API Tokens
  CREATE_API_TOKEN: 'API token létrehozása',
  DELETE_API_TOKEN: 'API token törlése',

  // Admin Console
  DELETE_ORG: 'Szervezet törlése',
  IMPERSONATE_USER: 'Felhasználó megszemélyesítése',

  // Automations
  CREATE_AUTOMATION: 'Automatizáció létrehozása',
  UPDATE_AUTOMATION: 'Automatizáció frissítése',
  DELETE_AUTOMATION: 'Automatizáció törlése',
  EXECUTE_AUTOMATION: 'Automatizáció végrehajtása',

  // Finance Documents
  CREATE_FINANCE_DOCUMENT: 'Pénzügyi dokumentum létrehozása',
  UPDATE_FINANCE_DOCUMENT: 'Pénzügyi dokumentum frissítése',
  DELETE_FINANCE_DOCUMENT: 'Pénzügyi dokumentum törlése',

  // SMS
  SEND_SMS: 'SMS küldése',
};

export const auditPage = {
  'Audit History': 'Ellenőrzési előzmények',
  'Create At': 'Létrehozás dátuma',
  User: 'Felhasználó',
  Action: 'Művelet',
  Before: 'Előtte',
  Automation: 'Automatizáció',
  After: 'Utána',
  Audit: 'Ellenőrzési előzmények',
  'See all audit history': 'Az összes ellenőrzési előzmény megtekintése',
  'More Info': 'További információk',
  action: actions,
  Changes: 'Változások',
};

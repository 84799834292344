export const aiComponent = {
  'Write with AI': 'Írj AI segítségével',
  'Write yourself': 'Írj magad',
  'Use AI to help write content effectively': 'Használj AI-t a tartalom hatékony megírásához',
  'eg. "I need to call the client to discuss the upcoming project"':
    'Pl. "Fel kell hívnom az ügyfelet, hogy megbeszéljük a közelgő projektet"',
  'Generate description': 'Leírás generálása',
  'Based on the following history, write a new task for the user, no more than a short paragraph':
    'Az alábbi előzmények alapján írj egy új feladatot a felhasználónak, legfeljebb egy rövid bekezdésben',
};

export const storageComponent = {
  Storage: 'אחסון',
  'Drag files here or click to select': 'גרור קבצים לכאן או לחץ לבחירה',
  'File deleted': 'הקובץ נמחק',
  'File was successfully deleted': 'הקובץ נמחק בהצלחה',
  Name: 'שם',
  'Last modified': 'שונה לאחרונה',
  Size: 'גודל',
  'Upload files': 'העלאת קבצים',
  'Click here, to upload those files': 'לחץ כאן להעלאת הקבצים',
  'Attach as many files as you like, each file should not exceed 5mb':
    'ניתן לצרף כמה שתרצה, כל קובץ לא יכול להיות גדול מ-5 מגה',
};

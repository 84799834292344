'use client';
import posthog from 'posthog-js';
import { PostHogProvider as PostHogProviderInternal } from 'posthog-js/react';
import { env } from '~/env.mjs';

if (typeof window !== 'undefined' && env.NEXT_PUBLIC_POSTHOG_KEY && env.NEXT_PUBLIC_POSTHOG_HOST) {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually in PostHogPageView
  });
}

export const AnalyticsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  if (env.NEXT_PUBLIC_POSTHOG_KEY) {
    return <PostHogProviderInternal client={posthog}>{children}</PostHogProviderInternal>;
  }
  return <>{children}</>;
};

export const cardComponent = {
  'Card created': 'כרטיס נוצר',
  'New record': 'רשומה חדשה',
  '{{name}} - copy': '{{name}} - העתק',
  'Card created successfully': 'כרטיס נוצר בהצלחה',
  'Create record': 'צור רשומה',
  'Card {{name}} created': `כרטיס "{{name}}" נוצר`,
  'Go to {{cardType}}': 'עבור ל{{cardType}}',
  'Activity Timeline': 'ציר זמן פעילות',
  '{{cardTypeName}} Details': `פרטי {{cardTypeName}}`,
  'Edit {{cardTypeName}}': `עריכת {{cardTypeName}}`,
  'Record Details': 'פרטי רשומה',
  'Created At': 'נוצר בתאריך',
  'Last Updated': 'עודכן לאחרונה',
  'Edit Fields': 'עריכת שדות',
  'Next {{cardTypeName}}': `ה{{cardTypeName}} הבא`,
  'Previous {{cardTypeName}}': `ה{{cardTypeName}} הקודם`,
  'Current Status': 'סטטוס נוכחי',
  Processes: 'תהליכים',
  Finances: 'הנהלת חשבונות',
  'Edit statuses': 'עריכת סטטוסים',
  'Edit processes': 'עריכת תהליכים',
  'Add title': 'הוסף כותרת',
  'New event': 'אירוע חדש',
  Event: 'אירוע',
  Task: 'משימה',
  When: 'מתי',
  'Pick date and time': 'בחר תאריך ושעה',
  Participants: 'משתתפים',
  'Add participants': 'הוסף משתתפים',
  Location: 'מיקום',
  'Add location': 'הוסף מיקום',
  Description: 'תיאור',
  'Add description': 'הוסף תיאור',
  Save: 'שמור',
  'Please enter a title': 'אנא הכנס כותרת',
  'Select date and time': 'בחר תאריך ושעה',
  'Assign to': 'עובד אחראי',
  'Select user': 'בחר משתמש',
  'Events & modules': 'אירועים ומודולים',
  'Storage & Cards Relations': 'אחסון וכרטיסים קשורים',
  'Cards Relations': 'כרטיסים קשורים',
  'Storage & Statuses': 'אחסון וסטטוסים',
  Previous: 'קודם',
  Next: 'הבא',
  'More fields': 'עוד שדות',
  Relations: 'קשרים',
  'Related cards': 'כרטיסים קשורים',
  'Select cards to relate to': 'בחר כרטיסים לקשר',
};

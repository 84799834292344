export const cardComponent = {
  'Card created': 'Kártya létrehozva',
  'New record': 'Új bejegyzés',
  '{{name}} - copy': '{{name}} - másolat',
  'Card created successfully': 'Kártya sikeresen létrehozva',
  'Create record': 'Hozz létre bejegyzést',
  'Card {{name}} created': `A "{{name}}" kártya létrehozva`,
  'Go to {{cardType}}': 'Ugrás a(z) {{cardType}}-ra',
  'Activity Timeline': 'Tevékenység idővonala',
  '{{cardTypeName}} Details': `{{cardTypeName}} részletei`,
  'Edit {{cardTypeName}}': `{{cardTypeName}} szerkesztése`,
  'Record Details': 'Bejegyzés részletei',
  'Created At': 'Létrehozva',
  'Last Updated': 'Utoljára frissítve',
  'Edit Fields': 'Mezők szerkesztése',
  'Next {{cardTypeName}}': `Következő {{cardTypeName}}`,
  'Previous {{cardTypeName}}': `Előző {{cardTypeName}}`,
  'Current Status': 'Jelenlegi állapot',
  Processes: 'Folyamatok',
  Finances: 'Pénzügyek',
  'Edit statuses': 'Állapotok szerkesztése',
  'Edit processes': 'Folyamatok szerkesztése',
  'Add title': 'Adj hozzá címet',
  'New event': 'Új esemény',
  Event: 'Esemény',
  Task: 'Feladat',
  When: 'Mikor',
  'Pick date and time': 'Válassz dátumot és időt',
  Participants: 'Résztvevők',
  'Add participants': 'Adj hozzá résztvevőket',
  Location: 'Helyszín',
  'Add location': 'Adj hozzá helyszínt',
  Description: 'Leírás',
  'Add description': 'Adj hozzá leírást',
  Save: 'Mentés',
  'Please enter a title': 'Kérjük, adj meg egy címet',
  'Select date and time': 'Válassz dátumot és időt',
  'Assign to': 'Felelős',
  'Select user': 'Válassz felhasználót',
  'Events & modules': 'Események és modulok',
  'Storage & Cards Relations': 'Tárhely és kártyakapcsolatok',
  'Cards Relations': 'Kártyakapcsolatok',
  'Storage & Statuses': 'Tárhely és állapotok',
  Previous: 'Előző',
  Next: 'Következő',
  'More fields': 'További mezők',
  Relations: 'Kapcsolatok',
  'Related cards': 'Kapcsolódó kártyák',
  'Select cards to relate to': 'Válassz kártyákat a kapcsolathoz',
};

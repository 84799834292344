import { dark } from '@clerk/themes';
import { useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';

export const useClerk = () => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const appearance = useMemo(() => {
    const isDark = colorScheme === 'dark';
    return {
      baseTheme: isDark ? dark : undefined,
      variables: {
        colorPrimary: colors.violet[5],
      },
    };
  }, [colorScheme]);

  return { appearance };
};

'use client';
import { type useAuth } from '@clerk/nextjs';
import {
  QueryClient,
  type QueryClientConfig,
  QueryClientProvider,
  hashKey,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  type PersistedClient,
  type Persister,
  persistQueryClient,
} from '@tanstack/react-query-persist-client';
import { httpBatchLink, httpLink } from '@trpc/client';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import { del, get, set } from 'idb-keyval';
import { type FC, type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import superjson from 'superjson';
import { env } from '~/env.mjs';
import { type AppRouter } from '../../server/api/root';
import { api } from '../../trpc';
import { errorHandlerLink } from './error.link';

const getBaseUrl = () => {
  if (typeof window !== 'undefined') return ''; // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3050}`; // dev SSR should use localhost
};

function createIDBPersister(idbValidKey: IDBValidKey) {
  if (typeof window === 'undefined') {
    return {
      persistClient: async () => {},
      restoreClient: async () => {},
      removeClient: async () => {},
    } as Persister;
  }
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  } satisfies Persister;
}

const persister = createIDBPersister('next-crm');

const requestLink = env.NEXT_PUBLIC_BULK_REQUESTS === 'true' ? httpBatchLink : httpLink;

export const TrpcProvider: FC<
  PropsWithChildren<{ auth: Pick<ReturnType<typeof useAuth>, 'userId' | 'orgId'> }>
> = ({ children, auth: { userId, orgId } = {} }) => {
  const queryClientConfig = useMemo(() => {
    return {
      defaultOptions: {
        queries: {
          queryKeyHashFn: (queryKeys) => {
            return hashKey([orgId, userId, ...queryKeys]);
          },
          refetchOnWindowFocus: false,
          retry: 1,
        },
      },
    } satisfies QueryClientConfig;
  }, [orgId, userId]);

  const queryClient = useMemo(() => {
    return new QueryClient(queryClientConfig);
  }, []);

  useEffect(() => {
    queryClient.setDefaultOptions(queryClientConfig.defaultOptions);
  }, [queryClientConfig]);

  useEffect(() => {
    void persistQueryClient({ queryClient, persister });
  }, [queryClient]);
  const [trpcClient] = useState(() =>
    api.createClient({
      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       */
      links: [
        // loggerLink({
        //   enabled: (opts) =>
        //     process.env.NODE_ENV === 'development' ||
        //     (opts.direction === 'down' && opts.result instanceof Error),
        // }),
        errorHandlerLink,
        requestLink({
          methodOverride: 'POST',
          url: `${getBaseUrl()}/api/trpc`,
          transformer: superjson,
          headers() {
            return {
              'x-request-id': window.crypto.randomUUID(),
            };
          },
        }),
      ],
    }),
  );
  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools position="top" buttonPosition="bottom-left" initialIsOpen={false} />
        {children}
      </QueryClientProvider>
    </api.Provider>
  );
};

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;

import { common } from './common';
import { activitiesComponent } from './components/activities';
import { aiComponent } from './components/ai';
import { cardComponent } from './components/card';
import { cardTypeComponent } from './components/card-type';
import { colorsComponent } from './components/colors';
import { dateComponent } from './components/date';
import { fieldsComponent } from './components/fields';
import { headerComponent } from './components/header';
import { multiFiltersComponent } from './components/multi-filters';
import { navbarComponent } from './components/navbar';
import { seedCustomLabels } from './components/seed';
import { storageComponent } from './components/storage';
import { meetingReminderEmail } from './emails/meeting-reminder';
import { taskReminderEmail } from './emails/task-reminder';
import { errorCodes } from './errors-codes';
import { accountInfo } from './pages/account-info';
import { adminPage } from './pages/admin';
import { auditPage } from './pages/audit';
import { automationsPage } from './pages/automations';
import { cardPage } from './pages/card';
import { cardsPage } from './pages/cards';
import { dashboardPage } from './pages/dashboard';
import { errorPage } from './pages/error';
import { financePage } from './pages/finance';
import { homePage } from './pages/home';
import { settingsPage } from './pages/settings';
import { welcomePage } from './pages/welcome';
import { meetingReminderSms, taskReminderSms } from './sms/reminder';
import { zodHungarianLocale } from './zod/zod';

export const hungarianLocale = {
  // General
  common,
  errorCodes,

  // Pages
  homePage,
  dashboardPage,
  errorPage,
  welcomePage,
  auditPage,
  adminPage,
  settingsPage,
  cardPage,
  cardsPage,
  accountInfo,
  automationsPage,
  financePage,

  // Components
  headerComponent,
  navbarComponent,
  cardTypeComponent,
  fieldsComponent,
  cardComponent,
  colorsComponent,
  activitiesComponent,
  storageComponent,
  dateComponent,
  multiFiltersComponent,
  aiComponent,

  // Emails
  taskReminderEmail,
  meetingReminderEmail,

  // SMS
  meetingReminderSms,
  taskReminderSms,

  // zod
  zod: zodHungarianLocale,

  // Seed
  seed: seedCustomLabels,
};

'use client';

import { useOrganization, useUser } from '@clerk/nextjs';
import { usePathname, useSearchParams } from 'next/navigation';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useSettings } from '~/ui/hooks/settings.hook';

export const AnalyticsPageView: React.FC = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();
  const { user, isSignedIn, isLoaded } = useUser();
  const { organization, isLoaded: isOrgLoaded } = useOrganization();
  const { plan, isLoadingSettings } = useSettings();

  useEffect(() => {
    if (!isLoaded || isLoadingSettings || !isOrgLoaded) return;
    if (isSignedIn) {
      posthog.identify(user.id, {
        email: user.primaryEmailAddress?.emailAddress,
        name: user.fullName,
        plan: plan?.type,
        organization: organization?.name,
        orgId: organization?.id,
      });
    } else {
      posthog.reset();
    }
  }, [isSignedIn, user, posthog]);

  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = `${url}?${searchParams.toString()}`;
      }
      posthog.capture('$pageview', { $current_url: url });
    }
  }, [pathname, searchParams, posthog]);

  return null;
};

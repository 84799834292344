import { IconHourglassLow } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { planUtils } from '~/Components/Settings/PlanAndBilling/plan.utils';
import { AppLinkIcon } from '~/Components/Shared/Icon/AppLinkIcon';
import { useSettings } from '~/ui/hooks/settings.hook';

export const PlanNotifications: React.FC = () => {
  const { plan } = useSettings();
  const { t } = useTranslation('settingsPage');

  if (!plan) return null;
  if (plan.type !== 'TRIAL') return null;

  return (
    <AppLinkIcon
      href="/settings/plan-and-billing"
      title="Plan"
      Icon={IconHourglassLow}
      tooltip={
        planUtils.isExpired(plan)
          ? t('Your trial plan has expired')
          : t('Your trial plan will expire {{expiredIn}}', { expiredIn: planUtils.expiredIn(plan) })
      }
      color="orange"
      size="lg"
    />
  );
};

export const settingsPage = {
  collaboration: {
    ORGANIZATION: 'Organization level',
    ROLE_GROUP: 'Role group level',
    USER: 'User level',
  },
  organizationsRelationsDescription: `Here you can set which organizations will be available for each role.
  For example, if you want to give access to the organization "A" for the role "B", you need to select the organization "A" in the "Access" column and click "Save".
  This requires your organization to be a parent of the related organizations. Contact your administrator if you need to change the parent organization.`,
  organizationsRelationsDescriptionNoOrgs: `You do not have any child organizations or your org is not a parent organization.`,
  permissionType: {
    CARDS: 'Cards',
    AUTOMATION: 'Automation',
    TASKS: 'Tasks',
    STORAGE: 'Storage',
    AUDIT: 'Audit',
    MEETINGS: 'Meetings',
    CARD_TYPES: 'Card types',
    CARD_FIELDS: 'Card fields',
    CARD_LAYOUTS: 'Card layouts',
    CARDS_TABLE_VIEWS: 'Table views',
    ROLES: 'Roles',
    ADMIN_CONSOLE: 'Admin console',
    ORGANIZATION: 'Organization',
    FINANCE_DOCUMENTS: 'Finance documents',
    SMS: 'SMS',
  },
};

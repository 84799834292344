import {
  Box,
  Container,
  Divider,
  Flex,
  Group,
  Text,
  Title,
  UnstyledButton,
  darken,
  rgba,
  useMantineTheme,
} from '@mantine/core';
import { type TFunction } from 'i18next';
import NextLink from 'next/link';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { appFonts } from '~/ui/fonts';
import { useAppMediaQuery } from '~/ui/hooks/media-query.hook';

const getLinks = (t: TFunction<'homePage'>) => [
  { label: t('Terms and Conditions'), link: '/terms-and-conditions' },
  { label: t('Privacy Policy'), link: '/privacy-policy' },
];

const MarketingLogo: React.FC = () => (
  <NextLink href={'/'} style={{ textDecoration: 'none' }}>
    <Title order={2} fw={500} c={'gray.0'} ff={appFonts.playfair.style.fontFamily}>
      NZ
    </Title>
  </NextLink>
);

const thisYear = new Date().getFullYear();

export const HomeFooter: React.FC = () => {
  const { t } = useTranslation('homePage');
  const links = useMemo(() => getLinks(t), [t]);
  const isSmallScreen = useAppMediaQuery('sm');
  const theme = useMantineTheme();
  return (
    <Box
      style={{
        backgroundColor: darken(theme.colors.violet[9], 0.7),
        boxShadow: `0px -10px 122px 8px ${rgba(theme.colors.violet[9], 0.5)}`,
        position: 'relative',
        zIndex: 10,
      }}
    >
      <Container size="xl">
        {!isSmallScreen && (
          <Flex style={{ alignSelf: 'stretch' }} justify="space-between" p="lg">
            <Flex direction={'column'} justify="flex-start" gap={'lg'}>
              <MarketingLogo />
            </Flex>
            <Flex direction={'column'} justify="flex-end" gap={'lg'}>
              <Text c={'white'} component="span" fw={300} size="xs">
                {t(
                  'To learn more about how we prioritize data privacy and security, please review our Privacy Policy',
                )}
              </Text>
            </Flex>
          </Flex>
        )}
        {isSmallScreen && (
          <Flex
            style={{ alignSelf: 'stretch' }}
            direction="column"
            justify="flex-start"
            p="lg"
            gap={'md'}
          >
            <MarketingLogo />
            <Text c={'gray.0'} fz={14}>
              {t(
                'To learn more about how we prioritize data privacy and security, please review our Privacy Policy',
              )}
            </Text>
          </Flex>
        )}
        <Divider color={'gray.5'} my="xs" ml="lg" mr="lg" />
        <Flex style={{ alignSelf: 'stretch' }} justify="flex-end" p="lg">
          <Group>
            {links.map(({ link, label }) => (
              <NextLink key={label} href={link}>
                <UnstyledButton>
                  <Text color="gray.5" size="sm">
                    {label}
                  </Text>
                </UnstyledButton>
              </NextLink>
            ))}
            <NextLink href={'/'}>
              <UnstyledButton>
                <Text c="gray.0" size="sm">
                  © Next Zebra {thisYear}
                </Text>
              </UnstyledButton>
            </NextLink>
          </Group>
        </Flex>
      </Container>
    </Box>
  );
};

export const adminPage = {
  Organizations: 'Szervezetek',
  Organization: 'Szervezet',
  'Organization name': 'Szervezet neve',
  Plan: 'Terv',
  'Registered users': 'Regisztrált felhasználók',
  'More Clerk details': 'További Clerk adatok',
  'View in Clerk': 'Megtekintés a Clerkben',
  'Created at': 'Létrehozva ekkor',
  Actions: 'Műveletek',
  'Organization ID': 'Szervezet azonosító',
  'Max Allowed users': 'Maximálisan engedélyezett felhasználók',
  Users: 'Felhasználók',
  'Members count': 'Tagok száma',
  'Max allowed memberships': 'Maximálisan engedélyezett tagságok',
  Name: 'Név',
  Email: 'Email',
  Id: 'Azonosító',
  'Clerk Role': 'Clerk szerepkör',
  Impersonate: 'Megszemélyesítés',
  'Danger Zone': 'Veszélyzóna',
  'Delete Organization': 'Szervezet törlése',
  Unlimited: 'Korlátlan',
  'Are you sure you want to delete this organization?':
    'Biztos, hogy törölni szeretné ezt a szervezetet?',
  'You can only filter by one field at a time': 'Egyszerre csak egy mező alapján lehet szűrni',
  'Organization info': 'Szervezet adatai',
};

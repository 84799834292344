import { AppShell, Box, LoadingOverlay, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { Suspense } from 'react';
import { appHeights } from '~/ui/app-constants';
import { useAppMediaQuery } from '~/ui/hooks/media-query.hook';
import { useAppInit } from '../../ui/hooks/app.hook';
import { useAppRouter } from '../../ui/hooks/app.router.hook';
import { HomeFooter } from '../Home/Footer';
import { MainHeader } from '../Navigation/Header/Header';
import { Sidebar } from '../Navigation/Sidebar/SideBar';
import { useMenuStore } from '../Navigation/Sidebar/menu.store';

export const Shell: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isMarketingPage } = useAppRouter();
  const { colorScheme } = useMantineColorScheme();
  const { colors } = useMantineTheme();
  const sideBarWidth = useMenuStore((s) => s.sideBarWidth);
  const isSmallerThanLg = useAppMediaQuery('lg');
  useAppInit();
  return (
    <AppShell
      layout="alt"
      header={{ height: appHeights.HEADER }}
      navbar={{
        breakpoint: 'lg',
        collapsed: { desktop: false, mobile: true },
        width: isMarketingPage ? 0.01 : sideBarWidth,
      }}
      style={{
        backgroundColor: colorScheme === 'light' ? colors.gray[0] : colors.dark[8],
      }}
    >
      <AppShell.Header
        style={{
          backgroundColor: colorScheme === 'light' ? 'white' : colors.dark[7],
          borderBottom: 'none',
        }}
      >
        <MainHeader />
      </AppShell.Header>
      {isMarketingPage ? null : (
        <AppShell.Navbar style={{ ...(isSmallerThanLg ? {} : { transition: 'width 300ms' }) }}>
          <Sidebar />
        </AppShell.Navbar>
      )}
      <AppShell.Main>
        <Box p={isMarketingPage ? 'none' : 'sm'}>
          <Suspense fallback={<LoadingOverlay visible />}>{children}</Suspense>
        </Box>
      </AppShell.Main>
      {isMarketingPage && <HomeFooter />}
    </AppShell>
  );
};

export const aiComponent = {
  // AI
  'Write with AI': 'כתוב עם AI',
  'Write yourself': 'כתוב בעצמך',
  'Use AI to help write content effectively': 'השתמש ב-AI כדי לכתוב תוכן בצורה אפקטיבית',
  'eg. "I need to call the client to discuss the upcoming project"':
    'לדוגמה. "אני צריך להתקשר ללקוח כדי לדון בפרויקט הקרוב"',
  'Generate description': 'צור תיאור',
  'Based on the following history, write a new task for the user, no more than a short paragraph':
    'בהתבסס על ההיסטוריה הבאה, כתוב משימה חדשה למשתמש, לא יותר מפסקה קצרה',
};

import { useUser } from '@clerk/nextjs';
import { setUser } from '@sentry/nextjs';
import { useEffect } from 'react';

const useMonitoring = () => {
  const { user } = useUser();

  useEffect(() => {
    if (!user) return;
    const email = user.primaryEmailAddress?.emailAddress;

    setUser({ email, username: user.fullName ?? '', id: user.id });
  }, [user?.id]);
};

export const MonitoringTracker: React.FC = () => {
  useMonitoring();
  return null;
};

export const seedCustomLabels = {
  // Card types
  Projects: 'פרוייקטים',
  Project: 'פרוייקט',
  'Projects related to a company': 'פרוייקטים הקשורים לחברה',
  'Main projects view': 'תצוגת פרוייקטים ראשית',
  Rooms: 'חדרים',
  Room: 'חדר',
  'Rooms and suites': 'חדרים וסוויטות',
  'Room size': 'גודל חדר',
  'Room size in square meters': 'גודל חדר במ"ר',
  'Bed size': 'גודל מיטה',
  Single: 'יחיד',
  Double: 'זוגי',
  King: 'מלך',
  Queen: 'מלכה',
  'Room type': 'סוג חדר',
  Regular: 'רגיל',
  Suite: 'סוויטה',
  Deluxe: 'דלוקס',
  Penthouse: 'פנטהאוז',
  'Last cleaning': 'ניקיון אחרון',
  'Last cleaning date': 'תאריך ניקיון אחרון',
  'Main rooms view': 'תצוגת חדרים ראשית',
  Main: 'ראשי',

  // custom fields
  'Eye color': 'צבע עיניים',
  Blue: 'כחול',
  Brown: 'חום',
  Green: 'ירוק',
  Height: 'גובה',
  'Height in cm': 'גובה בס"מ',
  Anniversary: 'יום נישואין',
  'Anniversary date': 'תאריך נישואין',
  Married: 'נשוי',
  'Favorite color': 'צבע מועדף',
  Red: 'אדום',
  Yellow: 'צהוב',
  Purple: 'סגול',
  Pink: 'ורוד',
  Orange: 'כתום',
  Black: 'שחור',
  White: 'לבן',
};

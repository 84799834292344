export const cardTypeComponent = {
  'Card type created': 'Kártyatípus létrehozva',
  'Card type created successfully': 'Kártyatípus sikeresen létrehozva',
  'Card type updated': 'Kártyatípus frissítve',
  'Card type updated successfully': 'Kártyatípus sikeresen frissítve',
  'Create card type': 'Hozz létre kártyatípust',
  'Card type': 'Kártyatípus',
  New: 'Új',
  'General information': 'Általános információk',
  Fields: 'Mezők',
  'Cards relations': 'Kártyakapcsolatok',
  'Relation delete': 'Kapcsolat törlése',
  'Are you sure you want to delete this relation view?':
    'Biztosan törölni szeretnéd ezt a kapcsolatnézetet?',
  Add: 'Hozzáadás',
  'Field name': 'Mező neve',
  'Field type': 'Mező típusa',
  '+ Create "{{query}}"': `+ Hozz létre "{{query}}"`,
  Options: 'Lehetőségek',
  Description: 'Leírás',
  Displayed: 'Megjelenített',
  Required: 'Kötelező',
  'Live Example': 'Élő példa',
  'Field Form Preview': 'Mező űrlap előnézete',
  'Field Display': 'Mező megjelenítése',
  'No value': 'Nincs érték',
  'Default value': 'Alapértelmezett érték',
  'Default values': 'Alapértelmezett értékek',
  'System API name': 'Rendszer API neve',
  'Remove field': 'Mező eltávolítása',
  'Are you sure that you want to remove "{{label}}" field?': `Biztosan eltávolítod a(z) "{{label}}" mezőt?`,
  'No fields': 'Nincsenek mezők',
  Name: 'Név',
  System: 'Rendszer',
  Type: 'Típus',
  Values: 'Értékek',
  '{{count}} more...': `Még {{count}}...`,
  Default: 'Alapértelmezett',
  Color: 'Szín',
  Order: 'Sorrend',
  'Sort order': 'Rendezési sorrend',
  'Card type visibility': 'Kártyatípus láthatósága',
  "When 'Hide from menu' is selected, this card type will not appear in the form menu or search results": `Ha a 'Rejtés a menüből' opció be van jelölve, ez a kártyatípus nem jelenik meg az űrlap menüjében vagy a keresési eredmények között`,
  "Usualy this feature is used when this card type is used only for relations and shouldnֿ't be created directly": `Ez a funkció általában akkor használatos, amikor a kártyatípus csak kapcsolatokhoz van használva, és nem kell közvetlenül létrehozni`,
  'Hide from menu': 'Rejtés a menüből',
  'Hidden from menu and search results': 'Rejtve a menüből és a keresési eredményekből',
  'Card type identifier': 'Kártyatípus azonosító',
  Identifiers: 'Azonosítók',
  'Identifiers are used to determine whether two cards are duplicates':
    'Azonosítók segítségével állapítható meg, hogy két kártya duplikált-e',
  'If no identifiers are set, duplication detection is disabled, meaning the system will not check for or flag duplicate cards':
    'Ha nincsenek azonosítók beállítva, a duplikáció észlelése inaktív, azaz a rendszer nem ellenőrzi vagy jelöli meg a duplikált kártyákat',
  'Duplications detection enable': 'Duplikáció-érzékelés engedélyezése',
  'More details': 'További részletek',
  'Add option': 'Lehetőség hozzáadása',
  'New option': 'Új lehetőség',
  none: 'Nincs',
  'no color': 'nincs szín',
  'Pick color': 'Szín kiválasztása',
  'Statuses and Progress': 'Állapotok és folyamatok',
  Statuses: 'Állapotok',
  Processes: 'Folyamatok',
  Layouts: 'Elrendezések',
  Layout: 'Elrendezés',
  'Main Layout': 'Fő elrendezés',
  'Layout created': 'Elrendezés létrehozva',
  'Layout created successfully': 'Elrendezés sikeresen létrehozva',
  'Layout updated': 'Elrendezés frissítve',
  'Layout updated successfully': 'Elrendezés sikeresen frissítve',
  Error: 'Hiba',
  Title: 'Cím',
  Save: 'Mentés',
  Create: 'Létrehozás',
  'Add layout': 'Elrendezés hozzáadása',
  'New layout': 'Új elrendezés',
  Loading: 'Betöltés',
  'Created at': 'Létrehozva',
  'Last update': 'Utolsó frissítés',
  'API Name': 'API név',
  Delete: 'Törlés',
  'CardType deleted': 'Kártyatípus törölve',
  '{{cardTypeName}} deleted successfully': `A(z) "{{cardTypeName}}" kártyatípus sikeresen törölve`,
  'You have {{cardsCount}} records, You need to delete all records before': `{{cardsCount}} rekordod van, először törölnöd kell az összes rekordot`,
  'CardType Delete': 'Kártyatípus törlése',
  'Are you sure that you want to remove "{{label}}" view?': `Biztosan eltávolítod a(z) "{{label}}" nézetet?`,
  'This field is used to identify the field in API calls':
    'Ez a mező az API hívások során azonosítja a mezőt.',
  'Fields order': 'Mezők sorrendje',
  'Fields display': 'Mezők megjelenítése',
  'These fields define the card type. You can hide or change the fields position in the layout section.':
    'Ezek a mezők határozzák meg a kártyatípust. Elrejtheted vagy módosíthatod a mezők helyét az elrendezés részben.',
  'Edit a views to link between cards. If a new link between cards is added, a view will be added automatically': `Szerkeszd a nézeteket a kártyák közötti kapcsolatokhoz. Ha új kapcsolatot adsz hozzá, egy nézet automatikusan létrejön`,
  'Relation title in the card': 'Kapcsolat címe a kártyában',
  '{{cardType}} - Related fields': `{{cardType}} - Kapcsolódó mezők`,
  'View description': 'Nézet leírása',
  'Edit view': 'Nézet szerkesztése',
  'View name': 'Nézet neve',
  Slug: 'Slug',
  'Hide from card view': 'Rejtés a kártyanézetből',
  'Visible in card view': 'Látható a kártyanézetben',
  Yes: 'Igen',
  No: 'Nem',
  Edit: 'Szerkesztés',
  Actions: 'Műveletek',
  Field: 'Mező',
  Label: 'Címke',
  Reset: 'Visszaállítás',
  'Layout deleted': 'Elrendezés törölve',
  'Layout deleted successfully': 'Elrendezés sikeresen törölve',

  // templates
  'Contacts and Leads': 'Kapcsolatok és leadek',
  Contact: 'Kapcsolat',
  Contacts: 'Kapcsolatok',
  Companies: 'Cégek',
  Company: 'Cég',
  All: 'Összes',
  'All {{pluralName}}': `Összes {{pluralName}}`,
  'All contacts': 'Összes kapcsolat',
  'New leads': 'Új leadek',
  'Irrelevant leads': 'Nem releváns leadek',
  Main: 'Fő',
  'All companies': 'Összes cég',
  'Companies and organizations': 'Cégek és szervezetek',
  // products template
  'Products and services': 'Termékek és szolgáltatások',
  'All products and services': 'Összes termék és szolgáltatás',
  Product: 'Termék',
  Products: 'Termékek',
  Leads: 'Leadek',
  'Leads Layout': 'Leadek elrendezése',
  'Available fields': 'Elérhető mezők',
  'Fields to display': 'Mezők megjelenítése',
  'All fields are displayed': 'Minden mező megjelenik',
};

import { type IAuditAction } from '~/server/services/audit/types';

const actions: Record<IAuditAction, string> = {
  // Card Types
  CREATE_CARD_TYPE: 'יצירת סוג כרטיס',
  UPDATE_CARD_TYPE: 'עדכון סוג כרטיס',
  CREATE_FIELDS: 'יצירת שדות',
  DELETE_CARD_TYPE: 'מחיקת סוג כרטיס',

  // Card Type Fields
  DELETE_CARD_FIELD: 'מחיקת שדה כרטיס',
  UPDATE_CARD_FIELD: 'עדכון שדה כרטיס',

  // Card type layouts
  CREATE_CARD_LAYOUT: 'יצירת פריסת כרטיס',
  UPDATE_CARD_LAYOUT: 'עדכון פריסת כרטיס',
  DELETE_CARD_LAYOUT: 'מחיקת פריסת כרטיס',

  // Cards
  CREATE_CARD: 'יצירת כרטיס',
  UPLOAD_CARDS: 'העלאת כרטיסים',
  UPDATE_CARD: 'עדכון כרטיס',
  DELETE_CARD: 'מחיקת כרטיס',
  DELETE_CARDS: 'מחיקת כרטיסים',
  UPDATE_CARD_RELATION: 'עדכון קישוריות כרטיס',

  // Activities
  CREATE_EVENT: 'יצירת אירוע',
  UPDATE_EVENT: 'עדכון אירוע',
  CREATE_TASK: 'יצירת משימה',
  UPDATE_TASK: 'עדכון משימה',
  CREATE_COMMUNICATION: 'יצירת תקשורת',
  UPDATE_COMMUNICATION: 'עדכון תקשורת',
  DELETE_ACTIVITY: 'מחיקת פעילות',

  // AI
  AI_GENERATE_DESCRIPTION: 'יצירת תיאור ע"י AI',

  // Roles and Permissions
  CREATE_ROLE: 'יצירת תפקיד',
  UPDATE_ROLE: 'עדכון תפקיד',
  DELETE_ROLE: 'מחיקת תפקיד',
  UPDATE_USER_ROLE: 'עדכון תפקיד משתמש',
  CREATE_USER_ROLE: 'יצירת תפקיד משתמש',
  DELETE_USER_ROLE: 'מחיקת תפקיד משתמש',

  // Card Table Views
  CREATE_CARDS_TABLE_VIEW: 'יצירת תצוגת טבלת כרטיסים',
  UPDATE_CARDS_TABLE_VIEW: 'עדכון תצוגת טבלת כרטיסים',
  DELETE_CARDS_TABLE_VIEW: 'מחיקת תצוגת טבלת כרטיסים',

  // Organizations
  UPDATE_PLAN: 'עדכון תוכנית',
  CREATE_ORGANIZATION: 'יצירת ארגון',
  UPDATE_ORGANIZATION_ACCESS: 'עדכון גישת ארגון',
  INVITE_USER: 'הזמנת משתמש',

  // Storage
  DELETE_FILE: 'מחיקת קובץ',
  UPLOAD_FILE: 'העלאת קובץ',

  // API Tokens
  CREATE_API_TOKEN: 'יצירת אסימון API',
  DELETE_API_TOKEN: 'מחיקת אסימון API',

  // Admin Console
  DELETE_ORG: 'מחיקת ארגון',
  IMPERSONATE_USER: 'התחזות למשתמש',

  // Automations
  CREATE_AUTOMATION: 'יצירת אוטומציה',
  UPDATE_AUTOMATION: 'עדכון אוטומציה',
  DELETE_AUTOMATION: 'מחיקת אוטומציה',
  EXECUTE_AUTOMATION: 'ביצוע אוטומציה',

  // Finance Documents
  CREATE_FINANCE_DOCUMENT: 'יצירת מסמך פיננסי',
  UPDATE_FINANCE_DOCUMENT: 'עדכון מסמך פיננסי',
  DELETE_FINANCE_DOCUMENT: 'מחיקת מסמך פיננסי',

  // SMS
  SEND_SMS: 'שליחת SMS',
};

export const auditPage = {
  'Audit History': 'הסטוריית שינויים',
  'Create At': 'תאריך יצירה',
  User: 'משתמש',
  Action: 'פעולה',
  Before: 'לפני',
  Automation: 'אוטומציה',
  After: 'אחרי',
  Audit: 'היסטוריית שינויים',
  'See all audit history': 'צפה בכל ההיסטוריה',
  'More Info': 'מידע נוסף',
  action: actions,
  Changes: 'שינויים',
};

import { useRef } from 'react';

type Effect = () => void;

export const useImmediateEffect = (effect: Effect) => {
  const cleanupRef = useRef<Effect | null>(effect);

  if (cleanupRef.current) {
    cleanupRef.current();
    cleanupRef.current = null;
  }
};

import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { useAppRouter } from '~/ui/hooks/app.router.hook';
import { type Card, type CardType } from '~db/types';
import { useCardTypes } from '../../../Cards/card-types.hook';
import { GlobalSearchComponent } from './Component';

export const GlobalSearch: React.FC = () => {
  const { isMarketingPage } = useAppRouter();
  const router = useRouter();
  const { cardTypes, isLoadingCardTypes } = useCardTypes();

  const handleCardSelect = useCallback(
    ({ card, cardType }: { card: Card; cardType: CardType }) => {
      const url = `/cards/${cardType.slug}/${card.id}`;
      router.push(url);
    },
    [router],
  );
  if (isMarketingPage) return null;
  if (isLoadingCardTypes || !cardTypes) return null;
  if (cardTypes.length === 0) return null;

  return <GlobalSearchComponent cardTypes={cardTypes} onSubmit={handleCardSelect} />;
};

export const navbarComponent = {
  Dashboard: 'Irányítópult',
  Automations: 'Automatizálások',
  Cards: 'Kártyák',
  'All Cards': 'Minden kártya',
  Tasks: 'Feladatok',
  Agenda: 'Napirend',
  Favorites: 'Kedvencek',
  Recent: 'Legutóbbi oldalak',
  Settings: 'Beállítások',
  General: 'Általános',
  'User Settings': 'Felhasználói beállítások',
  Members: 'Tagok',
  'Files Archive': 'Fájlarchívum',
  'API Keys': 'API kulcsok',
  Organization: 'Szervezet',
  'Roles and Permissions': 'Szerepek és jogosultságok',
  'Plan and Billing': 'Terv és számlázás',
  'Card Types': 'Kártyatípusok',
  Audit: 'Változások naplója',
  'Admin Console': 'Admin konzol',
  Organizations: 'Szervezetek',
  crmContacts: 'Kapcsolatok',
  crmCompanies: 'Cégek',
  crmProjects: 'Projektek',
  'Open Navigation': 'Navigáció megnyitása',
  'Close Navigation': 'Navigáció bezárása',
  Finances: 'Pénzügyek',
  'Documents & Reports': 'Dokumentumok és jelentések',
  Conversations: 'Beszélgetések',
};

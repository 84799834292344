import { zodResolver } from '@hookform/resolvers/zod';
import { ActionIcon, Button, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconHelp } from '@tabler/icons-react';
import { useCallback, useMemo } from 'react';
import { Form, FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from '~/Components/Form/Input/Input';
import { SubmitButton } from '~/Components/Form/Submit';
import { FormTextarea } from '~/Components/Form/Textarea';
import { type SupportTicket, supportTicketSchema } from '~/server/services/crm/types';
import { api } from '~/trpc';
import { useAppRouter } from '~/ui/hooks/app.router.hook';

const CreateHelpTicketModal: React.FC<{ close: () => void; defaultValues?: Partial<SupportTicket> }> = ({
  close,
  defaultValues,
}) => {
  const { t } = useTranslation();

  const formMethods = useForm<SupportTicket>({
    mode: 'onChange',
    defaultValues: defaultValues ?? {
      title: '',
      description: '',
    },
    resolver: zodResolver(supportTicketSchema),
  });

  const { mutateAsync: openSupportTicket, isPending } = api.support.create.useMutation({
    onError: () => {
      notifications.show({
        title: t('Something went wrong'),
        message: t('Failed to send message, please try again later'),
        color: 'red',
      });
    },
    onSuccess: () => {
      notifications.show({
        title: t('We are on it!'),
        message: t('We got your message and will get back to you soon'),
        color: 'green',
      });
      formMethods.reset();
      close();
    },
  });

  const onSubmit: SubmitHandler<SupportTicket> = useCallback(
    async (data) => {
      await openSupportTicket(data);
    },
    [openSupportTicket],
  );

  return (
    <FormProvider {...formMethods}>
      <Modal
        opened
        closeOnClickOutside={false}
        closeOnEscape
        onClose={close}
        title={
          <Text fw="500" size={'lg'}>
            {t('Create new support ticket')}
          </Text>
        }
      >
        <Form onSubmit={() => formMethods.handleSubmit(onSubmit)()}>
          <Stack>
            <FormInput
              name="title"
              label={t('Title')}
              placeholder={t('Title your ticket')}
              disabled={isPending}
            />
            <FormTextarea
              name="description"
              label={t('Description')}
              placeholder={t('Please describe your issue')}
              minRows={4}
              maxRows={5}
              disabled={isPending}
            />
            <SubmitButton loading={isPending} disabled={isPending} />
          </Stack>
        </Form>
      </Modal>
    </FormProvider>
  );
};

export const Support: React.FC = () => {
  const { isMarketingPage } = useAppRouter();
  const [opened, { open, close }] = useDisclosure();

  if (isMarketingPage) return null;

  return (
    <>
      <ActionIcon component="a" size="lg" radius="xl" variant="light" onClick={() => open()}>
        <IconHelp />
      </ActionIcon>
      {opened ? <CreateHelpTicketModal close={close} /> : null}
    </>
  );
};

export const Purchase: React.FC = () => {
  const [opened, { open, close }] = useDisclosure();
  const { t } = useTranslation();
  const purchaseValues = useMemo(
    () => ({
      title: t('Upgrade Plan'),
      description: t(
        'I would like to upgrade my plan to the next tier. Please contact me to discuss the details',
      ),
    }),
    [t],
  );
  return (
    <>
      <Button w="fit-content" color="grape" leftSection={<IconHelp size={14} />} onClick={() => open()}>
        {t('Upgrade Plan')}
      </Button>
      {opened ? <CreateHelpTicketModal close={close} defaultValues={purchaseValues} /> : null}
    </>
  );
};
